import React from 'react';

class PopupAddEditTypeEtab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: ""
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.typeEtab !== prevProps.typeEtab) {
            const edit = Object.keys(this.props.typeEtab).length > 0;
            this.setState({ name: edit ? this.props.typeEtab.name : "" });
        }
    }

    handleSubmit = (e) => {
        e.preventDefault();
        if(this.state.name !== "") {

            if(Object.keys(this.props.typeEtab).length > 0) {
                this.props.editTypeEtab({
                    id: this.props.typeEtab.id,
                    name: this.state.name,
                });
            } else this.props.addTypeEtab({ name: this.state.name });
            
            this.btnElement.click();
        }
    }

    render() {
        const edit = Object.keys(this.props.typeEtab).length > 0;

        return(
            <div>
                <div className="modal fade in" id="modalAddEditTypeEtab" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditTypeEtabLabel" animate="animate">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un type d'établissement</h1>
                            </div>
                            <div className="modal-body">
                                <form id="formAjout">

                                    <label>Label</label>
                                    <input 
                                    type="text" 
                                    required 
                                    value={this.state.name} 
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    />

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                type="submit"
                                form="formAjout"
                                className="btn-save"
                                disabled={false}
                                data-toggle={ Object.keys(this.props.typeEtab).length > 0 ? 'modal' : '' }
                                data-target={'#modalConfirmation'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if(Object.keys(this.props.typeEtab).length > 0) this.props.handleSubmit(this.handleSubmit);
                                    else this.handleSubmit(e);
                                }}>
                                    <img src="/dist/img/icons/icon-save.png" alt=""/>
                                    Sauvegarder
                                </button>
                                <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                ref={button => this.btnElement = button}
                                >Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        )
    }
}

export default PopupAddEditTypeEtab;