import { protTypeConstants } from '../constants/protype.constants';
import { proTypeService } from '../services/protype.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const protTypeActions = {
    getAllProType,
    postProType,
    editProType,
    deleteProType
};

function getAllProType() {

    return (dispatch) => {

      dispatch(request());
      dispatch(showLoading());

      proTypeService.getAllProType()
        .then(
            protype => {      
                dispatch(success(protype));    
                dispatch(hideLoading());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(hideLoading());
            }
        );
    };

    function request() { return { type: protTypeConstants.PRO_TYPE_REQUEST} }
    function success(protype) { return { type: protTypeConstants.PRO_TYPE_SUCCESS, protype } }
    function failure(error) { return { type: protTypeConstants.PRO_TYPE_FAILURE, error } }
}

function postProType(pds) {

    return (dispatch) => {

      dispatch(request());

      proTypeService.postProType(pds)
        .then(
            posted => {      
                dispatch(success(posted));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: protTypeConstants.POST_PRO_TYPE_REQUEST} }
    function success(posted) { return { type: protTypeConstants.POST_PRO_TYPE_SUCCESS, posted } }
    function failure(error) { return { type: protTypeConstants.POST_PRO_TYPE_FAILURE, error } }
}

function editProType(pds) {

    return (dispatch) => {

      dispatch(request());

      proTypeService.editProType(pds)
        .then(
            edited => {      
                dispatch(success(edited));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: protTypeConstants.EDIT_PRO_TYPE_REQUEST} }
    function success(edited) { return { type: protTypeConstants.EDIT_PRO_TYPE_SUCCESS, edited } }
    function failure(error) { return { type: protTypeConstants.EDIT_PRO_TYPE_FAILURE, error } }
}

function deleteProType(id) {

    return (dispatch) => {

      dispatch(request());

      proTypeService.deleteProType(id)
        .then(
            deleted => {      
                dispatch(success(deleted));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: protTypeConstants.DELETE_PRO_TYPE_REQUEST} }
    function success(deleted) { return { type: protTypeConstants.DELETE_PRO_TYPE_SUCCESS, deleted } }
    function failure(error) { return { type: protTypeConstants.DELETE_PRO_TYPE_FAILURE, error } }
}