import { logicielConstants } from '../constants/logiciel.constants';
import { logicielService } from '../services/logiciel.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const logicielActions = {
    getAllLogiciels,
    postLogiciel,
    editLogiciel,
    deleteLogiciel
};

function getAllLogiciels() {

    return (dispatch) => {

      dispatch(request());
      dispatch(showLoading());

      logicielService.getAllLogiciels()
        .then(
            logiciels => {      
                dispatch(success(logiciels));   
                dispatch(hideLoading()); 
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(hideLoading());
            }
        );
    };

    function request() { return { type: logicielConstants.LOGICIEL_REQUEST} }
    function success(logiciels) { return { type: logicielConstants.LOGICIEL_SUCCESS, logiciels } }
    function failure(error) { return { type: logicielConstants.LOGICIEL_FAILURE, error } }
}

function postLogiciel(log) {

    return (dispatch) => {

      dispatch(request());

      logicielService.postLogiciel(log)
        .then(
            posted => {      
                dispatch(success(posted));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: logicielConstants.POST_LOGICIEL_REQUEST} }
    function success(posted) { return { type: logicielConstants.POST_LOGICIEL_SUCCESS, posted } }
    function failure(error) { return { type: logicielConstants.POST_LOGICIEL_FAILURE, error } }
}

function editLogiciel(log) {

    return (dispatch) => {

      dispatch(request());

      logicielService.editLogiciel(log)
        .then(
            edited => {      
                dispatch(success(edited));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: logicielConstants.EDIT_LOGICIEL_REQUEST} }
    function success(edited) { return { type: logicielConstants.EDIT_LOGICIEL_SUCCESS, edited } }
    function failure(error) { return { type: logicielConstants.EDIT_LOGICIEL_FAILURE, error } }
}

function deleteLogiciel(id) {

    return (dispatch) => {

      dispatch(request());

      logicielService.deleteLogiciel(id)
        .then(
            deleted => {      
                dispatch(success(deleted));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: logicielConstants.DELETE_LOGICIEL_REQUEST} }
    function success(deleted) { return { type: logicielConstants.DELETE_LOGICIEL_SUCCESS, deleted } }
    function failure(error) { return { type: logicielConstants.DELETE_LOGICIEL_FAILURE, error } }
}