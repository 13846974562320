import React from 'react';
import { connect } from 'react-redux';
import { typeEtabActions } from '../../actions/typeEtab.actions';
import ButtonAdd from '../../components/shared/ButtonAdd';
import PopupDelete from '../../components/shared/PopupDelete';
import PopupAddEditTypeEtab from '../../components/shared/PopupAddEditTypeEtab';
import { NotificationManager} from 'react-notifications';
import PopupConfirmation from '../../components/shared/PopupConfirmation';

class TypesEtabPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idDelete: -1,
            selectedType: {},
            listTypes: [],
            limite: {
                min: 0,
                max: 100
            }
        }
    }

    componentDidMount() {
        this.props.getAllTypeEtab();
    }

    componentDidUpdate(prevProps){

        if((this.props.loadingTypeEtab !== prevProps.loadingTypeEtab) && !this.props.loadingTypeEtab){
            if((this.props.typesEtab !== "null") && (this.props.typesEtab !== undefined)){
                this.setState({ listTypes : JSON.parse(this.props.typesEtab) });
            }            
        }

        if((this.props.typePosted !== prevProps.typePosted) && this.props.typePosted) {
            this.props.getAllTypeEtab();
            NotificationManager.success('',"Type établissement ajouté");
        }

        if((this.props.typeEdited !== prevProps.typeEdited) && this.props.typeEdited) {
            this.props.getAllTypeEtab();
            NotificationManager.success('',"Type établissement édité");
        }

        if((this.props.typeDeleted !== prevProps.typeDeleted) && this.props.typeDeleted) {
            this.props.getAllTypeEtab();
            NotificationManager.success('',"Type établissement supprimé");
        }
    }

    affichePages = () => {
        let table = [];
        let listT = this.state.listTypes;
        let count = 1;

        for(let i=0; i<listT.length; i+=100) {
            table.push(
                <div
                key={`page${count}`}
                className="page-btn"
                onClick={() => this.setState({limite: {
                    min: i,
                    max: i+100 > listT.length ? listT.length : i+100
                }})}
                >
                    <p>{count}</p>
                </div>
            )
            count++;
        }

        return table;
    }

    render() {
        const { listTypes } = this.state;

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>  
                        Types d'établissement  
                        <small>Gestion des types d'établissement</small>
                    </h1>
                </section>

                <section className="content typeEtab">
                    
                    <table className="box tab-entete typeEtab">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>Label</p>
                                    </div>
                                </td>
                                <td className="last">
                                    <ButtonAdd modal="#modalAddEditTypeEtab" handleAdd={() => this.setState({selectedType: {}})}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list typeEtab">
                        <tbody>
                        {listTypes.map((type, i) => {
                            if((i >= this.state.limite.min) && (i < this.state.limite.max)) {
                                return (
                                    <tr key={i} className="list-row">
                                        <td>
                                            {type.name}
                                        </td>
                                        <td className="icons last">
                                            <div
                                            className="icon-btn"
                                            data-toggle="modal"
                                            data-target="#modalAddEditTypeEtab"
                                            onClick={() => this.setState({selectedType: type})}>
                                                <img src="/dist/img/icons/icon-edit.png" alt=""/>
                                            </div>
                                            <div
                                            className="icon-btn"
                                            data-toggle="modal"
                                            data-target="#modalDelete"
                                            onClick={() => this.setState({idDelete: type.id})}>
                                                <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                        {listTypes.length === 0 &&
                            <tr className="list-row">
                                <td>Aucun type d'établissement trouvé</td>
                            </tr>
                        }
                        </tbody>
                    </table>

                    <div className="page-selector">
                        {this.state.listTypes.length >= this.state.limite.max && this.affichePages()}
                    </div>

                </section>

                <PopupAddEditTypeEtab
                handleSubmit={(submit) => this.setState({ handleSubmit: submit })}
                addTypeEtab={(type) => this.props.postTypeEtab(type)}
                typeEtab={this.state.selectedType}
                editTypeEtab={(type) => this.props.editTypeEtab(type)}
                />

                <PopupDelete
                type="type d'établissement"
                deleteTypeEtab={(id) => this.props.deleteTypeEtab(id)}
                id={this.state.idDelete}
                />
                
                <PopupConfirmation message={'Êtes-vous sûr ?'} handleSubmit={this.state.handleSubmit}/>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingTypeEtab, typesEtab, typePosted, typeEdited, typeDeleted } = state.typeEtab;
    return { loadingTypeEtab, typesEtab, typePosted, typeEdited, typeDeleted };
}
const actionCreators = {
    getAllTypeEtab: typeEtabActions.getAllTypeEtab,
    postTypeEtab: typeEtabActions.postTypeEtab,
    editTypeEtab: typeEtabActions.editTypeEtab,
    deleteTypeEtab: typeEtabActions.deleteTypeEtab,
};

const TypesEtab = connect(mapState, actionCreators)(TypesEtabPage);

export default TypesEtab;