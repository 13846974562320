import { zoneGeoConstants } from '../constants/zoneGeo.constants';

export function zoneGeo(state = {}, action) {
  switch (action.type) {
    case zoneGeoConstants.GET_ALL_COUNTRIES_REQUEST:
      return Object.assign({}, state, {
        loading: true,
        countries: "null"
      });
    case zoneGeoConstants.GET_ALL_COUNTRIES_SUCCESS:
      return Object.assign({}, state, {
        countries: action.listCountries,
        loading: false
      });
    case zoneGeoConstants.GET_ALL_COUNTRIES_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        loading: false
      });
    case zoneGeoConstants.SET_COUNTRY:
      return Object.assign({}, state, {
        country: action.country.name,
        idCountry: action.country.id
      });
    case zoneGeoConstants.GET_BY_COUNTRY_REQUEST:
      return Object.assign({}, state, {
        loadingZoneGeo: true,
        zoneGeo: {}
      });
    case zoneGeoConstants.GET_BY_COUNTRY_SUCCESS:
      return Object.assign({}, state, {
        zoneGeo: action.listZoneGeo,
        loadingZoneGeo: false
      });
    case zoneGeoConstants.GET_BY_COUNTRY_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        loadingZoneGeo: false
      });
    case zoneGeoConstants.POST_ZONE_GEO_REQUEST:
      return Object.assign({}, state, {
        zonePosted: false,
      });
    case zoneGeoConstants.POST_ZONE_GEO_SUCCESS:
      return Object.assign({}, state, {
        zonePosted: true
      });
    case zoneGeoConstants.POST_ZONE_GEO_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        zonePosted: false
      });
    case zoneGeoConstants.EDIT_ZONE_GEO_REQUEST:
      return Object.assign({}, state, {
        zoneEdited: false,
      });
    case zoneGeoConstants.EDIT_ZONE_GEO_SUCCESS:
      return Object.assign({}, state, {
        zoneEdited: true
      });
    case zoneGeoConstants.EDIT_ZONE_GEO_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        zoneEdited: false
      });
    case zoneGeoConstants.DELETE_ZONE_GEO_REQUEST:
      return Object.assign({}, state, {
        zoneDeleted: false,
      });
    case zoneGeoConstants.DELETE_ZONE_GEO_SUCCESS:
      return Object.assign({}, state, {
        zoneDeleted: true
      });
    case zoneGeoConstants.DELETE_ZONE_GEO_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        zoneDeleted: false
      });
    case zoneGeoConstants.GET_USER_COUNTRY_REQUEST:
      return Object.assign({}, state, { 
        loadingUserCountry: true,
        userCountry: {}
      });
    case zoneGeoConstants.GET_USER_COUNTRY_SUCCESS:
      return Object.assign({}, state, { 
        userCountry: action.listUserCountry,
        loadingUserCountry: false
      });
    case zoneGeoConstants.GET_USER_COUNTRY_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        loadingUserCountry: false
      });
    default:
      return state
  }
}