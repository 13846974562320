import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions/user.actions';

class MonComptePage extends React.Component {

    componentDidMount() {
        let navarray = document.getElementsByClassName('nava')
        for(let i=0; i < navarray.length; i++) {
            navarray[i].className = "nava"
        }
    }

    render() {
        return(
            <section className="content">
                <section className="content-header">
                    <h1>
                        Mon compte 
                        <small>Modifier mes informations</small>
                    </h1>
                    <ol className="breadcrumb">
                        <li><a href="#"><i className="fa fa-envelope"></i> Mon compte</a></li>
                        <li className="active" translate="">Modifier</li>
                    </ol>
                </section>
                <section className="content">
                    <form>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="box-body account">
                                    <div className="form-group">
                                        <label htmlFor="password" translate="">Mot de passe (au moins 5 caractères)</label>
                                        <input type="password" className="form-control" id="password" placeholder="Mot de passe (obligatoire)"/>
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="passwordConfirm" translate="">Confirmez le mot de passe</label>
                                        <input type="password" className="form-control" id="passwordConfirm" name="passwordConfirm"  placeholder="Confirmez le mot de passe saisie (obligatoire)"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="box-body">
                            <div className="col-xs-12">
                                <button type="submit" className="btn btn-primary pull-right">
                                    <i className="fa fa-save"></i> Sauvegarder
                                </button>
                            </div>
                        </div>
                    </form>
                </section>
            </section>
        )
    }
}
function mapState(state) {
    const { loggingIn, loggedIn, user, logout, errorLog } = state.authentification;
    return { loggingIn, loggedIn, user, logout, errorLog };
}
const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    forgotPassword: userActions.forgotPassword,
};

const MonCompte = connect(mapState, actionCreators)(MonComptePage);

export default MonCompte;
