import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter, Route } from "react-router-dom";
import { NotificationManager, NotificationContainer} from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import NavigationMenu from './components/NavigationMenu';
import HeaderBar from './components/header-bar/HeaderBar';
import { Footer } from './components/Footer';
import ClearCache from "./ClearCache";
import LoadingBar from 'react-redux-loading-bar';

import Dashboard from './pages/Dashboard';
import JoursFeries from './pages/JoursFeries';
import Historique from './pages/Historique';
import Etablissements from './pages/Etablissements';
import Professionnels from './pages/Professionnels';
import ReleveCoord from './pages/ReleveCoord';
import MonCompte from './pages/Profil/MonCompte';
import Login from './pages/Authentification/Login';
import Specialite from './pages/Parametres/Specialite';
import TypePDS from './pages/Parametres/TypePDS';
import Logiciels from './pages/Parametres/Logiciels';
import TypesEtab from './pages/Parametres/TypesEtab';
import Utilisateurs from './pages/Parametres/Utilisateurs';
import ZoneGeo from './pages/Parametres/ZoneGeo';

import { userActions } from './actions/user.actions';

class AppPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
      roleUser: ""
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if((this.props.loadingRoleUser !== prevProps.loadingRoleUser) && !this.props.loadingRoleUser) {
      if(this.props.roleUser !== undefined && this.props.users !== "null") this.setState({ roleUser: this.props.roleUser });
    }
  }

  userLogin = () => {
    this.logoutAfterTime();
    this.setState({ loggedIn: true });
  }

  logoutAfterTime = () => {
    setTimeout(() => {      
      NotificationManager.success("","Deconnecté");  
      this.props.logout();   
      window.location = "/";
      this.setState({
        loggedIn: false
      });
    }, 7200000);
  } 

  logingOut = () => this.setState({loggedIn: false});

  render() {
    const role = this.state.roleUser === "" ? localStorage.getItem('role') : this.state.roleUser;

    // locale
    if(!localStorage.getItem('currentLanguage')) {
      localStorage.setItem('currentLanguage', "fr")
    }

    return (
      <ClearCache>
        {({ loading, isLatestBuildDate, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestBuildDate) {
            // You can decide how and when you want to force reload
            refreshCacheAndReload();
          }

          return (
            <div>
              <NotificationContainer/>
                {this.state.loggedIn ? (
                  <BrowserRouter>
                    <HeaderBar userLogOut={this.logingOut}/>
                    <div className="loading-bar">
                      <LoadingBar 
                      showFastActions
                      style={{ backgroundColor: '#6dbf3c', height: '4px' }}
                      />
                    </div>
                    <NavigationMenu roleUser={role} />
                    <div className="content-wrapper">
                      <Route exact path="/" component={Dashboard} />
                      <Route exact path="/etablissements" component={Etablissements} />
                      <Route exact path="/professionnels" component={Professionnels} />
                      <Route exact path="/institutions/check-list" component={ReleveCoord} />

                      <Route exact path="/jours-feries" component={JoursFeries} />
                      <Route exact path="/historique" component={Historique} />
                      
                      <Route exact path="/specialites" component={Specialite} />
                      <Route exact path="/logiciels" component={Logiciels} />
                      <Route exact path="/zones-geographiques" component={ZoneGeo} />
                      <Route exact path="/types-etablissement" component={TypesEtab} />
                      <Route exact path="/types-pds" component={TypePDS} />
                      <Route exact path="/utilisateurs" component={Utilisateurs} />

                      <Route exact  path="/profile/edit" component={MonCompte} />
                    </div>
                    <Footer/>
                  </BrowserRouter> 
                ) : (
                  <Login userLogin={this.userLogin}/>
                )}
            </div>
          );
        }}
      </ClearCache>
    )
    
  }
}

function mapState(state) {
  const { loadingRoleUser, roleUser } = state.authentification;
  return {  roleUser, loadingRoleUser };
}

const actionCreators = {  
  logout: userActions.logout
};

const App = connect(mapState, actionCreators)(AppPage);

export default App;