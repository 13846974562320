export const holidayConstants = {   
    GET_HOLIDAY_BY_COUNTRY_REQUEST: 'GET_HOLIDAY_BY_COUNTRY_REQUEST',
    GET_HOLIDAY_BY_COUNTRY_SUCCESS: 'GET_HOLIDAY_BY_COUNTRY_SUCCESS',
    GET_HOLIDAY_BY_COUNTRY_FAILURE: 'GET_HOLIDAY_BY_COUNTRY_FAILURE',
    
    POST_HOLIDAY_REQUEST: 'POST_HOLIDAY_REQUEST',
    POST_HOLIDAY_SUCCESS: 'POST_HOLIDAY_SUCCESS',
    POST_HOLIDAY_FAILURE: 'POST_HOLIDAY_FAILURE',

    DELETE_HOLIDAY_REQUEST: 'DELETE_HOLIDAY_REQUEST',
    DELETE_HOLIDAY_SUCCESS: 'DELETE_HOLIDAY_SUCCESS',
    DELETE_HOLIDAY_FAILURE: 'DELETE_HOLIDAY_FAILURE',
};