import React from 'react';
import { connect } from 'react-redux';

import { zoneGeoActions } from '../actions/zoneGeo.actions';
import { etabActions } from '../actions/etab.actions';
import { typeEtabActions } from '../actions/typeEtab.actions';

import ButtonAdd from '../components/shared/ButtonAdd';
import ButtonExport from '../components/shared/ButtonExport';
import PopupAddEditEtab from '../components/shared/PopupAddEditEtab';
import PopupLinkEtab from '../components/shared/PopupLinkEtab';
import PopupDelete from '../components/shared/PopupDelete';
import { NotificationManager} from 'react-notifications';
import Pagination from '../components/Pagination';
import CustomSelect from '../components/shared/CustomSelect';
import Select from 'react-select';
import ReactLoading from 'react-loading';
import PopupConfirmation from '../components/shared/PopupConfirmation';

class EtablissementsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            idCountry: -1,
            idDelete: -1,
            selectedEtab: {},
            linkEtab: {},
            etabList: [],
            zgList: [],
            typeList: [],
            zoneGeo: null,
            typeEtab: null,
            mission: null,
            doublon: {
                status : false,
                data : {},
                nbrPage : 0,
                page : 1
            },
            readOnly: false,
            etabProList: [],
            searchLinkEtab: false,
            etabListReady: false,
            validating: false,
            EtablissementReady : false,
            page: 1,
            maxPage: 1,
            maxPerPage: 100,
            status : "",
            search : "",
            searchPriv : "",
            searchNom : "",
            searchRef : "",
            protype : "",
            searchWarn : "",
            orderKey: "",
            orderByKey : {
                status : true,
                reference: false,
                name: false,
                type: false,
                isPrivate: false,
            }
        };

        this.timer = setTimeout(() => {}, 1);
    }

    componentDidMount() {
        this.props.getUserCountry();

        if(this.props.idCountry !== undefined) {
            this.props.getEtablissements(this.props.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage
            });
        }
        if(this.props.country !== undefined) this.props.getByCountry(this.props.country);
        
        this.props.getAllTypeEtab();
    }

    componentDidUpdate(prevProps, prevState) {
        if(
            (this.state.protype !== prevState.protype) ||
            (this.state.searchPriv !== prevState.searchPriv) ||
            (this.state.status !== prevState.status) ||
            (this.state.zoneGeo !== prevState.zoneGeo)
        
        ){
            let data = {
                "page" : 1,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            }
            this.props.getEtablissements(this.state.idCountry, data);
            this.setState({
                EtablissementReady: false,
                etabListReady: false
            });
        }

        if(this.props.loadingVerifEtab !== prevProps.loadingVerifEtab && !this.props.loadingVerifEtab){
            if(this.props.verif){
                if(JSON.parse(this.props.verif).similaireInstitution !== null){
                    this.setState({
                        doublon: {
                            status : true,
                            data : JSON.parse(this.props.verif).similaireInstitution,
                            nbrPage : JSON.parse(this.props.verif).nbrPages,
                            page : this.state.doublon.page
                        }
                    })
                }else{
                    this.setState({
                        doublon: {
                            status : false,
                            data : {},
                            nbrPage : 0,
                            page : 0
                        }
                    })
                }
            }
        }
        // verif

        if((this.props.idCountry !== prevProps.idCountry) && (this.props.idCountry !== undefined)) {
            this.props.getEtablissements(this.props.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
            this.setState({ idCountry : this.props.idCountry });
        }

        if((this.props.country !== prevProps.country) && (this.props.country !== undefined)) {
            this.props.getByCountry(this.props.country);
        }

        if(this.state.idCountry !== localStorage.getItem('idCountry')){
            this.setState({ idCountry : localStorage.getItem('idCountry') });
            this.props.getEtablissements(localStorage.getItem('idCountry'), {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
        }

        if((this.props.loadingUserCountry !== prevProps.loadingUserCountry) && !this.props.loadingUserCountry) {
            if(this.props.userCountry !== undefined && this.props.userCountry !== "null"){
                this.setState({
                    ...this.state,
                    countryList: JSON.parse(this.props.userCountry),
                    idCountry: localStorage.getItem('idCountry'),
                    nameCountry: localStorage.getItem('nameCountry'),
                    initIdCountry: JSON.parse(this.props.userCountry)[0].id
                });
                let data = {                    
                    "page" : this.state.page,
                    "maxPerPage" : this.state.maxPerPage,
                    "status" : (this.state.status !== null) ? this.state.status : null,
                    "search" : (this.state.search !== null) ? this.state.search : null,
                    "type" : (this.state.protype !== null) ? this.state.protype : null,
                    "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                    "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                    "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                    "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
                }
                this.props.getEtablissements(localStorage.getItem('idCountry'), data);
                this.props.getAllZonesGeo({ country: localStorage.getItem('idCountry') });
                // this.props.getAllMissions({ 
                    // country: JSON.parse(this.props.userCountry)[0].id,
                //  })
                this.setState({ EtablissementReady: false });
            } else if(this.props.userCountry === "null") {
                this.setState({
                    ...this.state,
                    EtablissementReady: true,
                    etabListReady: true
                })
            }
        }

        if((this.props.loadingEtabs !== prevProps.loadingEtabs) && !this.props.loadingEtabs) {
            if(this.props.etabs !== undefined) {
                if(JSON.parse(this.props.etabs).listInstitutionByArea !== null) {
                    this.setState({
                        etabList: (this.state.searchLinkEtab) ? this.state.etabList : this.formatedData(JSON.parse(this.props.etabs).listInstitutionByArea),
                        etabProList: (this.state.searchLinkEtab) ? this.formatedData(JSON.parse(this.props.etabs).listInstitutionByArea) : this.state.etabList,
                        maxPage: (this.state.searchLinkEtab) ? this.state.maxPage : JSON.parse(this.props.etabs).nbrPages,
                        etabListReady: true,
                        searchLinkEtab : false
                    });
                } else {
                    this.setState({
                        etabListReady: true,
                        maxPage: 0,
                        etabList: (this.state.searchLinkEtab) ? this.state.etabList : [],                        
                        etabProList: (this.state.searchLinkEtab) ? [] : this.state.etabList,
                        searchLinkEtab : false
                    });
                }
            }
        }

        if((this.props.loadingTypeEtab !== prevProps.loadingTypeEtab) && !this.props.loadingTypeEtab) {
            if((this.props.typesEtab !== undefined && (this.props.typesEtab !== "null"))) {
                let listType = JSON.parse(this.props.typesEtab);
                listType.unshift({id: "", name: "Tous", status: true});
                this.setState({ typeList: listType });
            }
            if(this.props.typesEtab === "null") this.setState({ typeList: [] });
        }

        if((this.props.loadingZoneGeo !== prevProps.loadingZoneGeo) && !this.props.loadingZoneGeo) {
            if((this.props.zoneGeo !== undefined) && (this.props.zoneGeo !== "null")){
                this.setState({ zgList: JSON.parse(this.props.zoneGeo) });
            }
            if(this.props.zoneGeo === "null") this.setState({ zgList: [] });
        }

        if(this.state.page !== prevState.page){
            this.props.getEtablissements(this.state.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
        }

        // NOTIFICATIONS

        if((this.props.etabDeleted !== prevProps.etabDeleted) && this.props.etabDeleted) {
            this.props.getEtablissements(this.state.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
            NotificationManager.success('', "Établissement supprimé");
        }

        if((this.props.etabPosted !== prevProps.etabPosted) && this.props.etabPosted) {
            this.props.getEtablissements(this.state.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
            NotificationManager.success('', "Établissement ajouté");
        }

        if((this.props.etabEdited !== prevProps.etabEdited) && this.props.etabEdited) {
            this.props.getEtablissements(this.state.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
            NotificationManager.success('', "Établissement edité");
        }
        
        if((this.props.etabEnabled !== prevProps.etabEnabled) && this.props.etabEnabled) {
            this.props.getEtablissements(this.state.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
            NotificationManager.success('', "Établissement validé");
        }

        if((this.props.etabDisabled !== prevProps.etabDisabled) && this.props.etabDisabled) {
            this.props.getEtablissements(this.state.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
            NotificationManager.success('', "Établissement annulé");
        }

        if((this.props.etabsLinked !== prevProps.etabsLinked) && this.props.etabsLinked) {
            this.props.getEtablissements(this.state.idCountry, {
                "page" : this.state.page,
                "maxPerPage" : this.state.maxPerPage,
                "status" : (this.state.status !== null) ? this.state.status : null,
                "search" : (this.state.search !== null) ? this.state.search : null,
                "type" : (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo" : (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public" : (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name" : (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference" : (this.state.searchRef !== null) ? this.state.searchRef : null,
            });
            NotificationManager.success('', "Établissements reliés");
        }
    }

    searchLinkEtab = (value) => { 
        this.setState({ searchLinkEtab: true });             
        let data = {                    
            "page" : 1,
            "maxPerPage" : 50,
            "linksearch" : value,
            "idToLink": this.state.linkEtab.id
        }
        this.props.getEtablissements(localStorage.getItem('idCountry'), data);
    }

    onChangePage = (data) => this.setState({ page: data });

    byString = (o, s) =>{
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');

        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                if(s === "reference" || s === "name" || s === "type" ) o = o[k].toLowerCase();
                else o = o[k];              
            } else return;
        }

        return o;
    }

    handleOrderByKey(liste, key){
        if(this.getKeyState(key) === false) liste.reverse(); 
        else liste.sort((a, b) =>{
            if(this.state.orderKey && this.byString(a, this.state.orderKey) !== undefined){
                if(this.byString(a, this.state.orderKey) < this.byString(b, this.state.orderKey)) return -1;
                if(this.byString(a, this.state.orderKey) > this.byString(b, this.state.orderKey)) return 1;
            }
            return 0;
        });
    }

    generateZGOptions = () => {
        const zone = this.state.zgList;
        let table = [
            {
                label: "Zone",
                options: []
            },
            {
                label: "Ville",
                options: []
            },
            {
                label: "Commune",
                options: []
            },
            {
                label: "Quartier",
                options: []
            },
        ];

        Object.keys(zone).map((item) => {
                
                if ( ( item !== "name") && (item !== "id")) {
                    Object.keys(zone[item]).map((zoneG) => {

                        if ( ( zoneG !== "name") && (zoneG !== "id")) {
                            Object.keys(zone[item][zoneG]).map((ville) => {
            
                                if ( ( ville !== "name") && (ville !== "id")) {
                                    Object.keys(zone[item][zoneG][ville]).map((com) => {
            
                                        if ( ( com !== "name") && (com !== "id")) {
                                            Object.keys(zone[item][zoneG][ville][com]).map((quart) => {
            
                                                if ( ( quart !== "name") && (quart !== "id")) {
                                                    table[table.map((g) => g.label).indexOf("Quartier")].options.push(
                                                        {
                                                            value: zone[item][zoneG][ville][com][quart].id,
                                                            label: zone[item][zoneG][ville][com][quart].name,
                                                            ad: `${zone[item].name}, ${zone[item][zoneG].name}, ${zone[item][zoneG][ville].name}, ${zone[item][zoneG][ville][com].name}, ${zone[item][zoneG][ville][com][quart].name}`,
                                                            histo : {
                                                                country : zone[item].name,
                                                                countryID : zone[item].id,
                                                                zoneG :  zone[item][zoneG].name,
                                                                zoneA :  zoneG,
                                                                zoneGID: zone[item][zoneG].id,
                                                                ville : zone[item][zoneG][ville].name,
                                                                villeA: ville,
                                                                villeID: zone[item][zoneG][ville].id,
                                                                comID: zone[item][zoneG][ville][com].id,
                                                                comA: com,
                                                                com: zone[item][zoneG][ville][com].name,
                                                                quartID: zone[item][zoneG][ville][com][quart].id,
                                                                quart: zone[item][zoneG][ville][com][quart].name,
                                                                quartA: quart
                                                            }
                                                        }
                                                    )
                                                }
                                            });

                                            table[table.map((g) => g.label).indexOf("Commune")].options.push(
                                                {
                                                    value: zone[item][zoneG][ville][com].id,
                                                    label: zone[item][zoneG][ville][com].name,
                                                    ad: `${zone[item].name}, ${zone[item][zoneG].name}, ${zone[item][zoneG][ville].name}, ${zone[item][zoneG][ville][com].name}`,
                                                    histo : {
                                                        country : zone[item].name,
                                                        countryID : zone[item].id,
                                                        zoneG :  zone[item][zoneG].name,
                                                        zoneGID: zone[item][zoneG].id,
                                                        zoneA: zoneG,
                                                        ville : zone[item][zoneG][ville].name,
                                                        villeID: zone[item][zoneG][ville].id,
                                                        villeA: ville,
                                                        comID: zone[item][zoneG][ville][com].id,
                                                        com: zone[item][zoneG][ville][com].name,
                                                        comA: com
                                                    }
                                                }
                                            )
                                        }
                                    });
            
                                    table[table.map((g) => g.label).indexOf("Ville")].options.push(
                                        {
                                            value: zone[item][zoneG][ville].id,
                                            label: zone[item][zoneG][ville].name,
                                            ad: `${zone[item].name}, ${zone[item][zoneG].name}, ${zone[item][zoneG][ville].name}`,
                                            histo : {
                                                country : zone[item].name,
                                                countryID : zone[item].id,
                                                zoneG :  zone[item][zoneG].name,
                                                zoneGID: zone[item][zoneG].id,
                                                zoneA:  zoneG,
                                                ville : zone[item][zoneG][ville].name,
                                                villeID: zone[item][zoneG][ville].id,
                                                villeA: ville
                                            }
                                        }
                                    )
                                }
                            });
            
                            table[table.map((g) => g.label).indexOf("Zone")].options.push(
                                {
                                    value: zone[item][zoneG].id,
                                    label: zone[item][zoneG].name,
                                    ad: `${zone[item].name}, ${zone[item][zoneG].name}`,
                                    histo : {
                                        country : zone[item].name,
                                        countryID : zone[item].id,
                                        zoneG :  zone[item][zoneG].name,
                                        zoneGID: zone[item][zoneG].id,
                                        zoneA:  zoneG
                                    }
                                }
                            )
                        }
                    });
                }
        });
        
        return table;
    }

    formatedData = (data) => { 
        for(let i = 0; i < data.length ; i++){   
            Object.assign(data[i], { type: (data[i].InstitutionType !== undefined && data[i].InstitutionType.nameInstitutionType ) ? data[i].InstitutionType.nameInstitutionType : "" });            
        }        
        return data;
    }
    
    getKeyState = (key) => {
        let keyState = "";
        if(key === "reference") {
            keyState = this.state.orderByKey.reference;
        }else if(key === "status"){
            keyState = this.state.orderByKey.status;
        }else if(key === "name"){
            keyState = this.state.orderByKey.name;
        }else if(key === "type"){
            keyState = this.state.orderByKey.type;
        }else if(key === "isPrivate"){
            keyState = this.state.orderByKey.isPrivate;
        }
        return keyState;
    }

    handlePressFilterbyKey = (key) => this.setState({
        orderByKey: { [key]: !this.getKeyState(key) },
        orderKey: key,
    });

    handleFilter = (value, key) => {
        clearTimeout(this.timer);
        this.setState({ [key]: value });
    
        this.timer = setTimeout(() => {
            let data = {
                "page": 1,
                "maxPerPage": this.state.maxPerPage,
                "status": (this.state.status !== null) ? this.state.status : null,
                "search": (this.state.search !== null) ? this.state.search : null,
                "type": (this.state.protype !== null) ? this.state.protype : null,
                "zonegeo": (this.state.zoneGeo !== null) ? this.state.zoneGeo.value : null,
                "public": (this.state.searchPriv !== null) ? this.state.searchPriv : null,
                "name": (this.state.searchNom !== null) ? this.state.searchNom : null,
                "reference": (this.state.searchRef !== null) ? this.state.searchRef : null
            }
            this.props.getEtablissements(this.state.idCountry, data);
            this.setState({
                EtablissementReady: false,
                etabListReady: false
            });
        }, 1000);
    }
    
    render() {
        const formatGroupLabel = data => (<div><span>{data.label}</span></div>);
        const displayedEtabs = (this.state.etabList !== null && this.state.etabListReady) ? this.state.etabList : [];
        this.handleOrderByKey(displayedEtabs, this.state.orderKey);

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Établissements
                        <small>Gestion des établissements</small>
                    </h1>
                </section>
            
                <section className="content">

                    <div className="row etablissements">
                        <div className="col-md-12">
                            <table className="box tab-entete etablissements">
                                <tbody>
                                    <tr>
                                        <td className="warn"><i className="fa fa-exclamation-triangle" aria-hidden="true"></i></td>
                                        <td className="stat text-center">
                                            <div>
                                                <p>Statut</p>
                                                <img src={`/dist/img/icons/${this.state.orderByKey.status === false && this.state.orderKey === "status"   ? "icon-z-a" : "icon-a-z"}.png`} alt="" onClick={() =>this.handlePressFilterbyKey("status")}/>
                                            </div>
                                        </td>
                                        <td className="ref">
                                            <div>
                                                <p>Réf.</p>
                                                <img src={`/dist/img/icons/${this.state.orderByKey.reference === false && this.state.orderKey === "reference"   ? "icon-z-a" : "icon-a-z"}.png`} alt="" onClick={() =>this.handlePressFilterbyKey("reference")}/>
                                            </div>
                                        </td>
                                        <td className="nom">
                                            <div><p>Nom</p>
                                                <img src={`/dist/img/icons/${this.state.orderByKey.name === false && this.state.orderKey === "name"   ? "icon-z-a" : "icon-a-z"}.png`} alt="" onClick={() =>this.handlePressFilterbyKey("name")}/>
                                            </div>
                                        </td>
                                        <td className="type">
                                            <div>
                                                <p>Type</p>
                                                <img src={`/dist/img/icons/${this.state.orderByKey.type === false && this.state.orderKey === "type"   ? "icon-z-a" : "icon-a-z"}.png`} alt="" onClick={() =>this.handlePressFilterbyKey("type")}/>
                                            </div>
                                        </td>
                                        <td className="loc">
                                            <div>
                                                <p style={{display: 'inline-block'}}>Localisation </p>
                                                {this.props.loadingZoneGeo && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader zoneGeo" />}
                                            </div>
                                        </td>
                                        <td className="priv">
                                            <div>
                                                <p>Privé/Public</p>
                                                <img src={`/dist/img/icons/${this.state.orderByKey.isPrivate === false && this.state.orderKey === "isPrivate"   ? "icon-z-a" : "icon-a-z"}.png`} alt="" onClick={() =>this.handlePressFilterbyKey("isPrivate")}/>
                                            </div>
                                        </td>
                                        <td className="last">
                                            <ButtonExport />
                                            <ButtonAdd modal="#modalAddEditEtab" handleAdd={() => this.setState({
                                                ...this.state,
                                                selectedEtab: {},
                                                readOnly: false,
                                                validating: false
                                            })}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="warn noTop">
                                            <div>
                                                <div>
                                                </div>                                            
                                            </div>
                                        </td>
                                        <td className="stat noTop">
                                            <div>
                                                <div>
                                                    <CustomSelect
                                                    defaultText="Tous"
                                                    width={"130px"}
                                                    optionsList={[
                                                        {id: "", name: "Tous"},
                                                        {id: 2, name: "Actif"},
                                                        {id: 0, name: "Non valide"},
                                                        {id: 1, name: "Inactif"}
                                                    ]}
                                                    handleOptionClick={(id, name) => this.setState({ status: id !== "Tous" ? id : "" })}
                                                    />
                                                </div>                                      
                                            </div>
                                        </td>
                                        <td className="ref noTop">
                                            <div>
                                                <div>
                                                    <input 
                                                    type="text" 
                                                    placeholder="..." 
                                                    value={this.state.searchRef} 
                                                    onChange={(e) => this.handleFilter(e.target.value, "searchRef")} />
                                                </div>                                            
                                            </div>
                                        </td>
                                        <td className="nom noTop">
                                            <div>
                                                <div>
                                                    <input 
                                                    type="text" 
                                                    placeholder="..." 
                                                    value={this.state.searchNom} 
                                                    onChange={(e) => this.handleFilter(e.target.value, "searchNom")} />
                                                </div>                                            
                                            </div>
                                        </td>
                                        <td className="type noTop">
                                            <div>
                                                <div>
                                                    <CustomSelect
                                                    defaultText="Tous"
                                                    width={"160px"}
                                                    optionsList={this.state.typeList}
                                                    handleOptionClick={(id, name) => this.setState({ protype: id !== "Tous" ? id : "" })}
                                                    />
                                                </div>                                           
                                            </div>
                                        </td>
                                        <td className="loc noTop">
                                            <div>
                                                <Select
                                                options={this.generateZGOptions()}
                                                formatGroupLabel={formatGroupLabel}                                    
                                                value={this.state.zoneGeo}
                                                onChange={(newValue) => this.setState({ zoneGeo: newValue })}
                                                placeholder="Entrez une zone..."
                                                formatOptionLabel={(option, { context }) => {
                                                    /* context can be either `menu` or `value`
                                                        menu - dropdown
                                                        value - value displayed
                                                    */
                                                    return context === 'menu' ? (
                                                        <div className="select-react-option">
                                                            <p>{option.label}</p>
                                                            <small>{option.ad}</small>
                                                        </div>
                                                    ) : option.label;
                                                }}
                                                isClearable
                                                className="react-select-container"
                                                />                                         
                                            </div>
                                        </td>
                                        <td className="priv noTop">
                                            <div>
                                                <div>
                                                    <CustomSelect
                                                    defaultText="Tous"
                                                    width={"160px"}
                                                    optionsList={[
                                                        {id: '', name: "Tous"},
                                                        {id: 0, name: "Inconnu"},
                                                        {id: 1, name: "Public"},
                                                        {id: 2, name: "Privé"},
                                                        {id: 3, name: "Semi-privé"},
                                                    ]}
                                                    handleOptionClick={(id, name) => this.setState({searchPriv: id !== "Tous" ? id : ""})}
                                                    />
                                                </div>                                            
                                            </div>
                                        </td>
                                        <td className="last noTop">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="list etablissements">
                                <tbody>
                                    {this.state.etabListReady && this.state.etabList.map((etab, i) => {
                                        return (
                                            <tr className="list-row" key={`etab${i}`}>
                                                <td className="warn">
                                                    {etab.zonesGeo.addresszoneGeo.split(", ").length < 4 &&
                                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                    }
                                                </td>
                                                <td className="planning stat">
                                                    <div className={`tag ${etab.status ? "valid" : "warning"}`}>
                                                        <p>{etab.status ? "Actif" : "Non validé"}</p>
                                                    </div>
                                                </td>
                                                <td
                                                className="ref"
                                                data-toggle="modal"
                                                data-target="#modalAddEditEtab"
                                                onClick={() => this.setState({
                                                    ...this.state,
                                                    selectedEtab: etab,
                                                    readOnly: true,
                                                    validating: false
                                                })}
                                                >
                                                    {etab.reference}
                                                </td>
                                                <td
                                                className="nom"
                                                data-toggle="modal"
                                                data-target="#modalAddEditEtab"
                                                onClick={() => this.setState({
                                                    ...this.state,
                                                    selectedEtab: etab,
                                                    readOnly: true,
                                                    validating: false
                                                })}
                                                >
                                                    {etab.name}
                                                </td>
                                                <td className="type">
                                                    {etab.InstitutionType.nameInstitutionType}
                                                </td>
                                                <td className="loc  text-center">
                                                    {etab.zonesGeo.addresszoneGeo.split(", ").length < 4 &&
                                                        <i className="fa fa-exclamation-triangle" aria-hidden="true"></i>
                                                    }
                                                    &nbsp;
                                                    {etab.zonesGeo.addresszoneGeo}
                                                </td>
                                                <td className="planning priv">
                                                    <div className={`tag ${etab.isPrivate === 1 ? "valid" : etab.isPrivate === 2 ? "prive" : etab.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                                        <p>
                                                            {etab.isPrivate === 1 ? "Public"
                                                            : etab.isPrivate === 2 ? "Privé"
                                                            : etab.isPrivate === 3 ? "Semi-privé"
                                                            : "Inconnu"}
                                                        </p>
                                                    </div>
                                                </td>
                                                
                                                <td className="icons last">

                                                    {/* boutons admin */}
                                                    {(this.props.roleUser === "ROLE_ADMIN" || this.props.roleUser === "ROLE_QADMIN") &&
                                                    <>
                                                    <div
                                                    className="icon-btn"
                                                    data-toggle="modal"
                                                    data-target="#modalAddEditEtab"
                                                    onClick={() => this.setState({
                                                        ...this.state,
                                                        selectedEtab: etab,
                                                        readOnly: etab.status ? false : true,
                                                        validating: etab.status ? false : true
                                                    })}>
                                                        <img src={`/dist/img/icons/icon-${etab.status ? "edit" : "valid"}.png`} alt=""/>
                                                    </div>
                                                    <div
                                                    className="icon-btn"
                                                    data-toggle="modal"
                                                    data-target="#modalLink"
                                                    onClick={() => this.setState({linkEtab: etab})}>
                                                        <img src="/dist/img/icons/icon-link.png" alt=""/>
                                                    </div>
                                                    {etab.status ?
                                                    <div
                                                    className="icon-btn"
                                                    data-toggle="modal"
                                                    data-target="#modalDelete"
                                                    onClick={() => this.setState({idDelete: etab.id})}>
                                                        <img src={`/dist/img/icons/icon-delete.png`} alt=""/>
                                                    </div>
                                                    : 
                                                    <div
                                                    className="icon-btn"
                                                    onClick={() => this.props.disableEtablissement(etab.id, etab.isEdit)}>
                                                        <img src={`/dist/img/icons/icon-times.png`} alt=""/>
                                                    </div>
                                                    }
                                                    </>}

                                                    {/** bouton edit autres roles */}
                                                    {this.props.roleUser !== "ROLE_ADMIN" && this.props.roleUser !== "ROLE_QADMIN" &&
                                                    <div
                                                    className="icon-btn"
                                                    data-toggle={(etab.isEdit && etab.status === 0) || etab.status ? "modal": ""}
                                                    data-target={(etab.isEdit && etab.status === 0) || etab.status ? "#modalAddEditEtab": ""}
                                                    onClick={() => ((etab.isEdit && etab.status === 0) || etab.status) && this.setState({
                                                        ...this.state,
                                                        selectedEtab: etab,
                                                        readOnly: false,
                                                        validating: false
                                                    })}
                                                    disabled={!etab.isEdit && etab.status === 0}
                                                    >
                                                        <img src={`/dist/img/icons/icon-edit.png`} alt=""/>
                                                    </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    {this.state.etabList.length === 0 && !this.props.loadingEtabs &&
                                    <tr>
                                        <td><p>Aucun établissement</p></td>
                                    </tr>
                                    }
                                </tbody>
                            </table>

                            {this.state.etabListReady && this.state.maxPage > 1  && 
                            <Pagination
                                maxPage={this.state.maxPage}
                                page={this.state.page}
                                onChangePage={this.onChangePage}
                            ></Pagination>
                            }
                        </div>
                    </div>

                </section>

                <PopupAddEditEtab
                handleSubmit={(submit) => this.setState({ handleSubmit: submit })}
                addEtablissement={(country, etab) => this.props.postEtablissement(country, etab)}
                etab={this.state.selectedEtab}
                editEtablissement={(etab) => this.props.editEtablissement(etab)}
                typeEtabList={this.state.typeList}
                zgList={this.state.zgList}
                zoneGeo={this.state.zgList}
                country={{id: this.state.idCountry, name: this.props.country}}
                readOnly={this.state.readOnly}
                validating={this.state.validating}
                deleteEtab={(id) => this.setState({idDelete: id})}
                enableEtab={(id, isEdit) => this.props.enableEtablissement(id, isEdit)}
                enableEditEtab={(id, isEdit) => {
                    this.props.enableEtablissement(id, isEdit);
                    this.setState({
                        ...this.state,
                        validating: false,
                        readOnly: false
                    })
                }}
                disableEtab={(id, isEdit) => this.props.disableEtablissement(id, isEdit)}
                typeEtabLoading={this.props.loadingTypeEtab}
                zgLoading={this.props.loadingZoneGeo}
                countryList={this.state.countryList}
                getByCountry={(name) => this.props.getByCountry(name)}
                verif={(data) => this.props.verifEtablissement(data)}
                doublons={this.state.doublon}
                />

                <PopupLinkEtab
                searchEtab={(value)=> this.searchLinkEtab(value)}
                etab={this.state.linkEtab}
                etabList={this.state.etabProList}
                typeEtabList={this.state.typeList}
                linkEtabs={(id1, id2) => this.props.linkEtabs(id1, id2)}
                loading={this.props.loadingEtabs}
                />

                <PopupDelete
                type="établissement"
                deleteEtablissement={(id) => this.props.deleteEtablissement(id)}
                id={this.state.idDelete}
                />
                
                <PopupConfirmation message={'Êtes-vous sûr ?'} handleSubmit={this.state.handleSubmit}/>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingEtabs, etabs, etabEdited, etabPosted, etabDeleted, etabEnabled, etabDisabled, etabsLinked, loadingVerifEtab, verif} = state.etab;
    const { loadingUserCountry, userCountry, loadingAllZones, listZones, loadingZoneGeo, zoneGeo, idCountry, country } = state.zoneGeo;
    const { loadingTypeEtab, typesEtab } = state.typeEtab;
    const { roleUser } = state.authentification;
    return { loadingVerifEtab, verif, loadingUserCountry, userCountry, loadingZoneGeo, zoneGeo, loadingAllZones, listZones, loadingEtabs, etabs, etabEdited, etabPosted, etabDeleted, loadingTypeEtab, typesEtab, idCountry, country, etabEnabled, etabsLinked, etabDisabled, roleUser };
}
const actionCreators = {
    getAllTypeEtab: typeEtabActions.getAllTypeEtab,
    getUserCountry: zoneGeoActions.getUserCountry,
    getAllZonesGeo: zoneGeoActions.getAllZonesGeo,
    getEtablissements: etabActions.getEtablissements,
    postEtablissement: etabActions.postEtablissement,
    editEtablissement: etabActions.editEtablissement,
    deleteEtablissement: etabActions.deleteEtablissement,
    enableEtablissement: etabActions.enableEtablissement,
    disableEtablissement: etabActions.disableEtablissement,
    verifEtablissement: etabActions.verifEtablissement,
    linkEtabs: etabActions.linkEtabs,
    getByCountry: zoneGeoActions.getByCountry,
};

const Etablissements = connect(mapState, actionCreators)(EtablissementsPage);

export default Etablissements;