import React from 'react';

class ButtonAdd extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            disabled: this.props.disabled ? true : false
        }
    }

    handleAdd = () => {
        if(!this.state.disabled) this.props.handleAdd(this.props.id);
    }

    render() {
        return(
            <button
            className="btn-add"
            disabled={this.state.disabled}
            data-toggle={this.state.disabled ? "" : "modal"}
            data-target={this.props.modal ? this.props.modal : "#modalAjout"}
            onClick={this.handleAdd}
            >
                +
            </button>
        )
    }
}

export default ButtonAdd;