import React from 'react';
import { connect } from 'react-redux';
import { etabActions } from '../../actions/etab.actions';

import CustomSelect from './CustomSelect';
import PopupChooseZoneGeo from './PopupChooseZoneGeo';
import Select from 'react-select';
import Moment from 'react-moment';
import ReactLoading from 'react-loading';
import Pagination from '../Pagination';

class PopupAddEditEtabPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            idTypeEtab: -1,
            typeEtab: "Sélectionnez un type d'établissement",
            email: "",
            tel: "",
            fax: "",
            priv: 0,
            internet: 0,
            assurance: 0,
            zgList: this.props.zgList,
            ad1: "",
            ad2: "",
            lat: "",
            lon: "",
            histo: [],
            country: {
                id:  null,
                name:  "Sélectionnez un pays"
            },
            zoneGeo: {},
            province: {
                id: null,
                label: null,
                name: "Sélectionnez une province"
            },
            ville: {
                id: null,
                label: null,
                name: "Sélectionnez une ville"
            },
            commune: {
                id: null,
                label: null,
                name: "Sélectionnez une commune"
            },
            quartier: {
                id: null,
                label: null,
                name: "Sélectionnez un quartier"
            },
            zoneID: -1,
            loadingZG: false,
            doublon : {}
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.zgList !== prevProps.zgList) this.setState({zgList: this.props.zgList});

        if(this.props.zoneGeo !== prevProps.zoneGeo) {
            this.setState({
                ...this.state,
                zoneGeo: this.props.zoneGeo,
                loadingZG: false
            });
        }

        if(this.props.etab !== prevProps.etab) {
            const edit = Object.keys(this.props.etab).length > 0;
            edit && this.props.getByCountry(this.props.etab.zonesGeo.fulldatazoneGeo.country.name);
            const fullZone = edit ? this.props.etab.zonesGeo.fulldatazoneGeo : {};
            if(edit) this.props.getHistoEtab(this.props.etab.id);

            this.setState({
                ...this.state,
                name: edit ? this.props.etab.name : "",
                idTypeEtab: edit ? this.props.etab.InstitutionType.idInstitutionType : -1,
                typeEtab: edit ? this.props.etab.InstitutionType.nameInstitutionType : "Sélectionnez un type d'établissement",
                email: edit ? this.props.etab.mail !== null ? this.props.etab.mail : "" : "",
                tel: edit ? this.props.etab.landLine : "",
                fax: edit ? this.props.etab.faxNumber : "",
                priv: edit ? this.props.etab.isPrivate : 0,
                internet: edit ? this.props.etab.withInternetConn : 0,
                assurance: edit ? this.props.etab.withInsurance : 0,
                ad1: edit ? this.props.etab.address1 : "",
                ad2: edit ? this.props.etab.address2 : "",
                lat: edit ? this.props.etab.CoordLat : "",
                lon: edit ? this.props.etab.CoordLong : "",
                country: {
                    id:  edit ? fullZone.country.id !== "" ? fullZone.country.id : null : null,
                    name:  edit ? fullZone.country.name !== "" ? fullZone.country.name : "Sélectionnez un pays" : "Sélectionnez un pays"
                },
                province: {
                    id: edit ? fullZone.zone.id !== "" ? fullZone.zone.id : null : null,
                    name: edit ? fullZone.zone.name !== "" ? fullZone.zone.name : "Sélectionnez une province" : "Sélectionnez une province"
                },
                ville: {
                    id: edit ? fullZone.ville.id !== "" ? fullZone.ville.id : null : null,
                    name: edit ? fullZone.ville.name !== "" ? fullZone.ville.name : "Sélectionnez une ville" : "Sélectionnez une ville"
                },
                commune: {
                    id: edit ? fullZone.commune.id !== "" ? fullZone.commune.id : null : null,
                    name: edit ? fullZone.commune.name !== "" ? fullZone.commune.name : "Sélectionnez une commune" : "Sélectionnez une commune"
                },
                quartier: {
                    id: edit ? fullZone.quartier.id !== "" ? fullZone.quartier.id : null : null,
                    name: edit ? fullZone.quartier.name !== "" ? fullZone.quartier.name : "Sélectionnez un quartier" : "Sélectionnez un quartier"
                },
                zoneID: edit ? this.props.etab.zonesGeo.idzoneGeo : -1,
                zoneGeo: {}
            })
        }

        if(this.state.name !== prevState.name){
            setTimeout(() => {                
                this.props.verif({
                    name : this.state.name,
                    page : 1,
                    maxPerPage: 5,
                    id: this.props.etab.id,
                    idCountry: this.props.etab.zonesGeo ? this.props.etab.zonesGeo.fulldatazoneGeo.country.name : localStorage.getItem("nameCountry")
                })
            }, 1500);
        }

        if((this.props.loadingHistoEtab !== prevProps.loadingHistoEtab) && !this.props.loadingHistoEtab) {
            if((this.props.histo !== undefined && (this.props.histo !== "null"))) {
                this.setState({ histo: JSON.parse(this.props.histo) });
            }
            if(this.props.histo === "null") this.setState({ histo: [] });
        }
    }

    handleZoneSearch = (country, province, ville, commune, zoneID) => this.setState({zoneGeo: {
        value: zoneID,
        name: commune ? commune : ville ? ville : province ? province : country,
        ad: `${country}${province ? `, ${province}` : ""}${ville ? `, ${ville}` : ""}${commune ? `, ${commune}` : ""}`
    }});

    generateZGOptions = () => {
        const zone = this.state.zgList;
        let table = [
            {
                label: "Pays",
                options: [{ value: this.props.country.id, label: this.props.country.name, ad: this.props.country.name }]
            },
            {
                label: "Zone",
                options: []
            },
            {
                label: "Ville",
                options: []
            },
            {
                label: "Commune",
                options: []
            },
            {
                label: "Quartier",
                options: []
            },
        ];

        Object.keys(zone).map((item, i) => {

            Object.keys(zone[item]).map((ville) => {

                if((ville !== "name" && (ville !== "id"))) {
                    Object.keys(zone[item][ville]).map((com) => {

                        if((com !== "name") && (com !== "id")) {
                            Object.keys(zone[item][ville][com]).map((quart) => {

                                if((quart !== "name") && (quart !== "id")) {
                                    table[table.map((g) => g.label).indexOf("Quartier")].options.push(
                                        {
                                            value: zone[item][ville][com][quart].id,
                                            label: zone[item][ville][com][quart].name,
                                            ad: `${this.props.country.name}, ${zone[item].name}, ${zone[item][ville].name}, ${zone[item][ville][com].name}, ${zone[item][ville][com][quart].name}`
                                        }
                                    )
                                }
                            });

                            table[table.map((g) => g.label).indexOf("Commune")].options.push(
                                {
                                    value: zone[item][ville][com].id,
                                    label: zone[item][ville][com].name,
                                    ad: `${this.props.country.name}, ${zone[item].name}, ${zone[item][ville].name}, ${zone[item][ville][com].name}`
                                }
                            )
                        }
                    });
                
                    table[table.map((g) => g.label).indexOf("Ville")].options.push(
                        {
                            value: zone[item][ville].id,
                            label: zone[item][ville].name,
                            ad: `${this.props.country.name}, ${zone[item].name}, ${zone[item][ville].name}`
                        }
                    )
                }
            });

            table[table.map((g) => g.label).indexOf("Zone")].options.push(
                {
                    value: zone[item].id,
                    label: zone[item].name,
                    ad: `${this.props.country.name}, ${zone[item].name}`
                }
            )
        });
        return table;
    }

    addressIntoOption = (z) => {
        let label = z.addresszoneGeo.split(", ");
        return {
            value: z.idzoneGeo,
            label: label[label.length-1],
            ad: z.addresszoneGeo
        };
    }

    handleSubmit = (e) => {
        e.preventDefault();

        if((this.state.name !== "") && (this.state.idTypeEtab !== -1) && (this.state.zoneID !== -1)) {
            
            const adComplete = (
                this.state.quartier.id !== null
                || (this.listeQuartier().length === 0 && this.state.commune.id !== null)
                || (this.listeCommune().length === 0 && this.state.ville.id !== null)
                || (this.listeVille().length === 0 && this.state.province.id !== null)
                || (Object.keys(this.state.zoneGeo).length === 0 && this.state.country.id !== null)
            );

            if(adComplete) {
                if(Object.keys(this.props.etab).length > 0) {
                    this.props.editEtablissement(
                        {
                            id: this.props.etab.id,
                            name: this.state.name,
                            idTypeEtab: this.state.idTypeEtab,
                            email: this.state.email,
                            tel: this.state.tel,
                            fax: this.state.fax,
                            zoneGeo: this.state.zoneID,
                            priv: this.state.priv,
                            internet: this.state.internet,
                            assurance: this.state.assurance,
                            address1: this.state.ad1,
                            address2: this.state.ad2,
                            coordLat: this.state.lat,
                            coordLong: this.state.lon,
                        }
                    );
                } else {
                    this.props.addEtablissement(
                        this.state.country.id,
                        {
                            name: this.state.name,
                            idTypeEtab: this.state.idTypeEtab,
                            email: this.state.email,
                            tel: this.state.tel,
                            fax: this.state.fax,
                            zoneGeo: this.state.zoneID,
                            priv: this.state.priv,
                            internet: this.state.internet,
                            assurance: this.state.assurance,
                            address1: this.state.ad1,
                            address2: this.state.ad2,
                            coordLat: this.state.lat,
                            coordLong: this.state.lon,
                        }
                    )
                }
            
                this.btnElement.click()
            }
        }

    }

    listeVille = () => {
        let table = [];
        const zg = Object.keys(this.state.zoneGeo);

        if(this.state.country.id !== null && this.state.province.id !== null) {
            const indexP = zg.map(zone => this.state.zoneGeo[zone].id).indexOf(this.state.province.id);
            const prov = this.state.zoneGeo[zg[indexP]];

            if(prov !== undefined){
                Object.keys(prov).map((item, i) => {
                    if((item !== "name") && (item !== "id")) {
                        table.push({
                            id: prov[item].id,
                            name: prov[item].name,
                            label: item
                        });
                    }
                });                
            }
        }

        return table;
    }

    listeCommune = () => {
        let table = [];
        let indexP, indexV, prov, vil;
        const zg = Object.keys(this.state.zoneGeo);

        if(this.state.country.id !== null && this.state.province.id !== null && this.state.ville.id !== null) {
            if(zg.length > 0) {
                indexP = zg.map(zone => this.state.zoneGeo[zone].id).indexOf(this.state.province.id);
                prov = this.state.zoneGeo[zg[indexP]];
                indexV = Object.keys(prov).map(zone => prov[zone].id).indexOf(this.state.ville.id);
                vil = prov[Object.keys(prov)[indexV]];
            }

            if(prov !== undefined){
                if(vil !== undefined){
                    Object.keys(vil).map((item, i) => {
                        if((item !== "name") && (item !== "id")) {
                            table.push({
                                id: vil[item].id,
                                name: vil[item].name,
                                label: item
                            });
                        }
                    }); 
                }
            }
        }

        return table;
    }

    listeQuartier = () => {
        let table = [];
        let indexP, indexV, indexC, prov, vil, com;
        const zg = Object.keys(this.state.zoneGeo);

        if(this.state.country.id !== null && this.state.province.id !== null && this.state.ville.id !== null && this.state.commune.id !== null) {
            if(zg.length > 0) {
                indexP = zg.map(zone => this.state.zoneGeo[zone].id).indexOf(this.state.province.id);
                prov = this.state.zoneGeo[zg[indexP]];
                indexV = Object.keys(prov).map(zone => prov[zone].id).indexOf(this.state.ville.id);
                vil = prov[Object.keys(prov)[indexV]];
                indexC = Object.keys(vil).map(zone => vil[zone].id).indexOf(this.state.commune.id);
                com = vil[Object.keys(vil)[indexC]];
            }
            
            if(prov !== undefined){
                if(vil !== undefined){
                    if(com !== undefined) {
                        Object.keys(com).map((item, i) => {
                            if((item !== "name") && (item !== "id")) {
                                table.push({
                                    id: com[item].id,
                                    name: com[item].name,
                                    label: item
                                });
                            }
                        }); 
                    }
                }
            }
        }
        
        return table;
    }
    
    onChangePage = (data) => this.props.verif({
        name : this.state.name,
        page : data,
        maxPerPage: 5,
        id: this.props.etab.id,
        idCountry: this.props.etab.zonesGeo ? this.props.etab.zonesGeo.fulldatazoneGeo.country.name : localStorage.getItem("nameCountry")
    });

    renderDoublons = (dataSend) => {
        let data = dataSend.data;
        let table = [];
        let elem = [];

        elem.push(
            <tr key={"tr"} style={{fontWeight: "bold"}}>
                <td>Référence</td>
                <td>Nom</td>
                <td>Adresse</td>
            </tr>
        )

        for(let i =0; i< data.length; i++){
            elem.push(
                <tr key={i}>
                    <td>{data[i].reference}</td>
                    <td>{data[i].name}</td>
                    <td>{(data[i].zonesGeo) ? data[i].zonesGeo.addresszoneGeo : ''}</td>
                </tr>
            )
        }

        table.push(
            <div key={0} style={{border: "3px solid red",padding: "10px"}}>
                <div style={{fontWeight: "bold"}}>Établissement similaires déjà enregistrés</div>
                <table>
                    <tbody>{elem}</tbody>
                </table>
                {dataSend.nbrPage > 1 &&
                    <Pagination
                        maxPage={dataSend.nbrPage}
                        page={dataSend.page}
                        onChangePage={this.onChangePage}
                    ></Pagination>
                }
            </div>
        )

        return table;
    }

    render() {
        const edit = Object.keys(this.props.etab).length > 0;
        const formatGroupLabel = data => (<div><span>{data.label}</span></div>);
        const doublons = (this.props.doublons.status) ? this.renderDoublons(this.props.doublons) : {};

        return(
            <div>
                <div className="modal fade in" id="modalAddEditEtab" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditEtabLabel" animate="animate">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un établissement {edit ? `- ${this.props.etab.reference} - ${this.props.etab.name}` : ""}</h1>
                            </div>
                            <div className="modal-body">
                                <form id="formAjout">

                                    <div className={edit ? "nav-tabs-custom" : ""}>
                                        {edit &&
                                        <ul className="nav nav-tabs">
                                            <li><a href="#tab_1" data-toggle="tab" className="active">Général</a></li>
                                            {!this.props.validating && <li><a href="#tab_2" data-toggle="tab">Adresse</a></li>}
                                            {this.props.readOnly && <li><a href="#tab_3" data-toggle="tab">Professionnels de santé</a></li>}
                                            {this.props.readOnly && !this.props.validating && <li><a href="#tab_4" data-toggle="tab">Historique</a></li>}
                                        </ul>
                                        }
                                        <div className={edit ? "tab-content" : ""}>
                                            <div className={edit ? "tab-pane active" : ""} id="tab_1">
                                                {!edit && <h2>Informations générales</h2>}
                                                <div style={(this.props.doublons.status) && this.state.name !== "" ? {display:'flex', justifyContent:"space-between"} : {}}>
                                                    <div style={(this.props.doublons.status) ? { flex: "1 1 0%"} : {}}>
                                                        <label>Nom</label>
                                                        <input type="text"
                                                        required
                                                        value={this.state.name}
                                                        onChange={(e) => {
                                                            const cursor = e.target.selectionStart;
                                                            this.setState({ name: e.target.value.toUpperCase() });
                                                            e.target.selectionStart = cursor;
                                                        }}
                                                        disabled={this.props.readOnly}
                                                        className={this.props.etab.isEdit && this.props.validating && (this.props.etab.name !== this.props.etab.Old_name) ? "highlight" : ""}
                                                        />

                                                        <label style={{display: "inline-block"}}>Type</label>
                                                        {this.props.typeEtabLoading && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader typeEtab" />}
                                                        <div
                                                        className={this.props.etab.isEdit && this.props.validating && (this.props.etab.InstitutionType.idInstitutionType !== this.props.etab.Old_InstitutionType.idInstitutionType) ? "highlighted" : ""}
                                                        >
                                                            <CustomSelect
                                                            defaultText={this.state.typeEtab}
                                                            optionsList={this.props.typeEtabList}
                                                            handleOptionClick={(id, name) => this.setState({...this.state,
                                                                idTypeEtab: id,
                                                                typeEtab: name
                                                            })}
                                                            disabled={this.props.readOnly}
                                                            />
                                                        </div>
                                                        {this.props.validating &&
                                                            <div>
                                                                <label>Zone géographique</label>
                                                                <div className="zoneGeo-select">
                                                                    <Select
                                                                    options={[]}
                                                                    formatGroupLabel={formatGroupLabel}
                                                                    value={{
                                                                        value: this.props.etab.zonesGeo.idzoneGeo,
                                                                        label: this.props.etab.zonesGeo.addresszoneGeo
                                                                    }}
                                                                    onChange={(newValue) => {}}
                                                                    placeholder="Entrez une zone..."
                                                                    formatOptionLabel={(option, { context }) => {
                                                                        /* context can be either `menu` or `value`
                                                                            menu - dropdown
                                                                            value - value displayed
                                                                        */
                                                                        return context === 'menu' ? (
                                                                            <div className="select-react-option">
                                                                                <p>{option.label}</p>
                                                                                <small>{option.ad}</small>
                                                                            </div>
                                                                        ) : option.label;
                                                                    }}
                                                                    isClearable
                                                                    className={this.props.etab.isEdit && this.props.validating && (this.props.etab.zonesGeo.idzoneGeo !== this.props.etab.Old_zonesGeo.idzoneGeo) ? "highlight react-select-container" : "react-select-container"}
                                                                    isDisabled
                                                                    />
                                                                </div>
                                                            </div>
                                                        }

                                                        <label>Adresse email</label>
                                                        <input
                                                        type="text"
                                                        required
                                                        value={this.state.email}
                                                        onChange={(e) => this.setState({email: e.target.value})}
                                                        disabled={this.props.readOnly}
                                                        className={this.props.etab.isEdit && this.props.validating && (this.props.etab.mail !== this.props.etab.Old_mail) ? "highlight" : ""}
                                                        />

                                                        <div className={(this.props.doublons.status && this.state.name !== "" ) ? "" : "row"}>
                                                            <div className={(this.props.doublons.status && this.state.name !== "" ) ? "" : "col-md-6"}>
                                                                <label>Téléphone fixe</label>
                                                                <input type="tel"
                                                                required
                                                                value={this.state.tel}
                                                                onChange={(e) => this.setState({tel: e.target.value})}
                                                                disabled={this.props.readOnly}
                                                                className={this.props.etab.isEdit && this.props.validating && (this.props.etab.landLine !== this.props.etab.Old_landLine) ? "highlight" : ""}
                                                                />
                                                            </div>
                                                            <div className={(this.props.doublons.status && this.state.name !== "" ) ? "" : "col-md-6"}>
                                                                <label>Fax</label>
                                                                <input type="text"
                                                                value={this.state.fax}
                                                                onChange={(e) => this.setState({fax: e.target.value})}
                                                                disabled={this.props.readOnly}
                                                                className={this.props.etab.isEdit && this.props.validating && (this.props.etab.faxNumber !== this.props.etab.Old_faxNumber) ? "highlight" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                        </div>

                                                        {this.props.doublons.status && this.state.name !== "" &&
                                                        <div style={{ flex: "1 1 0%", marginLeft:5}}>
                                                            {doublons}
                                                        </div>
                                                        }
                                                </div>
                                                {!this.props.validating &&
                                                <div className="row radio-div">
                                                    <div className="col-md-3">
                                                        <label>Privé / Public</label>
                                                        <label>Connexion internet</label>
                                                        <label>Assurance</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="priv"
                                                                checked={this.state.priv === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({priv: parseInt(e.target.value)})}
                                                                id="privInconnu"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="privInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="priv"
                                                                checked={this.state.priv === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({priv: parseInt(e.target.value)})}
                                                                id="privPublic"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="privPublic">Public</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="priv"
                                                                checked={this.state.priv === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({priv: parseInt(e.target.value)})}
                                                                id="privPrive"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="privPrive">Privé</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="priv"
                                                                checked={this.state.priv === 3}
                                                                value={3}
                                                                onChange={(e) => this.setState({priv: parseInt(e.target.value)})}
                                                                id="privSemi"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="privSemi">Semi-privé</label>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="internet"
                                                                checked={this.state.internet === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({internet: parseInt(e.target.value)})}
                                                                id="internetInconnu"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="internetInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="internet"
                                                                checked={this.state.internet === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({internet: parseInt(e.target.value)})}
                                                                id="internetOui"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="internetOui">Oui</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="internet"
                                                                checked={this.state.internet === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({internet: parseInt(e.target.value)})}
                                                                id="internetNon"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="internetNon">Non</label>
                                                            </div>
                                                        </div>
                                                    
                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="assurance"
                                                                checked={this.state.assurance === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({assurance: parseInt(e.target.value)})}
                                                                id="assuranceInconnu"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="assuranceInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="assurance"
                                                                checked={this.state.assurance === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({assurance: parseInt(e.target.value)})}
                                                                id="assuranceAvec"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="assuranceAvec">Avec</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="assurance"
                                                                checked={this.state.assurance === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({assurance: parseInt(e.target.value)})}
                                                                id="assuranceSans"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="assuranceSans">Sans</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="assurance"
                                                                checked={this.state.assurance === 3}
                                                                value={3}
                                                                onChange={(e) => this.setState({assurance: parseInt(e.target.value)})}
                                                                id="assuranceMixte"
                                                                disabled={this.props.readOnly}
                                                                />
                                                                <label htmlFor="assuranceMixte">Mixte</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                }
                                                
                                                {!edit &&
                                                    <div>
                                                        <h2>Adresse</h2>

                                                        <label>Pays</label>
                                                        <CustomSelect
                                                        defaultText={this.state.country.name}
                                                        optionsList={this.props.countryList}
                                                        handleOptionClick={(id, name) => {
                                                            this.props.getByCountry(name);
                                                            this.setState({
                                                                ...this.state,
                                                                zoneID: id,
                                                                country: {
                                                                    id: parseInt(id),
                                                                    name: name
                                                                },
                                                                province: {
                                                                    id:  null,
                                                                    name: "Sélectionnez une province",
                                                                    label: null 
                                                                },
                                                                ville: {
                                                                    id: null,
                                                                    name: "Sélectionnez une ville",
                                                                    label: null
                                                                },
                                                                commune: {
                                                                    id: null,
                                                                    name: "Sélectionnez une commune",
                                                                    label: null
                                                                },
                                                                quartier: {
                                                                    id:  null,
                                                                    name: "Sélectionnez un quartier",
                                                                    label: null 
                                                                },
                                                                loadingZG: true
                                                            })
                                                        }}
                                                        />

                                                        {this.state.loadingZG && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader" />}

                                                        {this.state.country.id !== null && Object.keys(this.state.zoneGeo).length > 0 &&
                                                        <div>
                                                            <label>Province</label>
                                                            <CustomSelect
                                                            defaultText={this.state.province.name}
                                                            optionsList={(this.state.zoneGeo !== undefined) ? Object.keys(this.state.zoneGeo).map((item, i) => ({
                                                                id: this.state.zoneGeo[item].id,
                                                                name: this.state.zoneGeo[item].name,
                                                                label: item
                                                            })) : []}
                                                            handleOptionClick={(id, name, label) => {this.setState({
                                                                ...this.state,
                                                                zoneID: id,
                                                                province: {
                                                                    id: parseInt(id),
                                                                    name: name,
                                                                    label: label
                                                                },
                                                                ville: {
                                                                    id: null,
                                                                    name: "Sélectionnez une ville",
                                                                    label: null
                                                                },
                                                                commune: {
                                                                    id: null,
                                                                    name: "Sélectionnez une commune",
                                                                    label: null
                                                                },
                                                                quartier: {
                                                                    id:  null,
                                                                    name: "Sélectionnez un quartier",
                                                                    label: null 
                                                                }
                                                            });}}
                                                            />
                                                        </div>
                                                        }

                                                        {this.state.province.id !== null  && this.listeVille().length > 0 &&
                                                        <div>
                                                            <label>Ville</label>
                                                            <CustomSelect
                                                            defaultText={this.state.ville.name}
                                                            optionsList={this.listeVille()}
                                                            handleOptionClick={(id, name, label) => this.setState({
                                                                ...this.state,
                                                                zoneID: id,
                                                                ville: {
                                                                    id: parseInt(id),
                                                                    name: name,
                                                                    label: label
                                                                },
                                                                commune: {
                                                                    id: null,
                                                                    name: "Sélectionnez une commune",
                                                                    label: null
                                                                },
                                                                quartier: {
                                                                    id:  null,
                                                                    name: "Sélectionnez un quartier",
                                                                    label: null 
                                                                }
                                                            })}
                                                            />
                                                        </div>
                                                        }

                                                        {this.state.ville.id !== null && this.listeCommune().length > 0 &&
                                                        <div>
                                                            <label>Commune</label>
                                                            <CustomSelect
                                                            defaultText={this.state.commune.name}
                                                            optionsList={this.listeCommune()}
                                                            handleOptionClick={(id, name, label) => this.setState({
                                                                ...this.state,
                                                                zoneID: id,
                                                                commune: {
                                                                    id: parseInt(id),
                                                                    name: name,
                                                                    label: label
                                                                },
                                                                quartier: {
                                                                    id:  null,
                                                                    name: "Sélectionnez un quartier",
                                                                    label: null 
                                                                }
                                                            })}
                                                            />
                                                        </div>
                                                        }

                                                        {this.state.commune.id !== null && this.listeQuartier().length > 0 && 
                                                        <div>
                                                            <label>Quartier</label>
                                                            <CustomSelect
                                                            defaultText={this.state.quartier.name}
                                                            optionsList={this.listeQuartier()}
                                                            handleOptionClick={(id, name, label) => this.setState({
                                                                ...this.state,
                                                                zoneID: id,
                                                                quartier: {
                                                                    id: parseInt(id),
                                                                    name: name,
                                                                    label: label
                                                                }
                                                            })}
                                                            />
                                                        </div>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                            {edit && !this.props.validating &&
                                                <div className="tab-pane" id="tab_2">
                                                    
                                                    <label>Pays</label>
                                                    <CustomSelect
                                                    defaultText={this.state.country.name}
                                                    optionsList={this.props.countryList}
                                                    handleOptionClick={(id, name) => {
                                                        this.props.getByCountry(name);
                                                        this.setState({
                                                            ...this.state,
                                                            zoneID: id,
                                                            country: {
                                                                id: parseInt(id),
                                                                name: name
                                                            },
                                                            province: {
                                                                id:  null,
                                                                name: "Sélectionnez une province",
                                                                label: null 
                                                            },
                                                            ville: {
                                                                id: null,
                                                                name: "Sélectionnez une ville",
                                                                label: null
                                                            },
                                                            commune: {
                                                                id: null,
                                                                name: "Sélectionnez une commune",
                                                                label: null
                                                            },
                                                            quartier: {
                                                                id:  null,
                                                                name: "Sélectionnez un quartier",
                                                                label: null 
                                                            }
                                                        })
                                                    }}
                                                    />

                                                    {this.state.country.id !== null && Object.keys(this.state.zoneGeo).length > 0 &&
                                                    <div>
                                                        <label>Province</label>
                                                        <CustomSelect
                                                        defaultText={this.state.province.name}
                                                        optionsList={(this.state.zoneGeo !== undefined) ? Object.keys(this.state.zoneGeo).map((item, i) => ({
                                                            id: this.state.zoneGeo[item].id,
                                                            name: this.state.zoneGeo[item].name,
                                                            label: item
                                                        })) : []}
                                                        handleOptionClick={(id, name, label) => {this.setState({
                                                            ...this.state,
                                                            zoneID: id,
                                                            province: {
                                                                id: parseInt(id),
                                                                name: name,
                                                                label: label
                                                            },
                                                            ville: {
                                                                id: null,
                                                                name: "Sélectionnez une ville",
                                                                label: null
                                                            },
                                                            commune: {
                                                                id: null,
                                                                name: "Sélectionnez une commune",
                                                                label: null
                                                            },
                                                            quartier: {
                                                                id:  null,
                                                                name: "Sélectionnez un quartier",
                                                                label: null 
                                                            }
                                                        });}}
                                                        />
                                                    </div>
                                                    }

                                                    {this.state.province.id !== null  && this.listeVille().length > 0 &&
                                                    <div>
                                                        <label>Ville</label>
                                                        <CustomSelect
                                                        defaultText={this.state.ville.name}
                                                        optionsList={this.listeVille()}
                                                        handleOptionClick={(id, name, label) => this.setState({
                                                            ...this.state,
                                                            zoneID: id,
                                                            ville: {
                                                                id: parseInt(id),
                                                                name: name,
                                                                label: label
                                                            },
                                                            commune: {
                                                                id: null,
                                                                name: "Sélectionnez une commune",
                                                                label: null
                                                            },
                                                            quartier: {
                                                                id:  null,
                                                                name: "Sélectionnez un quartier",
                                                                label: null 
                                                            }
                                                        })}
                                                        />
                                                    </div>
                                                    }

                                                    {this.state.ville.id !== null && this.listeCommune().length > 0 &&
                                                    <div>
                                                        <label>Commune</label>
                                                        <CustomSelect
                                                        defaultText={this.state.commune.name}
                                                        optionsList={this.listeCommune()}
                                                        handleOptionClick={(id, name, label) => this.setState({
                                                            ...this.state,
                                                            zoneID: id,
                                                            commune: {
                                                                id: parseInt(id),
                                                                name: name,
                                                                label: label
                                                            },
                                                            quartier: {
                                                                id:  null,
                                                                name: "Sélectionnez un quartier",
                                                                label: null 
                                                            }
                                                        })}
                                                        />
                                                    </div>
                                                    }

                                                    {this.state.commune.id !== null && this.listeQuartier().length > 0 && 
                                                    <div>
                                                        <label>Quartier</label>
                                                        <CustomSelect
                                                        defaultText={this.state.quartier.name}
                                                        optionsList={this.listeQuartier()}
                                                        handleOptionClick={(id, name, label) => this.setState({
                                                            ...this.state,
                                                            zoneID: id,
                                                            quartier: {
                                                                id: parseInt(id),
                                                                name: name,
                                                                label: label
                                                            }
                                                        })}
                                                        />
                                                    </div>
                                                    }
                                                    <div className="row comp">
                                                        <div className="col-md-6">
                                                            <label>Complément 1</label>
                                                            <input
                                                            type="text"
                                                            required
                                                            value={this.state.ad1}
                                                            onChange={(e) => this.setState({ad1: e.target.value})}
                                                            disabled={this.props.readOnly}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Complément 2</label>
                                                            <input
                                                            type="text"
                                                            required
                                                            value={this.state.ad2}
                                                            onChange={(e) => this.setState({ad2: e.target.value})}
                                                            disabled={this.props.readOnly}
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="row">
                                                        <h2>Coordonnées GPS</h2>

                                                        <div className="col-md-6">
                                                            <label>Latitude</label>
                                                            <input
                                                            type="number"
                                                            required
                                                            value={this.state.lat}
                                                            onChange={(e) => this.setState({lat: e.target.value})}
                                                            disabled={this.props.readOnly}
                                                            step="any"
                                                            max="90"
                                                            min="-90"
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label>Longitude</label>
                                                            <input
                                                            type="number"
                                                            required
                                                            value={this.state.lon}
                                                            onChange={(e) => this.setState({lon: e.target.value})}
                                                            disabled={this.props.readOnly}
                                                            step="any"
                                                            max="180"
                                                            min="-180"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {this.props.readOnly &&
                                                <div className="tab-pane" id="tab_3">
                                                    <table className="box tab-entete etabPDS">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div><p>Réf.</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Nom</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Prénom</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Type</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Spécialité</p></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="list etabPDS">
                                                        <tbody>
                                                        {this.props.etab.MedicalPro.map((pds, i) => {
                                                            return (
                                                                <tr key={i} className="list-row">
                                                                    <td>
                                                                        {pds.referenceMedicalPro}
                                                                    </td>
                                                                    <td>
                                                                        {pds.lastnameMedicalPro}
                                                                    </td>
                                                                    <td>
                                                                        {pds.firstnameMedicalPro}
                                                                    </td>
                                                                    <td>
                                                                        {pds.typeMedicalPro.nameTypeMedicalPro}
                                                                    </td>
                                                                    <td>
                                                                        {pds.specialityMedicalPro.map((spe, i) => {
                                                                            let endStr = (i+1) !== pds.specialityMedicalPro.length ? ", " : "";
                                                                            return spe.nameSpecialityMedicalPro + endStr;
                                                                        })}
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {this.props.readOnly && !this.props.validating &&
                                                <div className="tab-pane" id="tab_4">
                                                    <table className="box tab-entete etabHisto">
                                                        <tbody>
                                                            <tr>
                                                                <td className="first">
                                                                    <div><p>Action</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Par</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Date</p></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="list etabHisto">
                                                        <tbody>
                                                        {this.state.histo.map((action, i) => {
                                                            return (
                                                                <tr key={i} className="list-row">
                                                                    <td className="first">
                                                                        {action.action}
                                                                    </td>
                                                                    <td>
                                                                        {action.par !== null && action.par.fullname}
                                                                    </td>
                                                                    <td>
                                                                        <Moment locale="fr" fromNow>{new Date(action.date)}</Moment>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="modal-footer">
                                {!this.props.readOnly &&
                                    <button
                                    type="submit"
                                    form="formAjout"
                                    className="btn-save"
                                    data-toggle={ Object.keys(this.props.etab).length > 0 ? 'modal' : '' }
                                    data-target={'#modalConfirmation'}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if(Object.keys(this.props.etab).length > 0) this.props.handleSubmit(this.handleSubmit);
                                        else this.handleSubmit(e);
                                    }}
                                    disabled={!(
                                        (this.state.name !== "") && (this.state.idTypeEtab !== -1) && (this.state.zoneID !== -1) &&
                                        (
                                            this.state.quartier.id !== null
                                            || (this.listeQuartier().length === 0 && this.state.commune.id !== null)
                                            || (this.listeCommune().length === 0 && this.state.ville.id !== null)
                                            || (this.listeVille().length === 0 && this.state.province.id !== null)
                                            || (Object.keys(this.state.zoneGeo).length === 0 && this.state.country.id !== null)
                                        )
                                    )}
                                    >
                                        <img src="/dist/img/icons/icon-save.png" alt=""/>
                                        Sauvegarder
                                    </button>
                                }
                                {(this.props.validating && !this.props.etab.isEdit) &&
                                    <button
                                    type="button"
                                    className="btn-delete"
                                    /*data-toggle="modal"
                                    data-target="#modalDelete"*/
                                    data-dismiss="modal"
                                    onClick={() => {
                                        this.btnElement.click()
                                        this.props.disableEtab(this.props.etab.id, this.props.etab.isEdit)
                                    }}>
                                        <img src="/dist/img/icons/icon-times-red.png" alt=""/>
                                        Supprimer
                                    </button>
                                }
                                {this.props.validating && this.props.etab.isEdit &&
                                    <button
                                    type="button"
                                    className="btn-export"
                                    data-dismiss="modal"
                                    onClick={() => this.props.disableEtab(this.props.etab.id, this.props.etab.isEdit)}>
                                        <img src="/dist/img/icons/icon-historique.png" alt="" style={{width: 14, height: 14}}/>
                                        Revenir à la version précédente
                                    </button>
                                }
                                {this.props.validating &&
                                    <button
                                    type="button"
                                    className="btn-valid"
                                    onClick={() => this.props.enableEditEtab(this.props.etab.id, this.props.etab.isEdit)}>
                                        <img src="/dist/img/icons/icon-valid-green.png" alt=""/>
                                        Valider puis modifier
                                    </button>
                                }
                                {this.props.validating &&
                                    <button
                                    type="button"
                                    className="btn-valid"
                                    data-dismiss="modal"
                                    onClick={() => this.props.enableEtab(this.props.etab.id, this.props.etab.isEdit)}>
                                        <img src="/dist/img/icons/icon-valid-green.png" alt=""/>
                                        Valider
                                    </button>
                                }
                                <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                ref={button => this.btnElement = button}
                                >
                                    {this.props.readOnly && !this.props.validating ? "OK" : "Annuler"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <PopupChooseZoneGeo
                submitZone={(country, province, ville, commune, zoneID) => this.handleZoneSearch(country, province, ville, commune, zoneID)}
                id="2"
                />
            
            </div>
        )
    }
}

function mapState(state) {
    const { loadingHistoEtab, histo } = state.etab;
    return { loadingHistoEtab, histo };
}
const actionCreators = {
    getHistoEtab: etabActions.getHistoEtab,
};

const PopupAddEditEtab = connect(mapState, actionCreators)(PopupAddEditEtabPage);

export default PopupAddEditEtab;