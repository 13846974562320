import React from 'react';
import CustomSelect from './CustomSelect';
import { connect } from 'react-redux';
import { zoneGeoActions } from '../../actions/zoneGeo.actions';

class PopupChooseZoneGeoPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            country: {
                id: -1,
                name: "Sélectionnez un pays"
            },
            countryList: [],
            zoneGeo: {},
            province: {
                name: "Sélectionnez une province"
            },
            ville: {
                name: "Sélectionnez une ville"
            },
            commune: {
                name: "Sélectionnez une commune"
            },
            zoneID: -1
        }
    }

    componentDidMount() {
        this.props.getAllCountries();
    }

    componentDidUpdate(prevProps) {
        if((this.props.loading !== prevProps.loading) && !this.props.loading) {
            if(this.props.countries !== undefined) this.setState({ countryList: JSON.parse(this.props.countries) });
        }

        if((this.props.loadingZoneGeo !== prevProps.loadingZoneGeo) && !this.props.loadingZoneGeo) {
            if(this.props.zoneGeo !== undefined) this.setState({ zoneGeo: JSON.parse(this.props.zoneGeo) });
        }
    }

    handleCountryChange = (id, name) => {
        this.props.getByCountry(name);
        this.setState({
            ...this.state,
            zoneID: id,
            country: {
                id: id,
                name: name
            },
            province: {
                id: null,
                name: "Sélectionnez une province",
                label: null
            }
        })
    }

    listeVille = () => {
        let table = [];

        Object.keys(this.state.zoneGeo[this.state.province.label]).map((item, i) => {
            if((item !== "name") && (item !== "id")) {
                table.push({
                    id: this.state.zoneGeo[this.state.province.label][item].id,
                    name: this.state.zoneGeo[this.state.province.label][item].name,
                    label: item
                });
            }
        });

        return table;
    }

    listeCommune = () => {
        let table = [];

        Object.keys(this.state.zoneGeo[this.state.province.label][this.state.ville.label]).map((item, i) => {
            if((item !== "name") && (item !== "id")) {
                table.push({
                    id: this.state.zoneGeo[this.state.province.label][this.state.ville.label][item].id,
                    name: this.state.zoneGeo[this.state.province.label][this.state.ville.label][item].name,
                    label: item
                });
            }
        });

        return table;
    }

    handleSubmit = (e) => {
        if((this.state.country.id !== -1)) {
            e.preventDefault();

            this.props.submitZone(
                this.state.country.name,
                this.state.province.id ? this.state.province.name : null,
                this.state.ville.id ? this.state.ville.name : null,
                this.state.commune.id ? this.state.commune.name : null,
                this.state.zoneID
            );
            
            this.btnElement.click();
        }
    }

    render() {
        return(
            <div className="modal fade in" id={`modalChooseZoneGeo${this.props.id ? this.props.id : ""}`} tabIndex="-1" role="dialog" aria-labelledby="modalChooseZoneGeoLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">Sélection d'une zone géographique</h1>
                        </div>
                        <div className="modal-body">
                            <form id="formAjout">

                                <label>Zone géographique de départ</label>
                                <CustomSelect
                                defaultText={this.state.country.name}
                                optionsList={this.state.countryList}
                                handleOptionClick={(id, name) => this.handleCountryChange(id, name)}
                                />

                                {this.state.country.id !== -1 &&
                                    <div>
                                        <label>Province</label>
                                        <CustomSelect
                                        defaultText={this.state.province.name}
                                        optionsList={Object.keys(this.state.zoneGeo).map((item, i) => ({
                                            id: this.state.zoneGeo[item].id,
                                            name: this.state.zoneGeo[item].name,
                                            label: item
                                        }))}
                                        handleOptionClick={(id, name, label) => this.setState({
                                            ...this.state,
                                            zoneID: id,
                                            province: {
                                                id: id,
                                                name: name,
                                                label: label
                                            },
                                            ville: {
                                                id: null,
                                                name: "Sélectionnez une ville",
                                                label: null
                                            },
                                        })}
                                        />
                                    </div>
                                }

                                {this.state.province.id &&
                                    <div>
                                        <label>Ville</label>
                                        <CustomSelect
                                        defaultText={this.state.ville.name}
                                        optionsList={this.listeVille()}
                                        handleOptionClick={(id, name, label) => this.setState({
                                            ...this.state,
                                            zoneID: id,
                                            ville: {
                                                id: id,
                                                name: name,
                                                label: label
                                            },
                                            commune: {
                                                id: null,
                                                name: "Sélectionnez une commune",
                                                label: null
                                            },
                                        })}
                                        />
                                    </div>
                                }

                                {this.state.ville.id &&
                                    <div>
                                        <label>Commune</label>
                                        <CustomSelect
                                        defaultText={this.state.commune.name}
                                        optionsList={this.listeCommune()}
                                        handleOptionClick={(id, name, label) => this.setState({
                                            ...this.state,
                                            zoneID: id,
                                            commune: {
                                                id: id,
                                                name: name,
                                                label: label
                                            },
                                        })}
                                        />
                                    </div>
                                }
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" form="formAjout" className="btn-save" onClick={this.handleSubmit}>
                                <img src="/dist/img/icons/icon-save.png" alt=""/>
                                Sauvegarder
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            onClick={() => this.setState({
                                ...this.state,
                                country: {
                                    id: -1,
                                    name: "Sélectionnez un pays"
                                },
                                province: {
                                    name: "Sélectionnez une province"
                                },
                                ville: {
                                    name: "Sélectionnez une ville"
                                },
                                commune: {
                                    name: "Sélectionnez une commune"
                                }
                            })}
                            ref={button => this.btnElement = button}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { loading, countries, loadingZoneGeo, zoneGeo } = state.zoneGeo;
    return { loading, countries, loadingZoneGeo, zoneGeo };
}
const actionCreators = {
    getAllCountries: zoneGeoActions.getAllCountries,
    getByCountry: zoneGeoActions.getByCountry
};

const PopupChooseZoneGeo = connect(mapState, actionCreators)(PopupChooseZoneGeoPage);

export default PopupChooseZoneGeo;