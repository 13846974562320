import React from 'react';
import { connect } from 'react-redux';
import { etabActions } from '../actions/etab.actions';
import PopupCoord from '../components/shared/PopupCoord';
import Pagination from '../components/Pagination';

class ReleveCoordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            etabs: [],
            limite: 25,
            lat: 0,
            lon: 0,
            modal: false,
            popupText: {},
            page: 1,
            maxPage: 1,
            maxPerPage: 100,
            releveReady: false
        }
    }

    componentDidMount() {
        if(this.props.idCountry !== undefined) {
            this.props.getEtabReleve(this.props.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if((this.props.idCountry !== prevProps.idCountry) && (this.props.idCountry !== undefined)) {
            this.props.getEtabReleve(this.props.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage
            });
        }

        if((this.props.loadingReleve !== prevProps.loadingReleve) && !this.props.loadingReleve) {
            if(this.props.etabsReleve !== undefined) {
                if(JSON.parse(this.props.etabsReleve).listInstitutionReleve){
                    this.setState({
                        etabs: JSON.parse(this.props.etabsReleve).listInstitutionReleve,
                        releveReady: true,
                        maxPage: JSON.parse(this.props.etabsReleve).nbrPages,
                    });
                } else {
                    this.setState({
                        etabs: [],
                        releveReady: true
                    });
                }
            }
        }

        if(this.state.page !== prevState.page){
            this.props.getEtabReleve(this.props.idCountry, {
                page: this.state.page,
                maxPerPage : this.state.maxPerPage
            });
        }
    }

    selectValidCoord = () => {
        let etabs = [];

        for(let i=0; i<this.state.etabs.length; i++) {
            if((this.state.etabs[i].CoordLat !== "") && (this.state.etabs[i].CoordLong !== "")) {
                etabs.push(this.state.etabs[i])
            }
        }

        return etabs;
    }

    onChangePage = (data) => this.setState({ page: data });

    render() {
        const { releveReady, maxPage } = this.state;

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Établissements
                        <small>Vérification des photos et coordonnées</small>
                    </h1>
                </section>
            
                <section className="content coord">

                    <table className="box tab-entete coord">
                        <tbody>
                            <tr>
                                <td className="ref"><div><p>Réf.</p></div></td>
                                <td className="nom"><div><p>Nom</p></div></td>
                                <td className="type"><div><p>Type</p></div></td>
                                <td className="loc"><div><p>Localisation</p></div></td>
                                <td className="lat"><div><p>Latitude</p></div></td>
                                <td className="lon"><div><p>Longitude</p></div></td>
                                <td className="photo"><div><p>Photos</p></div></td>
                                <td className="modif"><div><p>Modifié...</p></div></td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list coord">
                        <tbody>
                        {this.state.etabs.map((etab, i) => {
                            if(i <= this.state.limite) {
                                return (
                                    <tr key={i} className="list-row">
                                        <td className="ref">{etab.reference}</td>
                                        <td className="nom">{etab.name}</td>
                                        <td className="type">{etab.InstitutionType.nameInstitutionType}</td>
                                        <td className="loc">{etab.zonesGeo.addresszoneGeo}</td>
                                        <td className="lat planning">
                                            <div
                                            className={`tag ${etab.CoordLat !== "" ? "valid" : "alert"}`}
                                            data-toggle={etab.CoordLat !== "" ? "modal" : ""}
                                            data-target={etab.CoordLat !== "" ? "#modalCoord" : ""}
                                            onClick={() => {
                                                if(etab.lat !== "") {
                                                    this.setState({
                                                        ...this.state,
                                                        modal: true,
                                                        lat: etab.CoordLat,
                                                        lon: etab.CoordLong,
                                                        popupText: {
                                                            ref: etab.reference,
                                                            name: etab.name
                                                        }
                                                    });
                                                }
                                            }}
                                            >
                                                <p>{etab.CoordLat !== "" ? etab.CoordLat : "Non"}</p>
                                            </div>
                                        </td>
                                        <td className="lon planning">
                                            <div
                                            className={`tag ${etab.CoordLong !== "" ? "valid" : "alert"}`}
                                            data-toggle={etab.CoordLong !== "" ? "modal" : ""}
                                            data-target={etab.CoordLong !== "" ? "#modalCoord" : ""}
                                            onClick={() => {
                                                if(etab.lon !== "") {
                                                    this.setState({
                                                        ...this.state,
                                                        modal: true,
                                                        lat: etab.CoordLat,
                                                        lon: etab.CoordLong,
                                                        popupText: {
                                                            ref: etab.reference,
                                                            name: etab.name
                                                        }
                                                    });
                                                }
                                            }}
                                            >
                                                <p>{etab.CoordLong !== "" ? etab.CoordLong : "Non"}</p>
                                            </div>
                                        </td>
                                        <td className="photo planning">
                                            <div className={`tag ${etab.Pictures.length !== 0 ? "valid" : "alert"}`}>
                                                <p>{etab.Pictures.length}</p>
                                            </div>
                                        </td>
                                        <td className="modif">{etab.modif}</td>
                                    </tr>
                                )
                            }
                        })}
                        </tbody>
                    </table>

                    {releveReady && maxPage > 1  && 
                    <Pagination
                        maxPage={this.state.maxPage}
                        page={this.state.page}
                        onChangePage={this.onChangePage}
                    ></Pagination>
                    }

                </section>

                <PopupCoord
                coord={{lat: this.state.lat, lon: this.state.lon}}
                popupText={this.state.popupText}
                open={this.state.modal}
                closeModal={() => this.setState({ modal: false })}
                etabs={this.selectValidCoord()}
                />

            </section>
        )
    }
}

function mapState(state) {
    const { idCountry } = state.zoneGeo;
    const { loadingReleve, etabsReleve } = state.etab;
    return { idCountry, loadingReleve, etabsReleve };
}
const actionCreators = {
    getEtabReleve: etabActions.getEtabReleve
};

const ReleveCoord = connect(mapState, actionCreators)(ReleveCoordPage);

export default ReleveCoord;