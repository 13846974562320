import { usersConstants } from '../constants/users.constants';

export function users(state = {}, action) {
  switch (action.type) {
    case usersConstants.GET_ALL_USERS_REQUEST:
      return {
        loadingUsers: true,
        users: {}
      };
    case usersConstants.GET_ALL_USERS_SUCCESS:
      return {
        users: action.listUsers,
        loadingUsers: false
      };
    case usersConstants.GET_ALL_USERS_FAILURE:
      return { 
        error: action.error,
        loadingUsers: false
      };
    case usersConstants.POST_USER_REQUEST:
      return {
        userPosted: false
      };
    case usersConstants.POST_USER_SUCCESS:
      return {
        userPosted: true,
        userPostedData: action.posted
      };
    case usersConstants.POST_USER_FAILURE:
      return { 
        error: action.error,
        userPosted: false
      };
    case usersConstants.EDIT_USER_REQUEST:
      return {
        userEdited: false
      };
    case usersConstants.EDIT_USER_SUCCESS:
      return {
        userEdited: true,
        userEditedData: action.edited
      };
    case usersConstants.EDIT_USER_FAILURE:
      return { 
        error: action.error,
        userEdited: false
      };
    case usersConstants.DELETE_USER_REQUEST:
      return {
        userDeleted: false
      };
    case usersConstants.DELETE_USER_SUCCESS:
      return {
        userDeleted: true
      };
    case usersConstants.DELETE_USER_FAILURE:
      return { 
        error: action.error,
        userDeleted: false
      };
    case usersConstants.DISABLE_USER_REQUEST:
      return {
        userDisabled: false
      };
    case usersConstants.DISABLE_USER_SUCCESS:
      return {
        userDisabled: true
      };
    case usersConstants.DISABLE_USER_FAILURE:
      return { 
        error: action.error,
        userDisabled: false
      };
    default:
      return state
  }
}