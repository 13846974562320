import React from 'react';

class PopupAddEditPDS extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            code: ""
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.pds !== prevProps.pds) {
            const edit = Object.keys(this.props.pds).length > 0;
            this.setState({
                ...this.state,
                name: edit ? this.props.pds.name : "",
                code: edit ? this.props.pds.code : ""
            })
        }
    }

    handleSubmit = (e) => {
        if((this.state.name !== "") && (this.state.code !== "")) {
            e.preventDefault();

            if(Object.keys(this.props.pds).length > 0) {
                this.props.editPDS({
                    id: this.props.pds.id,
                    name: this.state.name,
                    code: this.state.code,
                });
            } else this.props.addPDS({
                name: this.state.name,
                code: this.state.code,
            });
            
            this.btnElement.click();
        }
    }

    render() {
        const edit = Object.keys(this.props.pds).length > 0;

        return(
            <div>
                <div className="modal fade in" id="modalAddEditPDS" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditPDSLabel" animate="animate">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un type de PDS</h1>
                            </div>
                            <div className="modal-body">
                                <form id="formAjout">

                                    <label>Code</label>
                                    <input
                                    type="text"
                                    required 
                                    value={this.state.code} 
                                    onChange={(e) => this.setState({code: e.target.value})}
                                    />

                                    <label>Nom</label>
                                    <input 
                                    type="text" 
                                    required 
                                    value={this.state.name} 
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    />

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                type="submit"
                                form="formAjout"
                                className="btn-save"
                                disabled={false}
                                data-toggle={ Object.keys(this.props.pds).length > 0 ? 'modal' : '' }
                                data-target={'#modalConfirmation'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (Object.keys(this.props.pds).length > 0) this.props.handleSubmit(this.handleSubmit);
                                    else this.handleSubmit(e);
                                }}>
                                    <img src="/dist/img/icons/icon-save.png" alt=""/>
                                    Sauvegarder
                                </button>
                                <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                ref={button => this.btnElement = button}
                                >Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        )
    }
}

export default PopupAddEditPDS;