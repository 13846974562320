import { config } from '../constants/app.constants';
import { authHeader } from '../helpers/auth-header';
import axios from 'axios';

export const historyService = {
    getHistory,
};

function getHistory(data) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.request({
                    method: 'GET',
                    baseURL: config.baseUrl,
                    url: 'api/history',
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    },
                    params: {
                        page: data.page,
                        maxPerPage: data.maxPerPage
                    }
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}