export const logicielConstants = {   
    LOGICIEL_REQUEST: 'LOGICIEL_GETALL_REQUEST',
    LOGICIEL_SUCCESS: 'LOGICIEL_GETALL_SUCCESS',
    LOGICIEL_FAILURE: 'LOGICIEL_GETALL_FAILURE',

    POST_LOGICIEL_REQUEST: 'POST_LOGICIEL_REQUEST',
    POST_LOGICIEL_SUCCESS: 'POST_LOGICIEL_SUCCESS',
    POST_LOGICIEL_FAILURE: 'POST_LOGICIEL_FAILURE',

    EDIT_LOGICIEL_REQUEST: 'EDIT_LOGICIEL_REQUEST',
    EDIT_LOGICIEL_SUCCESS: 'EDIT_LOGICIEL_SUCCESS',
    EDIT_LOGICIEL_FAILURE: 'EDIT_LOGICIEL_FAILURE',

    DELETE_LOGICIEL_REQUEST: 'DELETE_LOGICIEL_REQUEST',
    DELETE_LOGICIEL_SUCCESS: 'DELETE_LOGICIEL_SUCCESS',
    DELETE_LOGICIEL_FAILURE: 'DELETE_LOGICIEL_FAILURE',
};