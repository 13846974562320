import { userConstants } from '../constants/user.constants';
const initialState = { loggedIn: false,loggingIn: false};

export function authentification(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return  Object.assign({}, state, {
        loggingIn: true,
        loggedIn: false,
        user: action.user
      });
    case userConstants.LOGIN_SUCCESS:
      return  Object.assign({}, state, {
        loggedIn: true,
        loggingIn: false,
        user: action.user
      });
    case userConstants.LOGIN_FAILURE:
      return  Object.assign({}, state, {
        loggedIn: false,
        logout: false,
        errorLog: true,
        loggingIn: false,
      });
    case userConstants.GET_USER_CONNECTED_REQUEST:
      return  Object.assign({}, state, {
        loadingRoleUser: true,
        roleUser: {},
        userData: {}
      });
    case userConstants.GET_USER_CONNECTED_SUCCESS:
      return  Object.assign({}, state, {
        loadingRoleUser: false,
        roleUser: action.userConnected.roles[0],
        userData: action.userConnected
      });
    case userConstants.GET_USER_CONNECTED_FAILURE:
      return  Object.assign({}, state, {
        error: true,
        loadingRoleUser: false,
      });
    case userConstants.GET_COUNTRIES_USER_REQUEST:
      return  Object.assign({}, state, {
        loadingCountriesUser: true,
        countriesUser: {}
      });
    case userConstants.GET_COUNTRIES_USER_SUCCESS:
      return  Object.assign({}, state, {
        loadingCountriesUser: false,
        countriesUser: action.countriesUser
      });
    case userConstants.GET_COUNTRIES_USER_FAILURE:
      return  Object.assign({}, state, {
        error: true,
        loadingCountriesUser: false,
      });
    case userConstants.LOGOUT:
      return {  
        loggedIn: false
      };
    default:
      return state
  }
}