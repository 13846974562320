import { dashboardConstants } from '../constants/dashboard.constants';

export function dashboard(state = {}, action) {
  switch (action.type) {
    case dashboardConstants.GET_DETAILS_PDS_REQUEST:
      return {
        loadingDetailsPDS: true,
        detailsPDS: {}
      };
    case dashboardConstants.GET_DETAILS_PDS_SUCCESS:
      return {
        detailsPDS: action.detailsPDS,
        loadingDetailsPDS: false
      };
    case dashboardConstants.GET_DETAILS_PDS_FAILURE:
      return { 
        error: action.error,
        loadingDetailsPDS: false
      };
    case dashboardConstants.GET_DETAILS_ETAB_REQUEST:
      return {
        loadingDetailsInstitution: true,
        detailsInstitution: {}
      };
    case dashboardConstants.GET_DETAILS_ETAB_SUCCESS:
      return {
        detailsInstitution: action.detailsInstitution,
        loadingDetailsInstitution: false
      };
    case dashboardConstants.GET_DETAILS_ETAB_FAILURE:
      return { 
        error: action.error,
        loadingDetailsInstitution: false
      };
    case dashboardConstants.GET_DETAILS_COUNTRY_REQUEST:
      return {
        loadingDetailsCountry: true,
        detailsCountry: {}
      };
    case dashboardConstants.GET_DETAILS_COUNTRY_SUCCESS:
      return {
        detailsCountry: action.detailsCountry,
        loadingDetailsCountry: false
      };
    case dashboardConstants.GET_DETAILS_COUNTRY_FAILURE:
      return { 
        error: action.error,
        loadingDetailsCountry: false
      };
    default:
      return state
  }
}