import React from 'react'
import ReactDOM from 'react-dom';
import './app-back.scss';
import App from './App';
import { Provider } from 'react-redux';
import { store } from './helpers/store';
import * as serviceWorker from './serviceWorker';
//import * as Sentry from '@sentry/react';
import 'moment/locale/fr';

//Sentry.init({dsn: "https://1c429d9ae5e64d23a67f26f85ffe1292@o341294.ingest.sentry.io/5372442"});

ReactDOM.render( 
    <Provider store={ store }>
        <App/>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
