import { dashboardConstants } from '../constants/dashboard.constants';
import { dashboardService } from '../services/dashboard.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const dashboardActions = {
    getDashPDS,
    getDashEtab,
    getDashCountry
};

function getDashPDS() {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        dashboardService.getDashPDS()
            .then(
                detailsPDS => {
                    dispatch(success(detailsPDS));
                    dispatch(hideLoading());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: dashboardConstants.GET_DETAILS_PDS_REQUEST } }
    function success(detailsPDS) { return { type: dashboardConstants.GET_DETAILS_PDS_SUCCESS, detailsPDS } }
    function failure(error) { return { type: dashboardConstants.GET_DETAILS_PDS_FAILURE, error } }
}

function getDashEtab() {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        dashboardService.getDashEtab()
            .then(
                detailsInstitution => {
                    dispatch(success(detailsInstitution));  
                    dispatch(hideLoading());              
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: dashboardConstants.GET_DETAILS_ETAB_REQUEST } }
    function success(detailsInstitution) { return { type: dashboardConstants.GET_DETAILS_ETAB_SUCCESS, detailsInstitution } }
    function failure(error) { return { type: dashboardConstants.GET_DETAILS_ETAB_FAILURE, error } }
}

function getDashCountry() {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        dashboardService.getDashCountry()
            .then(
                detailsCountry => {
                    dispatch(success(detailsCountry));  
                    dispatch(hideLoading());              
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: dashboardConstants.GET_DETAILS_COUNTRY_REQUEST } }
    function success(detailsCountry) { return { type: dashboardConstants.GET_DETAILS_COUNTRY_SUCCESS, detailsCountry } }
    function failure(error) { return { type: dashboardConstants.GET_DETAILS_COUNTRY_FAILURE, error } }
}