import { specialiteConstants } from '../constants/specialite.constants';

export function specialite(state = {}, action) {
  switch (action.type) {
    case specialiteConstants.SPECIALITE_REQUEST:
      return  Object.assign({}, state, {
        loadingSpecialite: true,
        loadSpecialite: false,
      });
    case specialiteConstants.SPECIALITE_SUCCESS:
      return  Object.assign({}, state, {
        loadingSpecialite: false,
        loadSpecialite: true,
        specialite: action.specialite
      });
    case specialiteConstants.SPECIALITE_FAILURE:
      return  Object.assign({}, state, {          
        error: action.error
      });
    case specialiteConstants.POST_SPECIALITE_REQUEST:
      return  Object.assign({}, state, {
        spePosted: false
      });
    case specialiteConstants.POST_SPECIALITE_SUCCESS:
      return  Object.assign({}, state, {
        spePosted: true
      });
    case specialiteConstants.POST_SPECIALITE_FAILURE:
      return  Object.assign({}, state, {
        spePosted: false,         
        error: action.error
      });
    case specialiteConstants.EDIT_SPECIALITE_REQUEST:
      return  Object.assign({}, state, {
        speEdited: false
      });
    case specialiteConstants.EDIT_SPECIALITE_SUCCESS:
      return  Object.assign({}, state, {
        speEdited: true
      });
    case specialiteConstants.EDIT_SPECIALITE_FAILURE:
      return  Object.assign({}, state, {
        speEdited: false,         
        error: action.error
      });
    case specialiteConstants.DELETE_SPECIALITE_REQUEST:
      return  Object.assign({}, state, {
        speDeleted: false
      });
    case specialiteConstants.DELETE_SPECIALITE_SUCCESS:
      return  Object.assign({}, state, {
        speDeleted: true
      });
    case specialiteConstants.DELETE_SPECIALITE_FAILURE:
      return  Object.assign({}, state, {
        speDeleted: false,         
        error: action.error
      });
    default:
      return state
  }
}