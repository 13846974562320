import { usersConstants } from '../constants/users.constants';
import { usersService } from '../services/users.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const usersActions = {
    getAllUsers,
    disableUser,
    postUser,
    deleteUser,
    editUser
};

function getAllUsers(data) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        usersService.getAllUsers(data)
            .then(
                listUsers => {
                    dispatch(success(listUsers));   
                    dispatch(hideLoading());             
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: usersConstants.GET_ALL_USERS_REQUEST } }
    function success(listUsers) { return { type: usersConstants.GET_ALL_USERS_SUCCESS, listUsers } }
    function failure(error) { return { type: usersConstants.GET_ALL_USERS_FAILURE, error } }
}

function postUser(user) {
    return (dispatch) => {

        dispatch(request());
        
        usersService.postUser(user)
            .then(
                posted => {
                    dispatch(success(posted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: usersConstants.POST_USER_REQUEST } }
    function success(posted) { return { type: usersConstants.POST_USER_SUCCESS, posted } }
    function failure(error) { return { type: usersConstants.POST_USER_FAILURE, error } }
}

function editUser(user) {
    return (dispatch) => {

        dispatch(request());
        
        usersService.editUser(user)
            .then(
                edited => {
                    dispatch(success(edited));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: usersConstants.EDIT_USER_REQUEST } }
    function success(edited) { return { type: usersConstants.EDIT_USER_SUCCESS, edited } }
    function failure(error) { return { type: usersConstants.EDIT_USER_FAILURE, error } }
}

function deleteUser(id) {
    return (dispatch) => {

        dispatch(request());
        
        usersService.deleteUser(id)
            .then(
                deleted => {
                    dispatch(success(deleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: usersConstants.DELETE_USER_REQUEST } }
    function success(deleted) { return { type: usersConstants.DELETE_USER_SUCCESS, deleted } }
    function failure(error) { return { type: usersConstants.DELETE_USER_FAILURE, error } }
}

function disableUser(id) {
    return (dispatch) => {

        dispatch(request());
        
        usersService.disableUser(id)
            .then(
                disabled => {
                    dispatch(success(disabled));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: usersConstants.DISABLE_USER_REQUEST } }
    function success(disabled) { return { type: usersConstants.DISABLE_USER_SUCCESS, disabled } }
    function failure(error) { return { type: usersConstants.DISABLE_USER_FAILURE, error } }
}