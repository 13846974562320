import { typeEtabConstants } from '../constants/typeEtab.constants';

export function typeEtab(state = {}, action) {
  switch (action.type) {
    case typeEtabConstants.TYPE_ETAB_REQUEST:
      return  Object.assign({}, state, {
        loadingTypeEtab: true
      });
    case typeEtabConstants.TYPE_ETAB_SUCCESS:
      return  Object.assign({}, state, {
        loadingTypeEtab: false,
        typesEtab: action.typesEtab
      });
    case typeEtabConstants.TYPE_ETAB_FAILURE:
      return  Object.assign({}, state, {          
        error: action.error
      });
    case typeEtabConstants.POST_TYPE_ETAB_REQUEST:
      return  Object.assign({}, state, {
        typePosted: false,
      });
    case typeEtabConstants.POST_TYPE_ETAB_SUCCESS:
      return  Object.assign({}, state, {
        typePosted: true,
      });
    case typeEtabConstants.POST_TYPE_ETAB_FAILURE:
      return  Object.assign({}, state, { 
        typePosted: false,         
        error: action.error
      });
    case typeEtabConstants.EDIT_TYPE_ETAB_REQUEST:
      return  Object.assign({}, state, {
        typeEdited: false,
      });
    case typeEtabConstants.EDIT_TYPE_ETAB_SUCCESS:
      return  Object.assign({}, state, {
        typeEdited: true,
      });
    case typeEtabConstants.EDIT_TYPE_ETAB_FAILURE:
      return  Object.assign({}, state, { 
        typeEdited: false,         
        error: action.error
      });
    case typeEtabConstants.DELETE_TYPE_ETAB_REQUEST:
      return  Object.assign({}, state, {
        typeDeleted: false,
      });
    case typeEtabConstants.DELETE_TYPE_ETAB_SUCCESS:
      return  Object.assign({}, state, {
        typeDeleted: true,
      });
    case typeEtabConstants.DELETE_TYPE_ETAB_FAILURE:
      return  Object.assign({}, state, { 
        typeDeleted: false,         
        error: action.error
      });
    default:
      return state
  }
}