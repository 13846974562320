import React from 'react';
import { connect } from 'react-redux';
import { zoneGeoActions } from '../actions/zoneGeo.actions';
import { holidayActions } from '../actions/holiday.actions';
import ButtonAdd from '../components/shared/ButtonAdd';
import PopupAddJourFerie from '../components/shared/PopupAddJourFerie';
import PopupDelete from '../components/shared/PopupDelete';
import { NotificationManager} from 'react-notifications';

class JoursFeriesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            countryList: [],
            jours: [],
            idDelete: -1,
            limite: 25
        }
    }

    componentDidMount() {
        if(this.props.idCountry !== undefined) this.props.getHolidays(this.props.idCountry);
        this.props.getAllCountries();
    }

    componentDidUpdate(prevProps) {
        if((this.props.loading !== prevProps.loading) && !this.props.loading) {
            if(this.props.countries !== undefined) this.setState({ countryList: JSON.parse(this.props.countries) });
        }

        if((this.props.loadingHolidays !== prevProps.loadingHolidays) && !this.props.loadingHolidays) {
            if((this.props.holidays !== undefined) && (this.props.holidays !== "null")){
                this.setState({ jours: JSON.parse(this.props.holidays) });
            }
            if(this.props.holidays === "null") this.setState({ jours: [] });
        }

        if((this.props.idCountry !== prevProps.idCountry) && (this.props.idCountry !== undefined)) {
            this.props.getHolidays(this.props.idCountry);
        }

        if((this.props.holidayDeleted !== prevProps.holidayDeleted) && this.props.holidayDeleted) {
            this.props.getHolidays(this.props.idCountry);          
            NotificationManager.success('', "Jour férié supprimé");
        }

        if((this.props.holidayPosted !== prevProps.holidayPosted) && this.props.holidayPosted) {
            this.props.getHolidays(this.props.idCountry);          
            NotificationManager.success('', "Jour férié ajouté");
        }
    }

    render() {
        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Jours fériés - {this.props.country}
                        <small>Gestion des jours fériés</small>
                    </h1>
                </section>
            
                <section className="content joursFeries">

                    <table className="box tab-entete joursFeries">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>Date</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>Description</p>
                                    </div>
                                </td>
                                <td>
                                    <ButtonAdd modal="#modalAddJourFerie" handleAdd={() => {}}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list joursFeries">
                        <tbody>
                        {this.state.jours.map((jour, i) => {
                            if(i <= this.state.limite) {
                                return (
                                    <tr key={i} className="list-row">
                                        <td>
                                            {new Date(jour.date).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' })}
                                        </td>
                                        <td>
                                            {jour.name}
                                        </td>
                                        <td className="icons">
                                            <div
                                            className="icon-btn"
                                            data-toggle="modal"
                                            data-target="#modalDelete"
                                            onClick={() => this.setState({idDelete: jour.id})}>
                                                <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        })}
                        {this.state.jours.length === 0 && !this.props.loadingHolidays &&
                        <tr>
                            <td><p>Aucun jour férié</p></td>
                        </tr>
                        }
                        </tbody>
                    </table>

                </section>

                <PopupAddJourFerie
                countryList={this.state.countryList}
                country={{id: this.props.idCountry, name: this.props.country}}
                addJourFerie={(idCountry, date, desc) => this.props.postHoliday(idCountry, date, desc)}
                />

                <PopupDelete
                type="jour férié"
                deleteHoliday={(id) => this.props.deleteHoliday(id)}
                id={this.state.idDelete}
                />

            </section>
        )
    }
}

function mapState(state) {
    const { loading, countries, country, idCountry } = state.zoneGeo;
    const { loadingHolidays, holidays, holidayDeleted, holidayPosted } = state.holiday;
    return { loading, countries, country, idCountry, loadingHolidays, holidays, holidayDeleted, holidayPosted };
}
const actionCreators = {
    getAllCountries: zoneGeoActions.getAllCountries,
    getHolidays: holidayActions.getHolidays,
    postHoliday: holidayActions.postHoliday,
    deleteHoliday: holidayActions.deleteHoliday,
};

const JoursFeries = connect(mapState, actionCreators)(JoursFeriesPage);

export default JoursFeries;