import React from 'react';
import { connect } from 'react-redux';
import { etabActions } from '../../actions/etab.actions';
import { medicalProActions } from '../../actions/medicalPro.actions';
import CustomSelect from './CustomSelect';
import Select from 'react-select';
import ButtonAdd from './ButtonAdd';
import PopupAddEditProEtab from './PopupAddEditProEtab';
import Moment from 'react-moment';
import { NotificationManager} from 'react-notifications';
import ReactLoading from 'react-loading';
import Pagination from '../Pagination';

class PopupAddEditProPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            country: "Sélectionnez un pays",
            idCountry: -1,
            name: "",
            idPDS: -1,
            pds: "Sélectionnez un type de professionnel de santé",
            sexe: 0,
            fname: "",
            email: "",
            telP: "",
            telF: "",
            fax: "",
            informatise: 0,
            leaderOpinion: 0,
            ethicaFriend: 0,
            titre: "Docteur",
            listSpe: [],
            selectedSpe: {
                nameSpeciality: "Sélectionnez une spécialité"
            },
            etabList: [],
            firstEtab: null,
            listLog: [],
            selectedLog: {
                nameSoftware: "Sélectionnez un logiciel"
            },
            selectedProEtab: {},
            updated: false,
            histo: [],
            savedOnEthica: false,
            etabListReady: false,
            idMainSpe: -1,
            mainSpe: "Sélectionnez une spécialité",
            listSpeByType: [],
            isRetired: 0,
            isDeath: 0
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if((this.props.pro !== prevProps.pro) && !this.state.updated) {

            const edit = Object.keys(this.props.pro).length > 0;

            if(edit) {
                this.props.getSpePro(this.props.pro.Id);
                this.props.getLogPro(this.props.pro.Id);
                this.props.getHistoPDS(this.props.pro.Id);
                this.props.getSpeByType(this.props.pro.MedicalProType.idMedicalProType);
            } else this.props.getEtablissements(this.props.idCountry, {});

            this.setState({
                ...this.state,
                name: edit ? this.props.pro.LastName : "",
                idPDS: edit ? this.props.pro.MedicalProType.idMedicalProType : -1,
                sexe: edit ? this.props.pro.Gender : 0,
                fname: edit ? this.props.pro.FirstName : "",
                pds: edit ? this.props.pro.MedicalProType.nameMedicalProType : "Sélectionnez un type de professionnel de santé",
                email: edit ? this.props.pro.Mail !== null ? this.props.pro.Mail : "" : "",
                telP: edit ? this.props.pro.Mobile !== null ? this.props.pro.Mobile : "" : "",
                telF: edit ? this.props.pro.LandLine !== null ? this.props.pro.LandLine : "" : "",
                fax: edit ? this.props.pro.FaxNumber : "",
                informatise: edit ? this.props.pro.IsComputerized : 0,
                leaderOpinion: edit ? this.props.pro.IsThoughtLeader : 0,
                ethicaFriend: edit ? this.props.pro.IsEthicaFriend : 0,
                titre: edit ? this.props.pro.Title : "Docteur",
                selectedSpe: {nameSpeciality: "Sélectionnez une spécialité"},
                savedOnEthica: edit ? this.props.pro.SavedOnEthica : false,
                isRetired: edit ? this.props.pro.isRetired !== null ? this.props.pro.isRetired : 0 : 0,
                isDeath: edit ? this.props.pro.isDeath !== null ? this.props.pro.isDeath : 0 : 0,
                idMainSpe: edit ? this.props.pro.MedicalProMainSpeciality.IdMainSpeciality ? this.props.pro.MedicalProMainSpeciality.IdMainSpeciality : -1 : -1,
                mainSpe: edit ? this.props.pro.MedicalProMainSpeciality.NameMainSpeciality ? this.props.pro.MedicalProMainSpeciality.NameMainSpeciality : "Sélectionnez une spécialité" : "Sélectionnez une spécialité",
            })
        }

        if((this.props.loadingSpeByType !== prevProps.loadingSpeByType) && !this.props.loadingSpeByType) {
            if((this.props.speByType !== undefined && (this.props.speByType !== "null"))) {
                this.setState({ listSpeByType: JSON.parse(this.props.speByType) });
            }
            if(this.props.speByType === "null") this.setState({ listSpeByType: [] });
        }

        // UPDATED

        if((this.props.pro !== prevProps.pro) && this.state.updated) this.setState({ updated: false });

        // DOUBLON

        if(this.state.name !== prevState.name || this.state.fname !== prevState.fname) {
            const edit = Object.keys(this.props.pro).length > 0;

            setTimeout(() => {                
                this.props.verif({
                    name: this.state.name,
                    firstName: this.state.fname,
                    page: 1,
                    maxPerPage: 5,
                    id: this.props.pro.Id,
                    idCountry: edit ? this.props.pro.idArea : localStorage.getItem("idCountry")
                })
            }, 1500);
        }

        // ETABS

        if((this.props.loadingEtabs !== prevProps.loadingEtabs) && !this.props.loadingEtabs) {
            if(this.props.etabs !== undefined) {
                if(JSON.parse(this.props.etabs).listInstitutionByArea !== null) {
                    this.setState({
                        etabList: JSON.parse(this.props.etabs).listInstitutionByArea,
                        etabListReady: true
                    });
                } else {
                    this.setState({
                        etabList: [],
                        etabListReady: true
                    });
                }
            }
        }

        // SPE

        if((this.props.loadingSpePro !== prevProps.loadingSpePro) && !this.props.loadingSpePro) {
            if((this.props.spePro !== undefined && (this.props.spePro !== "null"))) {
                this.setState({ listSpe: JSON.parse(this.props.spePro) });
            }
            if(this.props.spePro === "null") this.setState({ listSpe: [] });
        }

        // LOGICIEL

        if((this.props.loadingLogPro !== prevProps.loadingLogPro) && !this.props.loadingLogPro) {
            if((this.props.logPro !== undefined && (this.props.logPro !== "null"))) {
                this.setState({
                    listLog: JSON.parse(this.props.logPro)
                })
            }
            if(this.props.logPro === "null") {
                this.setState({
                    listLog: []
                })
            }
        }

        // HISTO

        if((this.props.loadingHistoPro !== prevProps.loadingHistoPro) && !this.props.loadingHistoPro) {
            if((this.props.histo !== undefined && (this.props.histo !== "null"))) {
                this.setState({
                    histo: JSON.parse(this.props.histo)
                })
            }
            if(this.props.histo === "null") {
                this.setState({
                    histo: []
                })
            }
        }

        // NOTIFICATIONS

        // etab
        if((this.props.etabProPosted !== prevProps.etabProPosted) && this.props.etabProPosted) {
            this.props.updatePros();
            this.setState({ updated: true });
            NotificationManager.success('', "Établissement ajouté");
        }
        
        if((this.props.etabProEdited !== prevProps.etabProEdited) && this.props.etabProEdited) {
            this.props.updatePros();
            this.setState({ updated: true });
            NotificationManager.success('', "Établissement édité");
        }

        if((this.props.etabProDisabled !== prevProps.etabProDisabled) && this.props.etabProDisabled) {
            this.props.updatePros();
            this.setState({ updated: true });
            NotificationManager.success('', "Établissement désactivé");
        }

        if((this.props.etabProDeleted !== prevProps.etabProDeleted) && this.props.etabProDeleted) {
            this.props.updatePros();
            this.setState({ updated: true });
            NotificationManager.success('', "Établissement supprimé");
        }

        // spe
        if((this.props.speProPosted !== prevProps.speProPosted) && this.props.speProPosted) {
            this.props.getSpePro(this.props.pro.Id);
            NotificationManager.success('',"Spécialité ajouté");
        }

        if((this.props.speProDisabled !== prevProps.speProDisabled) && this.props.speProDisabled) {
            this.props.getSpePro(this.props.pro.Id);    
            NotificationManager.success('',"Spécialité désactivé");
        }
        if((this.props.speProDeleted !== prevProps.speProDeleted) && this.props.speProDeleted) {
            this.props.getSpePro(this.props.pro.Id);    
            NotificationManager.success('',"Spécialité supprimé");
        }

        // log
        if((this.props.logProPosted !== prevProps.logProPosted) && this.props.logProPosted) {
            this.props.getLogPro(this.props.pro.Id);    
            NotificationManager.success('',"Logiciel ajouté");
        }

        if((this.props.logProDisabled !== prevProps.logProDisabled) && this.props.logProDisabled) {
            this.props.getLogPro(this.props.pro.Id);    
            NotificationManager.success('',"Logiciel desactivé");
        }

        if((this.props.logProDeleted !== prevProps.logProDeleted) && this.props.logProDeleted) {
            this.props.getLogPro(this.props.pro.Id);    
            NotificationManager.success('',"Logiciel supprimé");
        }
    }

    handleSubmit = (e) => {
        if((this.state.name !== "") && (this.state.idPDS !== -1) && (this.state.fname !== "")) {
            e.preventDefault();

            if(Object.keys(this.props.pro).length > 0) {
                this.props.editPro({
                    id: this.props.pro.Id,
                    idCountry: this.props.idCountry,
                    name: this.state.name,
                    idPDS: this.state.idPDS,
                    sexe: this.state.sexe,
                    fname: this.state.fname,
                    email: this.state.email,
                    telP: this.state.telP,
                    telF: this.state.telF,
                    fax: this.state.fax,
                    informatise: this.state.informatise,
                    leaderOpinion: this.state.leaderOpinion,
                    titre: this.state.titre,
                    ethicaFriend: this.state.ethicaFriend,
                    savedOnEthica: this.state.savedOnEthica,
                    isRetired: this.state.isRetired,
                    isDeath: this.state.isDeath,
                    Speciality: this.state.idMainSpe !== -1 ? this.state.idMainSpe : null
                });
            } else {
                this.props.addPro({
                    idCountry: this.props.idCountry,
                    name: this.state.name,
                    idPDS: this.state.idPDS,
                    sexe: this.state.sexe,
                    fname: this.state.fname,
                    email: this.state.email,
                    telP: this.state.telP,
                    telF: this.state.telF,
                    fax: this.state.fax,
                    idInstitution: this.state.firstEtab.value,
                    titre: (this.state.idPDS === 7) ||  //charge cmd
                        (this.state.idPDS === 3) ||     //infirmier
                        (this.state.idPDS === 4) ||     //kine
                        (this.state.idPDS === 6) ?      //sage femme
                        "M." : "Docteur",
                    ethicaFriend: this.state.ethicaFriend,
                    informatise: this.state.informatise,
                    leaderOpinion: this.state.leaderOpinion,
                    savedOnEthica: this.state.savedOnEthica,
                    Speciality: this.state.idMainSpe !== -1 ? this.state.idMainSpe : null,
                    isRetired: this.state.isRetired,
                    isDeath: this.state.isDeath
                })
            }
            
            this.btnElement.click()
        }
    }

    generateEtabOptions = () => {
        const etabs = (this.state.etabListReady && this.state.etabList !== undefined && this.state.etabList.length > 0) ? this.state.etabList : [];
        const types = this.props.typeEtabList
        let table = [];
        types.map((t) => {
            table.push({
                label: t.name,
                options: []
            })
        })

        etabs.map((e) => {
            table[table.map((g) => g.label).indexOf(e.InstitutionType.nameInstitutionType)].options.push(
                {
                    value: e.id,
                    label: e.name,
                    ref: e.reference,
                    ad: e.zonesGeo.addresszoneGeo,
                    isPrivate: e.isPrivate
                }
            )
        });
        return table;
    }

    onChangePage = (data) => this.props.verif({
        name: this.state.name,
        firstName: this.state.fname,
        page: data,
        maxPerPage: 5,
        id: this.props.pro.Id,
        idCountry: Object.keys(this.props.pro).length > 0 ? this.props.pro.idArea : localStorage.getItem("idCountry")
    });

    renderDoublons = (dataSend) => {
        console.log(dataSend)
        let data = dataSend.data;
        let table = [];
        let elem = [];
        elem.push(
            <tr key={"tr"} style={{fontWeight: "bold"}}>
                <td>Référence</td>
                <td>Nom</td>
                <td>Prénom</td>
                <td>Type de PDS</td>
                <td>Spécialité</td>
            </tr>
        )
        let specialite = "";
        for(let i =0; i< data.length; i++){
            if(data[i].MedicalProSpeciality.length > 0){
                for(let y =0; y < data[i].MedicalProSpeciality.length; y++){
                    specialite += " "+data[i].MedicalProSpeciality[y].nameMedicalProSpeciality
                }
            }
            elem.push(
                <tr key={i}>
                    <td>{data[i].Reference}</td>
                    <td>{data[i].LastName}</td>
                    <td>{data[i].FirstName}</td>
                    <td>{data[i].MedicalProType.nameMedicalProType}</td>
                    <td>{specialite}</td>
                </tr>
            )
        }
        table.push(
            <div key={0} style={{border: "3px solid red",padding: "10px"}}>
                <div style={{fontWeight: "bold"}}>PDS similaires déjà enregistrés</div>
                <table>
                    <tbody>{elem}</tbody>
                </table>
                {dataSend.nbrPage > 1 &&
                    <Pagination
                        maxPage={dataSend.nbrPage}
                        page={dataSend.page}
                        onChangePage={this.onChangePage}
                    ></Pagination>
                }
            </div>
        )
        return table;
    }

    render() {
        const edit = Object.keys(this.props.pro).length > 0;
        
        const formatGroupLabel = data => (
            <div>
                <span>{data.label}</span>
            </div>
        );
        
        const doublons = this.props.doublons && this.props.doublons.status && this.props.doublons.data && this.props.doublons.data.length > 0 ? this.renderDoublons(this.props.doublons) : [];

        return(
            <div>
                <div className="modal fade in" id="modalAddEditPro" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditProLabel" animate="animate">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="myModalLabel">
                                {edit ? `Modification - ${this.props.pro.Reference} - ${this.props.pro.Title} ${this.props.pro.FirstName} ${this.props.pro.LastName}`
                                : "Ajouter un professionnel de santé"}
                                </h1>
                            </div>
                            <div className="modal-body">

                                    <div className={edit ? "nav-tabs-custom" : ""}>
                                        {edit &&
                                        <ul className="nav nav-tabs">
                                            <li><a href="#tab_1" data-toggle="tab" className="active">Général</a></li>
                                            <li><a href="#tab_2" data-toggle="tab">Lieux de consultation</a></li>
                                            <li><a href="#tab_3" data-toggle="tab">Spécialités</a></li>
                                            <li><a href="#tab_4" data-toggle="tab">Logiciels</a></li>
                                            {this.props.readOnly && !this.props.validating && <li><a href="#tab_5" data-toggle="tab">Historique</a></li>}
                                        </ul>
                                        }
                                        <div className={edit ? "tab-content" : ""}>
                                            <div className={edit ? "tab-pane active" : ""} id="tab_1">
                                                {!this.props.readOnly ? (
                                                <div>
                                                <div>
                                                    <div className="row">
                                                        <div className="col-md-5">
                                                            <label style={{display: "inline-block"}}>Type de PDS</label>
                                                            {this.props.typePDSLoading && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader typePDS" />}
                                                            <div>
                                                                <CustomSelect
                                                                defaultText={this.state.pds}
                                                                optionsList={this.props.pdsList}
                                                                handleOptionClick={(id, name) => 
                                                                    {
                                                                        this.setState({
                                                                            ...this.state,
                                                                            idPDS: id,
                                                                            pds: name,
                                                                            mainSpe: "Sélectionnez une spécialité",
                                                                            idMainSpe: -1,
                                                                            listSpeByType: []
                                                                        });
                                                                        this.props.getSpeByType(id);
                                                                    }
                                                                }
                                                                />
                                                            </div>
                                                        </div>
                                                        {this.state.idPDS !== -1 ?
                                                            this.state.listSpeByType.length > 0 ?
                                                            <div className="col-md-5">
                                                                <label>Spécialité principale</label>
                                                                <CustomSelect
                                                                defaultText={this.state.mainSpe}
                                                                optionsList={this.state.listSpeByType}
                                                                handleOptionClick={(id, name) => this.setState({
                                                                    ...this.state,
                                                                    idMainSpe: id,
                                                                    mainSpe: name
                                                                })}
                                                                />
                                                            </div>
                                                            : this.props.loadingSpeByType && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader speByType" />
                                                        : null}
                                                        </div>
                                                </div>
                                                <div className="row">
                                                    {edit &&
                                                        <>
                                                            {(this.props.pro.MedicalProType.idMedicalProType !== 8) &&  //assist medic
                                                            (this.props.pro.MedicalProType.idMedicalProType !== 12) &&  //assist sanitaire
                                                            (this.props.pro.MedicalProType.idMedicalProType !== 10) &&  //auxiliaire pharma
                                                            (this.props.pro.MedicalProType.idMedicalProType !== 11) &&  //psy
                                                            (this.props.pro.MedicalProType.idMedicalProType !== 9) &&   //technicien
                                                            (this.props.pro.MedicalProType.idMedicalProType !== 13) &&  //vendeur pharma
                                                            <div className="col-md-2">
                                                                <label>Titre</label>
                                                                <CustomSelect
                                                                defaultText={this.state.titre}
                                                                optionsList={
                                                                    (this.props.pro.MedicalProType.idMedicalProType === 7) ||   //charge cmd
                                                                    (this.props.pro.MedicalProType.idMedicalProType === 3) ||   //infirmier
                                                                    (this.props.pro.MedicalProType.idMedicalProType === 4) ||   //kine
                                                                    (this.props.pro.MedicalProType.idMedicalProType === 6) ? [  //sage femme
                                                                        { id: 0, name: "M." },
                                                                        { id: 1, name: "Mme" },
                                                                        { id: 2, name: "Mlle" }
                                                                    ] : [
                                                                        { id: 0, name: "Docteur" },
                                                                        { id: 1, name: "Professeur" }
                                                                    ]}
                                                                handleOptionClick={(id, name) => this.setState({titre: name})}
                                                                />
                                                            </div>
                                                            }
                                                        </>
                                                    }
                                                    <div className={edit ? "col-md-5" : "col-md-6"}>
                                                        <label>Nom</label>
                                                        <input
                                                        type="text"
                                                        required
                                                        value={this.state.name}
                                                        onChange={(e) => {
                                                            const cursor = e.target.selectionStart;
                                                            this.setState({ name: e.target.value.toUpperCase() });
                                                            e.target.selectionStart = cursor;
                                                        }}
                                                        />
                                                    </div>
                                                    <div className={edit ? "col-md-5" : "col-md-6"}>
                                                        <label>Prénom</label>
                                                        <input
                                                        type="text"
                                                        required 
                                                        value={this.state.fname} 
                                                        onChange={(e) => {
                                                            const cursor = e.target.selectionStart;
                                                            this.setState({ fname: e.target.value.toUpperCase() });
                                                            e.target.selectionStart = cursor;
                                                        }}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="row radio-div">
                                                    <div className="col-md-3">
                                                        <label>Sexe</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="sexe"
                                                                checked={this.state.sexe === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({sexe: parseInt(e.target.value)})}
                                                                id="sexeInconnu"
                                                                />
                                                                <label htmlFor="sexeInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="sexe"
                                                                checked={this.state.sexe === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({sexe: parseInt(e.target.value)})}
                                                                id="sexeH"
                                                                />
                                                                <label htmlFor="sexeH">Homme</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="sexe"
                                                                checked={this.state.sexe === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({sexe: parseInt(e.target.value)})}
                                                                id="sexeF"
                                                                />
                                                                <label htmlFor="sexeF">Femme</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <label>Adresse email</label>
                                                <input type="text" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}/>

                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <label>Téléphone portable</label>
                                                        <input type="tel" value={this.state.telP} onChange={(e) => this.setState({telP: e.target.value})}/>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label>Téléphone fixe</label>
                                                        <input type="tel" value={this.state.telF} onChange={(e) => this.setState({telF: e.target.value})}/>
                                                    </div>
                                                </div>
                                            
                                                {doublons.length > 0 && this.state.name !== "" &&
                                                <div style={{ flex: "1 1 0%", marginLeft: 5}}>
                                                    {doublons}
                                                </div>
                                                }

                                                <div className="row radio-div">
                                                    <div className="col-md-3">
                                                        <label>Informatisé</label>
                                                        <label>Leader d'opinion</label>
                                                        <label>Ami d'Ethica</label>
                                                        <label style={{fontSize: 15}}>Enregistré sur la plateforme ETHICA</label>
                                                        <label>Retraité</label>
                                                        <label>Décédé</label>
                                                    </div>
                                                    <div className="col-md-9">
                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="informatise"
                                                                checked={this.state.informatise === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({informatise: parseInt(e.target.value)})}
                                                                id="informatiseInconnu"
                                                                />
                                                                <label htmlFor="informatiseInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="informatise"
                                                                checked={this.state.informatise === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({informatise: parseInt(e.target.value)})}
                                                                id="informatiseOui"
                                                                />
                                                                <label htmlFor="informatiseOui">Oui</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="informatise"
                                                                checked={this.state.informatise === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({informatise: parseInt(e.target.value)})}
                                                                id="informatiseNon"
                                                                />
                                                                <label htmlFor="informatiseNon">Non</label>
                                                            </div>
                                                        </div>

                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="leaderOpinion"
                                                                checked={this.state.leaderOpinion === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({leaderOpinion: parseInt(e.target.value)})}
                                                                id="leaderOpinionInconnu"
                                                                />
                                                                <label htmlFor="leaderOpinionInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="leaderOpinion"
                                                                checked={this.state.leaderOpinion === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({leaderOpinion: parseInt(e.target.value)})}
                                                                id="leaderOpinionOui"
                                                                />
                                                                <label htmlFor="leaderOpinionOui">Oui</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="leaderOpinion"
                                                                checked={this.state.leaderOpinion === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({leaderOpinion: parseInt(e.target.value)})}
                                                                id="leaderOpinionNon"
                                                                />
                                                                <label htmlFor="leaderOpinionNon">Non</label>
                                                            </div>
                                                        </div>

                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="ethicaFriend"
                                                                checked={this.state.ethicaFriend === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({ethicaFriend: parseInt(e.target.value)})}
                                                                id="ethicaFriendInconnu"
                                                                />
                                                                <label htmlFor="ethicaFriendInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="ethicaFriend"
                                                                checked={this.state.ethicaFriend === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({ethicaFriend: parseInt(e.target.value)})}
                                                                id="ethicaFriendOui"
                                                                />
                                                                <label htmlFor="ethicaFriendOui">Oui</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="ethicaFriend"
                                                                checked={this.state.ethicaFriend === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({ethicaFriend: parseInt(e.target.value)})}
                                                                id="ethicaFriendNon"
                                                                />
                                                                <label htmlFor="ethicaFriendNon">Non</label>
                                                            </div>
                                                        </div>

                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="savedOnEthica"
                                                                checked={this.state.savedOnEthica}
                                                                value={true}
                                                                onChange={() => this.setState({savedOnEthica: true})}
                                                                id="savedOnEthicaOui"
                                                                />
                                                                <label htmlFor="savedOnEthicaOui">Oui</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="savedOnEthica"
                                                                checked={!this.state.savedOnEthica}
                                                                value={false}
                                                                onChange={() => this.setState({savedOnEthica: false})}
                                                                id="savedOnEthicaNon"
                                                                />
                                                                <label htmlFor="savedOnEthicaNon">Non</label>
                                                            </div>
                                                        </div>

                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="isRetired"
                                                                checked={this.state.isRetired === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({isRetired: parseInt(e.target.value)})}
                                                                id="isRetiredInconnu"
                                                                />
                                                                <label htmlFor="isRetiredInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="isRetired"
                                                                checked={this.state.isRetired === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({isRetired: parseInt(e.target.value)})}
                                                                id="isRetiredOui"
                                                                />
                                                                <label htmlFor="isRetiredOui">Oui</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="isRetired"
                                                                checked={this.state.isRetired === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({isRetired: parseInt(e.target.value)})}
                                                                id="isRetiredNon"
                                                                />
                                                                <label htmlFor="isRetiredNon">Non</label>
                                                            </div>
                                                        </div>

                                                        <div className="radio-form">
                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="isDeath"
                                                                checked={this.state.isDeath === 0}
                                                                value={0}
                                                                onChange={(e) => this.setState({isDeath: parseInt(e.target.value)})}
                                                                id="isDeathInconnu"
                                                                />
                                                                <label htmlFor="isDeathInconnu">Inconnu</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="isDeath"
                                                                checked={this.state.isDeath === 1}
                                                                value={1}
                                                                onChange={(e) => this.setState({isDeath: parseInt(e.target.value)})}
                                                                id="isDeathOui"
                                                                />
                                                                <label htmlFor="isDeathOui">Oui</label>
                                                            </div>

                                                            <div className="radio">
                                                                <input
                                                                type="radio"
                                                                name="isDeath"
                                                                checked={this.state.isDeath === 2}
                                                                value={2}
                                                                onChange={(e) => this.setState({isDeath: parseInt(e.target.value)})}
                                                                id="isDeathNon"
                                                                />
                                                                <label htmlFor="isDeathNon">Non</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                {!edit && !this.props.readOnly &&
                                                    <div>
                                                        <label className="lieuConsult">Lieu de consultation</label>
                                                        {this.props.loadingEtabs && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader etabs" />}
                                                        <Select
                                                        options={(this.props.typeEtabList !== undefined) && this.props.typeEtabList.length !== 0 ? this.generateEtabOptions() : []}
                                                        formatGroupLabel={formatGroupLabel}
                                                        value={this.state.firstEtab}
                                                        onChange={(newValue) => this.setState({firstEtab: newValue})}
                                                        placeholder="Entrez le nom d'un établissement"
                                                        formatOptionLabel={(option, { context }) => {
                                                            /* context can be either `menu` or `value`
                                                                menu - dropdown
                                                                value - value displayed
                                                            */
                                                            return context === 'menu' ? (
                                                                <div className="select-react-option etab">
                                                                    <div>
                                                                        <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                                                            <p>
                                                                                {option.isPrivate === 1 ? "Public"
                                                                                : option.isPrivate === 2 ? "Privé"
                                                                                : option.isPrivate === 3 ? "Semi-privé"
                                                                                : "Inconnu"}
                                                                            </p>
                                                                        </div>
                                                                        <p>{option.ref} - {option.label}</p>
                                                                    </div>
                                                                    <small>{option.ad}</small>
                                                                </div>
                                                            ) : option.label;
                                                        }}
                                                        isClearable
                                                        className="react-select-container"
                                                        />
                                                    </div>
                                                }
                                                </div>
                                                ) : this.props.readOnly &&   //readOnly
                                                <div className="row pdsReadOnly">
                                                    <div className="col-md-4">
                                                        <label>Nom</label>
                                                        <label>Type de PDS</label>
                                                        <label>Sexe</label>
                                                        <label>Adresse email</label>
                                                        <label>Téléphone portable</label>
                                                        <label>Téléphone fixe</label>
                                                        <label>Informatisé</label>
                                                        <label>Leader d'opinion</label>
                                                        <label>Ami d'Ethica</label>
                                                        <label>Enregistré sur la plateforme ETHICA</label>
                                                        <label>Origine (data)</label>
                                                        <label>Créé par</label>
                                                        <label>Créé le</label>
                                                        <label>Retraité</label>
                                                        <label>Décédé</label>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.Title !== this.props.pro.Old_Title) ? "highlight" : ""}>
                                                                {this.state.titre}
                                                            </span>
                                                            &nbsp;
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.FirstName !== this.props.pro.Old_FirstName) ? "highlight" : ""}>
                                                                {this.state.fname}
                                                            </span>
                                                            &nbsp;
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.LastName !== this.props.pro.Old_LastName) ? "highlight" : ""}>
                                                                {this.state.name}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.MedicalProType.idMedicalProType !== this.props.pro.Old_MedicalProType.idMedicalProType) ? "highlight" : ""}>
                                                                {this.state.pds}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.Gender !== this.props.pro.Old_Gender) ? "highlight" : ""}>
                                                                {this.state.sexe === 1 ? "Homme"
                                                                : this.state.sexe === 2 ? "Femme"
                                                                : "Inconnu"}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.Mail !== this.props.pro.Old_Mail) ? "highlight" : ""}>
                                                                {this.state.email}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.Mobile !== this.props.pro.Old_Mobile) ? "highlight" : ""}>
                                                                {this.state.telP}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.LandLine !== this.props.pro.Old_LandLine) ? "highlight" : ""}>
                                                                {this.state.telF}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.IsComputerized !== this.props.pro.Old_IsComputerized) ? "highlight" : ""}>
                                                                {this.state.informatise === 1 ? "Oui" : this.state.informatise === 2 ? "Non" : "Inconnu"}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.IsThoughtLeader !== this.props.pro.Old_IsThoughtLeader) ? "highlight" : ""}>
                                                                {this.state.leaderOpinion === 1 ? "Oui" : this.state.leaderOpinion === 2 ? "Non" : "Inconnu"}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.IsEthicaFriend !== this.props.pro.Old_IsEthicaFriend) ? "highlight" : ""}>
                                                                {this.state.ethicaFriend === 1 ? "Oui" : this.state.ethicaFriend === 2 ? "Non" : "Inconnu"}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.SavedOnEthica !== this.props.pro.Old_SavedOnEthica) ? "highlight" : ""}>
                                                                {this.state.savedOnEthica ? "Oui" : "Non"}
                                                            </span>
                                                        </p>
                                                        <p>{this.props.pro.OrgnCode}</p>
                                                        <p>{this.props.pro.CustomCreatedBy}</p>
                                                        <p><Moment locale="fr">{new Date(this.props.pro.CreatedAt)}</Moment></p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.isRetired !== this.props.pro.Old_isRetired) ? "highlight" : ""}>
                                                                {this.state.isRetired === 1 ? "Oui" : this.state.isRetired === 2 ? "Non" : "Inconnu"}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            <span className={this.props.pro.isEdit && this.props.validating && (this.props.pro.isDeath !== this.props.pro.Old_isDeath) ? "highlight" : ""}>
                                                                {this.state.isDeath === 1 ? "Oui" : this.state.isDeath === 2 ? "Non" : "Inconnu"}
                                                            </span>
                                                        </p>
                                                    </div>

                                                    {doublons.length > 0 && this.state.name !== "" &&
                                                    <div className="col-md-12">
                                                        {doublons}
                                                    </div>
                                                    }
                                                </div>
                                                }
                                            </div>
                                            {edit &&
                                                <div className="tab-pane" id="tab_2">
                                                    <table className="box tab-entete proEtab">
                                                        <tbody>
                                                            <tr>
                                                                <td className="small"></td>
                                                                <td className="small"><div><p>P</p></div></td>
                                                                <td className="nameEtab"><div><p>Établissement</p></div></td>
                                                                <td className="zg"><div><p>Zone géographique</p></div></td>
                                                                <td className="type"><div><p>Type</p></div></td>
                                                                <td className="stat"><div><p>Statut</p></div></td>
                                                                {!this.props.readOnly ? <td className="last"><ButtonAdd modal="#modalAddEditProEtab" handleAdd={() => this.setState({selectedProEtab: {}})}/></td> :  null}
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="list proEtab">
                                                        <tbody>
                                                        {this.props.pro.MedicalProsInstitutions.map((etab, i) => {
                                                            return (
                                                                <tr key={i} className="list-row">
                                                                    <td className="planning small">
                                                                        <div
                                                                        className={`tag ${etab.isPrivateInstitution === 1 ? "valid" : etab.isPrivateInstitution === 2 ? "prive" : etab.isPrivateInstitution === 3 ? "warning" : "inconnu"}`}
                                                                        >
                                                                            <p>
                                                                            {etab.isPrivateInstitution === 1 ? "Public"
                                                                            : etab.isPrivateInstitution === 2 ? "Privé"
                                                                            : etab.isPrivateInstitution === 3 ? "Semi-privé"
                                                                            : "Inconnu"}
                                                                            </p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="small"></td>
                                                                    <td className="nameEtab">{etab.NameInstitution}</td>
                                                                    <td className="zg">{etab.ZoneGeoInstitution}</td>
                                                                    <td className="type">{etab.TypeInstitution.nameType}</td>
                                                                    <td className="planning stat">
                                                                        <div
                                                                        className={`tag ${etab.StatusInstitution ? "valid" : "warning"}`}
                                                                        onClick={() => !this.props.readOnly ? this.props.disableEtabPro(this.props.pro.Id, etab.IdInstitution) : {}}>
                                                                            <p>{etab.StatusInstitution ? "Actuel" : "Ancien"}</p>
                                                                        </div>
                                                                    </td>
                                                                    {!this.props.readOnly &&
                                                                    <td className="icons last">
                                                                        <div
                                                                        className="icon-btn"
                                                                        data-toggle="modal"
                                                                        data-target="#modalAddEditProEtab"
                                                                        onClick={() => this.setState({selectedProEtab: etab})}>
                                                                            <img src="/dist/img/icons/icon-edit.png" alt=""/>
                                                                        </div>
                                                                        <div
                                                                        className="icon-btn"
                                                                        onClick={() => this.props.deleteEtabPro(this.props.pro.Id, etab.IdInstitution)}>
                                                                            <img src={`/dist/img/icons/icon-delete.png`} alt=""/>
                                                                        </div>
                                                                    </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {edit &&
                                                <div className="tab-pane" id="tab_3">
                                                    {this.state.listSpeByType.length > 0 && !this.props.readOnly &&
                                                        <div className="speLog">
                                                            <CustomSelect
                                                            defaultText={this.state.selectedSpe.nameSpeciality}
                                                            optionsList={this.state.listSpeByType}
                                                            handleOptionClick={(id, name) => this.setState({selectedSpe: {
                                                                idSpeciality: id,
                                                                nameSpeciality: name
                                                            }})}
                                                            />
                                                            &nbsp;
                                                            <button className='btn-add' onClick={(e) => {
                                                                e.preventDefault();
                                                                this.props.postSpePro(this.props.pro.Id, this.state.selectedSpe.idSpeciality)
                                                            }}>+</button>
                                                        </div>
                                                    }

                                                    <table className="box tab-entete proSpe">
                                                        <tbody>
                                                            <tr>
                                                                <td><div><p>Spécialité</p></div></td>
                                                                <td><div><p>Statut</p></div></td>
                                                                <td><div><p>Type</p></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="list proSpe">
                                                        <tbody>
                                                        {this.state.listSpe.map((spe, i) => {
                                                            return (
                                                                <tr key={i} className="list-row">
                                                                    <td>{spe.nameSpeciality}</td>
                                                                    <td className="planning">
                                                                        <div
                                                                        className={`tag ${spe.status === 0 ? "valid" : spe.status === 1 ? "warning" : "alert"}`}
                                                                        onClick={() => !this.props.readOnly && !spe.isMAin && this.props.disableSpePro(this.props.pro.Id, spe.idSpeciality)}>
                                                                            <p>{spe.status === 0 ? "Actuelle" : spe.status === 1 ? "Ancienne" : "À supprimer"}</p>
                                                                        </div>
                                                                    </td>
                                                                    <td className="planning">
                                                                        <div className={`tag ${spe.isMAin ? "valid" : "warning"}`}>
                                                                            <p>{spe.isMAin ? "Principale" : "Secondaire"}</p>
                                                                        </div>
                                                                    </td>
                                                                    {!this.props.readOnly && (!spe.isMAin || spe.status === 2) &&
                                                                    <td className="icons last">
                                                                        <div
                                                                        className="icon-btn"
                                                                        onClick={() => this.props.deleteSpePro(this.props.pro.Id, spe.idSpeciality)}>
                                                                            <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                                                        </div>
                                                                    </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {edit &&
                                                <div className="tab-pane" id="tab_4">
                                                    {!this.props.readOnly &&
                                                    <div className="speLog">
                                                        <CustomSelect
                                                        defaultText={this.state.selectedLog.nameSoftware}
                                                        optionsList={this.props.logList}
                                                        handleOptionClick={(id, name) => this.setState({selectedLog: {
                                                            idSoftware: id,
                                                            nameSoftware: name
                                                        }})}
                                                        />
                                                        <button className='btn-add' onClick={(e) => {
                                                            e.preventDefault()
                                                            this.props.postLogPro(this.props.pro.Id, this.state.selectedLog.idSoftware)
                                                        }}>+</button>
                                                    </div>
                                                    }

                                                    <table className="box tab-entete proLog">
                                                        <tbody>
                                                            <tr>
                                                                <td><div><p>Logiciel</p></div></td>
                                                                <td><div><p>Statut</p></div></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="list proLog">
                                                        <tbody>
                                                        {this.state.listLog.map((log, i) => {
                                                            return (
                                                                <tr key={i} className="list-row">
                                                                    <td>{log.nameSoftware}</td>
                                                                    <td className="planning">
                                                                        <div
                                                                        className={`tag ${log.status ? "valid" : "warning"}`}
                                                                        onClick={() => !this.props.readOnly ? this.props.disableLogPro(this.props.pro.Id, log.idSoftware) : {}}>
                                                                            <p>{log.status ? "Actuel" : "Ancien"}</p>
                                                                        </div>
                                                                    </td>
                                                                    {!this.props.readOnly &&
                                                                    <td className="icons last">
                                                                        <div
                                                                        className="icon-btn"
                                                                        onClick={() => this.props.deleteLogPro(this.props.pro.Id, log.idSoftware)}>
                                                                            <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                                                        </div>
                                                                    </td>
                                                                    }
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                            {this.props.readOnly && !this.props.validating &&
                                                <div className="tab-pane" id="tab_5">
                                                    <table className="box tab-entete proHisto">
                                                        <tbody>
                                                            <tr>
                                                                <td className="first">
                                                                    <div><p>Action</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Par</p></div>
                                                                </td>
                                                                <td>
                                                                    <div><p>Date</p></div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="list proHisto">
                                                        <tbody>
                                                        {this.state.histo.map((action, i) => {
                                                            return (
                                                                <tr key={`histo${i}`} className="list-row">
                                                                    <td className="first">
                                                                        {action.action}
                                                                    </td>
                                                                    <td>
                                                                        {action.par.fullname}
                                                                    </td>
                                                                    <td>
                                                                        <Moment locale="fr" fromNow>{new Date(action.date)}</Moment>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            }
                                        </div>
                                    </div>

                            </div>
                            <div className="modal-footer">
                                {!this.props.readOnly &&
                                    <button
                                    type="submit"
                                    form="formAddEditPro"
                                    className="btn-save"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if(Object.keys(this.props.pro).length > 0) this.props.handleSubmit(this.handleSubmit);
                                        else this.handleSubmit(e);
                                    }}
                                    data-toggle={ Object.keys(this.props.pro).length > 0 ? 'modal' : '' }
                                    data-target={'#modalConfirmation'}
                                    disabled={(this.state.name === "") || (this.state.idPDS === -1) || (this.state.fname === "")}
                                    >
                                        <img src="/dist/img/icons/icon-save.png" alt=""/>
                                        Sauvegarder
                                    </button>
                                }
                                {(this.props.validating && !this.props.pro.isEdit) &&
                                    <button
                                    type="button"
                                    className="btn-delete"
                                    /*data-toggle="modal"
                                    data-target="#modalDelete"*/
                                    data-dismiss="modal"
                                    onClick={() => {
                                        this.btnElement.click()
                                        this.props.disablePro(this.props.pro.Id, this.props.pro.isEdit)
                                    }}>
                                        <img src="/dist/img/icons/icon-times-red.png" alt=""/>
                                        Supprimer
                                    </button>
                                }
                                {this.props.validating && this.props.pro.isEdit &&
                                    <button
                                    type="button"
                                    className="btn-export"
                                    data-dismiss="modal"
                                    onClick={() => this.props.disablePro(this.props.pro.Id, this.props.pro.isEdit)}>
                                        <img src="/dist/img/icons/icon-historique.png" alt="" style={{width: 14, height: 14}}/>
                                        Revenir à la version précédente
                                    </button>
                                }
                                {this.props.validating &&
                                    <button
                                    type="button"
                                    className="btn-valid"
                                    onClick={() => this.props.enableEditPro(this.props.pro.Id, this.props.pro.isEdit)}>
                                        <img src="/dist/img/icons/icon-valid-green.png" alt=""/>
                                        Valider puis modifier
                                    </button>
                                }
                                {this.props.validating &&
                                    <button
                                    type="button"
                                    className="btn-valid"
                                    data-dismiss="modal"
                                    onClick={() => this.props.enablePro(this.props.pro.Id, this.props.pro.isEdit)}>
                                        <img src="/dist/img/icons/icon-valid-green.png" alt=""/>
                                        Valider
                                    </button>
                                }
                                <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                ref={button => this.btnElement = button}
                                >
                                    {this.props.readOnly && !this.props.validating ? "OK" : "Annuler"}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <PopupAddEditProEtab
                postProEtab={(idPro, etab) => this.props.postMedicalProEtab(idPro, etab)}
                etab={this.state.selectedProEtab}
                editProEtab={(idPro, etab) => this.props.editMedicalProEtab(idPro, etab)}
                etabList={this.props.etabCountryList}
                idPro={this.props.pro.Id}
                idCountry={this.props.idCountry}
                />
            
            </div>
        )
    }
}

function mapState(state) {
    const { loadingEtabs, etabs } = state.etab;
    const { etabProPosted, etabProEdited, etabProDisabled, loadingSpePro, spePro, speProPosted, speProDisabled, speProDeleted, loadingLogPro, logPro, logProPosted, logProDisabled, logProDeleted, etabProDeleted, loadingHistoPro, histo, loadingSpeByType, speByType } = state.medicalPro;
    return { loadingEtabs, etabs, etabProPosted, etabProEdited, etabProDisabled, loadingSpePro, spePro, speProPosted, speProDisabled, speProDeleted, loadingLogPro, logPro, logProPosted, logProDisabled, logProDeleted, etabProDeleted, loadingHistoPro, histo, loadingSpeByType, speByType };
}
const actionCreators = {
    getEtablissements: etabActions.getEtablissements,
    postMedicalProEtab: medicalProActions.postEtabPro,
    editMedicalProEtab: medicalProActions.editEtabPro,
    disableEtabPro: medicalProActions.disableEtabPro,
    deleteEtabPro: medicalProActions.deleteEtabPro,
    getSpePro: medicalProActions.getSpePro,
    postSpePro: medicalProActions.postSpePro,
    disableSpePro: medicalProActions.disableSpePro,
    deleteSpePro: medicalProActions.deleteSpePro,
    getLogPro: medicalProActions.getLogPro,
    postLogPro: medicalProActions.postLogPro,
    disableLogPro: medicalProActions.disableLogPro,
    deleteLogPro: medicalProActions.deleteLogPro,
    getHistoPDS: medicalProActions.getHistoPDS,
    getSpeByType: medicalProActions.getSpeByType
};

const PopupAddEditPro = connect(mapState, actionCreators)(PopupAddEditProPage);

export default PopupAddEditPro;