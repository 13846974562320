import { medicalProConstants } from '../constants/medicalPro.constants';

export function medicalPro(state = {}, action) {
  switch (action.type) {
    case medicalProConstants.GET_MEDICAL_PRO_REQUEST:
      return {
        loadingMedicalPros: true,
        medicalPros: {}
      };
    case medicalProConstants.GET_MEDICAL_PRO_SUCCESS:
      return {
        medicalPros: action.listMedicalPros,
        loadingMedicalPros: false
      };
    case medicalProConstants.GET_MEDICAL_PRO_FAILURE:
      return { 
        error: action.error,
        loadingMedicalPros: false
      };
    case medicalProConstants.POST_MEDICAL_PRO_REQUEST:
      return {
        medicalProPosted: false
      };
    case medicalProConstants.POST_MEDICAL_PRO_SUCCESS:
      return {
        medicalProPosted: true
      };
    case medicalProConstants.POST_MEDICAL_PRO_FAILURE:
      return { 
        error: action.error,
        medicalProPosted: false
      };
    case medicalProConstants.EDIT_MEDICAL_PRO_REQUEST:
      return {
        medicalProEdited: false
      };
    case medicalProConstants.EDIT_MEDICAL_PRO_SUCCESS:
      return {
        medicalProEdited: true
      };
    case medicalProConstants.EDIT_MEDICAL_PRO_FAILURE:
      return { 
        error: action.error,
        medicalProEdited: false
      };
    case medicalProConstants.DELETE_MEDICAL_PRO_REQUEST:
      return {
        medicalProDeleted: false
      };
    case medicalProConstants.DELETE_MEDICAL_PRO_SUCCESS:
      return {
        medicalProDeleted: true
      };
    case medicalProConstants.DELETE_MEDICAL_PRO_FAILURE:
      return { 
        error: action.error,
        medicalProDeleted: false
      };
    case medicalProConstants.VALIDATE_MEDICAL_PRO_REQUEST:
      return {
        medicalProValidated: false
      };
    case medicalProConstants.VALIDATE_MEDICAL_PRO_SUCCESS:
      return {
        medicalProValidated: true
      };
    case medicalProConstants.VALIDATE_MEDICAL_PRO_FAILURE:
      return { 
        error: action.error,
        medicalProValidated: false
      };
    case medicalProConstants.UNVALIDATE_MEDICAL_PRO_REQUEST:
      return {
        medicalProUnvalidated: false
      };
    case medicalProConstants.UNVALIDATE_MEDICAL_PRO_SUCCESS:
      return {
        medicalProUnvalidated: true
      };
    case medicalProConstants.UNVALIDATE_MEDICAL_PRO_FAILURE:
      return { 
        error: action.error,
        medicalProUnvalidated: false
      };
    case medicalProConstants.POST_ETAB_PRO_REQUEST:   // ETAB
      return {
        etabProPosted: false
      };
    case medicalProConstants.POST_ETAB_PRO_SUCCESS:
      return {
        etabProPosted: true
      };
    case medicalProConstants.POST_ETAB_PRO_FAILURE:
      return { 
        error: action.error,
        etabProPosted: false
      };
    case medicalProConstants.EDIT_ETAB_PRO_REQUEST:
      return {
        etabProEdited: false
      };
    case medicalProConstants.EDIT_ETAB_PRO_SUCCESS:
      return {
        etabProEdited: true
      };
    case medicalProConstants.EDIT_ETAB_PRO_FAILURE:
      return { 
        error: action.error,
        etabProEdited: false
      };
    case medicalProConstants.DISABLE_ETAB_PRO_REQUEST:
      return {
        etabProDisabled: false
      };
    case medicalProConstants.DISABLE_ETAB_PRO_SUCCESS:
      return {
        etabProDisabled: true
      };
    case medicalProConstants.DISABLE_ETAB_PRO_FAILURE:
      return { 
        error: action.error,
        etabProDisabled: false
      };
    case medicalProConstants.DELETE_ETAB_PRO_REQUEST:
      return {
        etabProDeleted: false
      };
    case medicalProConstants.DELETE_ETAB_PRO_SUCCESS:
      return {
        etabProDeleted: true
      };
    case medicalProConstants.DELETE_ETAB_PRO_FAILURE:
      return { 
        error: action.error,
        etabProDeleted: false
      };
    case medicalProConstants.LINK_PROS_REQUEST:   // LINK
      return {
        prosLinked: false
      };
    case medicalProConstants.LINK_PROS_SUCCESS:
      return {
        prosLinked: true
      };
    case medicalProConstants.LINK_PROS_FAILURE:
      return { 
        error: action.error,
        prosLinked: false
      };
    case medicalProConstants.GET_ONE_PRO_REQUEST:   // ONE
      return {
        loadingMedicalPro: true,
        medicalPro: {}
      };
    case medicalProConstants.GET_ONE_PRO_SUCCESS:
      return {
        medicalPro: action.medicalPro,
        loadingMedicalPro: false
      };
    case medicalProConstants.GET_ONE_PRO_FAILURE:
      return { 
        error: action.error,
        loadingMedicalPro: false
      };
    case medicalProConstants.GET_SPE_PRO_REQUEST:   // SPE
      return {
        loadingSpePro: true,
        spePro: {}
      };
    case medicalProConstants.GET_SPE_PRO_SUCCESS:
      return {
        spePro: action.listSpePro,
        loadingSpePro: false
      };
    case medicalProConstants.GET_SPE_PRO_FAILURE:
      return { 
        error: action.error,
        loadingSpePro: false
      };
    case medicalProConstants.POST_SPE_PRO_REQUEST:
      return {
        speProPosted: false
      };
    case medicalProConstants.POST_SPE_PRO_SUCCESS:
      return {
        speProPosted: true
      };
    case medicalProConstants.POST_SPE_PRO_FAILURE:
      return { 
        error: action.error,
        speProPosted: false
      };
    case medicalProConstants.DISABLE_SPE_PRO_REQUEST:
      return {
        speProDisabled: false
      };
    case medicalProConstants.DISABLE_SPE_PRO_SUCCESS:
      return {
        speProDisabled: true
      };
    case medicalProConstants.DISABLE_SPE_PRO_FAILURE:
      return { 
        error: action.error,
        speProDisabled: false
      };
    case medicalProConstants.DELETE_SPE_PRO_REQUEST:
      return {
        speProDeleted: false
      };
    case medicalProConstants.DELETE_SPE_PRO_SUCCESS:
      return {
        speProDeleted: true
      };
    case medicalProConstants.DELETE_SPE_PRO_FAILURE:
      return { 
        error: action.error,
        speProDeleted: false
      };
    case medicalProConstants.GET_LOG_PRO_REQUEST:   // LOG
      return {
        loadingLogPro: true,
        logPro: {}
      };
    case medicalProConstants.GET_LOG_PRO_SUCCESS:
      return {
        logPro: action.listLogPro,
        loadingLogPro: false
      };
    case medicalProConstants.GET_LOG_PRO_FAILURE:
      return { 
        error: action.error,
        loadingLogPro: false
      };
    case medicalProConstants.POST_LOG_PRO_REQUEST:
      return {
        logProPosted: false
      };
    case medicalProConstants.POST_LOG_PRO_SUCCESS:
      return {
        logProPosted: true
      };
    case medicalProConstants.POST_LOG_PRO_FAILURE:
      return { 
        error: action.error,
        logProPosted: false
      };
    case medicalProConstants.DISABLE_LOG_PRO_REQUEST:
      return {
        logProDisabled: false
      };
    case medicalProConstants.DISABLE_LOG_PRO_SUCCESS:
      return {
        logProDisabled: true
      };
    case medicalProConstants.DISABLE_LOG_PRO_FAILURE:
      return { 
        error: action.error,
        logProDisabled: false
      };
    case medicalProConstants.DELETE_LOG_PRO_REQUEST:
      return {
        logProDeleted: false
      };
    case medicalProConstants.DELETE_LOG_PRO_SUCCESS:
      return {
        logProDeleted: true
      };
    case medicalProConstants.DELETE_LOG_PRO_FAILURE:
      return { 
        error: action.error,
        logProDeleted: false
      };
    case medicalProConstants.GET_ALL_LOG_REQUEST:
      return {
        loadingLogiciels: true,
        listLogiciels: {}
      };
    case medicalProConstants.GET_ALL_LOG_SUCCESS:
      return {
        listLogiciels: action.listLogiciels,
        loadingLogiciels: false
      };
    case medicalProConstants.GET_ALL_LOG_FAILURE:
      return { 
        error: action.error,
        loadingLogiciels: false
      };
    case medicalProConstants.GET_HISTO_PRO_REQUEST:   // HISTO
      return {
        loadingHistoPro: true,
        histo: {}
      };
    case medicalProConstants.GET_HISTO_PRO_SUCCESS:
      return {
        histo: action.histo,
        loadingHistoPro: false
      };
    case medicalProConstants.GET_HISTO_PRO_FAILURE:
      return { 
        error: action.error,
        loadingHistoPro: false
      };
      case medicalProConstants.GET_SPE_TYPE_REQUEST:
        return {
          loadingSpeByType: true,
          speByType: {}
        };
      case medicalProConstants.GET_SPE_TYPE_SUCCESS:
        return {
          speByType: action.speByType,
          loadingSpeByType: false
        };
      case medicalProConstants.GET_SPE_TYPE_FAILURE:
        return { 
          error: action.error,
          loadingSpeByType: false
        };
      case medicalProConstants.GET_PRO_INVALIDATE_REQUEST:
        return {
          loadingInvalidatePro: true,
          invalidatePro: {}
        };
      case medicalProConstants.GET_PRO_INVALIDATE_SUCCESS:
        return {
          invalidatePro: action.invalidate,
          loadingInvalidatePro: false
        };
      case medicalProConstants.GET_PRO_INVALIDATE_FAILURE:
        return { 
          error: action.error,
          loadingInvalidatePro: false
        };
      case medicalProConstants.GET_VERFIF_PRO_REQUEST:
        return {
          loadingVerifPro: true,
          verif: {}
        };
      case medicalProConstants.GET_VERFIF_PRO_SUCCESS:
        return {
          verif: action.verif,
          loadingVerifPro: false
        };
      case medicalProConstants.GET_VERFIF_PRO_FAILURE:
        return { 
          error: action.error,
          loadingVerifPro: false
        };
    default:
      return state
  }
}