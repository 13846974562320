import React from 'react';
import ReactPaginate from 'react-paginate';

class Pagination extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            page : this.props.page,
            maxPage: this.props.maxPage
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.maxPage !== prevProps.maxPage) this.setState({ maxPage: this.props.maxPage });
    }

    handlePageClick = (data) => this.props.onChangePage(data.selected + 1);

    render() {
        return(
            <div style={{maxWidth: 500, margin: '0 auto' }}>
                <ReactPaginate
                    pageCount={this.state.maxPage}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}                    
                    previousLabel={'<'}
                    nextLabel={'>'}                    
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'} 
                ></ReactPaginate>
            </div>
        )
    }
}

export default Pagination;