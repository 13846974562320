export const zoneGeoConstants = {   
    GET_ALL_COUNTRIES_REQUEST: 'COUNTRIES_GETALL_REQUEST',
    GET_ALL_COUNTRIES_SUCCESS: 'COUNTRIES_GETALL_SUCCESS',
    GET_ALL_COUNTRIES_FAILURE: 'COUNTRIES_GETALL_FAILURE',

    GET_BY_COUNTRY_REQUEST: 'GET_ZONEGEO_BY_COUNTRY_REQUEST',
    GET_BY_COUNTRY_SUCCESS: 'GET_ZONEGEO_BY_COUNTRY_SUCCESS',
    GET_BY_COUNTRY_FAILURE: 'GET_ZONEGEO_BY_COUNTRY_FAILURE',

    SET_COUNTRY: 'SET_COUNTRY',

    GET_ALL_ZONES_REQUEST: 'ZONES_GETALL_REQUEST',
    GET_ALL_ZONES_SUCCESS: 'ZONES_GETALL_SUCCESS',
    GET_ALL_ZONES_FAILURE: 'ZONES_GETALL_FAILURE',  

    GET_USER_COUNTRY_REQUEST: 'GET_USER_COUNTRY_REQUEST',
    GET_USER_COUNTRY_SUCCESS: 'GET_USER_COUNTRY_SUCCESS',
    GET_USER_COUNTRY_FAILURE: 'GET_USER_COUNTRY_FAILURE',
    
    POST_ZONE_GEO_REQUEST: 'POST_ZONE_GEO_REQUEST',
    POST_ZONE_GEO_SUCCESS: 'POST_ZONE_GEO_SUCCESS',
    POST_ZONE_GEO_FAILURE: 'POST_ZONE_GEO_FAILURE',

    EDIT_ZONE_GEO_REQUEST: 'EDIT_ZONE_GEO_REQUEST',
    EDIT_ZONE_GEO_SUCCESS: 'EDIT_ZONE_GEO_SUCCESS',
    EDIT_ZONE_GEO_FAILURE: 'EDIT_ZONE_GEO_FAILURE',

    DELETE_ZONE_GEO_REQUEST: 'DELETE_ZONE_GEO_REQUEST',
    DELETE_ZONE_GEO_SUCCESS: 'DELETE_ZONE_GEO_SUCCESS',
    DELETE_ZONE_GEO_FAILURE: 'DELETE_ZONE_GEO_FAILURE',
};