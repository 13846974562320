import { typeEtabConstants } from '../constants/typeEtab.constants';
import { typeEtabService } from '../services/typeEtab.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const typeEtabActions = {
    getAllTypeEtab,
    postTypeEtab,
    editTypeEtab,
    deleteTypeEtab
};

function getAllTypeEtab() {

    return (dispatch) => {

      dispatch(request());
      dispatch(showLoading());

      typeEtabService.getAllTypeEtab()
        .then(
            typesEtab => {      
                dispatch(success(typesEtab));    
                dispatch(hideLoading());
            },
            error => {
                dispatch(failure(error.toString()));
                dispatch(hideLoading());
            }
        );
    };

    function request() { return { type: typeEtabConstants.TYPE_ETAB_REQUEST} }
    function success(typesEtab) { return { type: typeEtabConstants.TYPE_ETAB_SUCCESS, typesEtab } }
    function failure(error) { return { type: typeEtabConstants.TYPE_ETAB_FAILURE, error } }
}

function postTypeEtab(type) {

    return (dispatch) => {

      dispatch(request());

      typeEtabService.postTypeEtab(type)
        .then(
            posted => {      
                dispatch(success(posted));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: typeEtabConstants.POST_TYPE_ETAB_REQUEST} }
    function success(posted) { return { type: typeEtabConstants.POST_TYPE_ETAB_SUCCESS, posted } }
    function failure(error) { return { type: typeEtabConstants.POST_TYPE_ETAB_FAILURE, error } }
}

function editTypeEtab(type) {

    return (dispatch) => {

      dispatch(request());

      typeEtabService.editTypeEtab(type)
        .then(
            edited => {      
                dispatch(success(edited));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: typeEtabConstants.EDIT_TYPE_ETAB_REQUEST} }
    function success(edited) { return { type: typeEtabConstants.EDIT_TYPE_ETAB_SUCCESS, edited } }
    function failure(error) { return { type: typeEtabConstants.EDIT_TYPE_ETAB_FAILURE, error } }
}

function deleteTypeEtab(id) {

    return (dispatch) => {

      dispatch(request());

      typeEtabService.deleteTypeEtab(id)
        .then(
            deleted => {      
                dispatch(success(deleted));    
            },
            error => {
                dispatch(failure(error.toString()));
            }
        );
    };
    
    function request() { return { type: typeEtabConstants.DELETE_TYPE_ETAB_REQUEST} }
    function success(deleted) { return { type: typeEtabConstants.DELETE_TYPE_ETAB_SUCCESS, deleted } }
    function failure(error) { return { type: typeEtabConstants.DELETE_TYPE_ETAB_FAILURE, error } }
}