import { logicielConstants } from '../constants/logiciel.constants';

export function software(state = {}, action) {
  switch (action.type) {
    case logicielConstants.LOGICIEL_REQUEST:
      return  Object.assign({}, state, {
        loadingLogiciel: true
      });
    case logicielConstants.LOGICIEL_SUCCESS:
      return  Object.assign({}, state, {
        loadingLogiciel: false,
        logiciel: action.logiciels
      });
    case logicielConstants.LOGICIEL_FAILURE:
      return  Object.assign({}, state, {          
        error: action.error
      });
    case logicielConstants.POST_LOGICIEL_REQUEST:
      return  Object.assign({}, state, {
        logPosted: false,
      });
    case logicielConstants.POST_LOGICIEL_SUCCESS:
      return  Object.assign({}, state, {
        logPosted: true,
      });
    case logicielConstants.POST_LOGICIEL_FAILURE:
      return  Object.assign({}, state, { 
        logPosted: false,         
        error: action.error
      });
    case logicielConstants.EDIT_LOGICIEL_REQUEST:
      return  Object.assign({}, state, {
        logEdited: false,
      });
    case logicielConstants.EDIT_LOGICIEL_SUCCESS:
      return  Object.assign({}, state, {
        logEdited: true,
      });
    case logicielConstants.EDIT_LOGICIEL_FAILURE:
      return  Object.assign({}, state, { 
        logEdited: false,         
        error: action.error
      });
    case logicielConstants.DELETE_LOGICIEL_REQUEST:
      return  Object.assign({}, state, {
        logDeleted: false,
      });
    case logicielConstants.DELETE_LOGICIEL_SUCCESS:
      return  Object.assign({}, state, {
        logDeleted: true,
      });
    case logicielConstants.DELETE_LOGICIEL_FAILURE:
      return  Object.assign({}, state, { 
        logDeleted: false,         
        error: action.error
      });
    default:
      return state
  }
}