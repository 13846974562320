export const dashboardConstants = {   
    GET_DETAILS_PDS_REQUEST: 'GET_DETAILS_PDS_REQUEST',
    GET_DETAILS_PDS_SUCCESS: 'GET_DETAILS_PDS_SUCCESS',
    GET_DETAILS_PDS_FAILURE: 'GET_DETAILS_PDS_FAILURE',

    GET_DETAILS_ETAB_REQUEST: 'GET_DETAILS_ETAB_REQUEST',
    GET_DETAILS_ETAB_SUCCESS: 'GET_DETAILS_ETAB_SUCCESS',
    GET_DETAILS_ETAB_FAILURE: 'GET_DETAILS_ETAB_FAILURE',

    GET_DETAILS_COUNTRY_REQUEST: 'GET_DETAILS_COUNTRY_REQUEST',
    GET_DETAILS_COUNTRY_SUCCESS: 'GET_DETAILS_COUNTRY_SUCCESS',
    GET_DETAILS_COUNTRY_FAILURE: 'GET_DETAILS_COUNTRY_FAILURE',
};