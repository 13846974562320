import React from 'react';
import { connect } from 'react-redux';
import { specialiteActions } from '../../actions/specialite.actions';
import { protTypeActions } from '../../actions/protype.actions';
import ButtonAdd from '../../components/shared/ButtonAdd';
import PopupDelete from '../../components/shared/PopupDelete';
import PopupAddEditSpe from '../../components/shared/PopupAddEditSpe';
import { NotificationManager} from 'react-notifications';
import Pagination from '../../components/Pagination';
import CustomSelect from '../../components/shared/CustomSelect';
import PopupConfirmation from '../../components/shared/PopupConfirmation';

class SpecialitePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            listSpecialite : [],
            idDelete: -1,
            selectedSpe: {},
            listTypePDS: [],
            typePDS: "Sélectionnez un type de PDS",
            page: 1,
            maxPage: 1,
            maxPerPage: 100,
            speReady: false,
            label: "",
            code: ""
        };

        this.timer = setTimeout(() => {}, 1);
    }

    componentDidMount() {
        this.props.getAllSpecialite({
            page : this.state.page,
            maxPerPage : this.state.maxPerPage
        });
        this.props.getAllProType();
    }

    componentDidUpdate(prevProps, prevState){

        if((this.props.loadingSpecialite !== prevProps.loadingSpecialite) && !this.props.loadingSpecialite){
            if(this.props.specialite !== undefined){
                if(JSON.parse(this.props.specialite).listSpeciality !== null) this.setState({...this.state,
                    listSpecialite : JSON.parse(this.props.specialite).listSpeciality,
                    maxPage: JSON.parse(this.props.specialite).nbrPages,
                    speReady: true
                });
                else this.setState({...this.state,
                    listSpecialite : [],
                    maxPage: 0,
                    speReady: true
                })
            }            
        }

        if((this.props.loadingProType !== prevProps.loadingProType) && !this.props.loadingProType){
            if((this.props.protype !== "null") && (this.props.protype !== undefined)){
                this.setState({ listTypePDS : JSON.parse(this.props.protype) });
            }            
        }

        // PAGINATION

        if(this.state.page !== prevState.page){
            this.props.getAllSpecialite({
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                protype: this.state.idTypePDS,
                label: this.state.label,
                code: this.state.code
            });
        }

        // FILTRES

        if(this.state.idTypePDS !== prevState.idTypePDS) {                
            this.props.getAllSpecialite({
                page: 1,
                maxPerPage: this.state.maxPerPage,
                protype: this.state.idTypePDS,
                label: this.state.label,
                code: this.state.code
            });
            this.setState({ speReady: false });
        }

        // NOTFICATIONS

        if((this.props.spePosted !== prevProps.spePosted) && this.props.spePosted) {
            this.props.getAllSpecialite({
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                protype: this.state.idTypePDS,
                label: this.state.label,
                code: this.state.code
            });
            NotificationManager.success('',"Spécialité ajoutée");
        }

        if((this.props.speEdited !== prevProps.speEdited) && this.props.speEdited) {
            this.props.getAllSpecialite({
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                protype: this.state.idTypePDS,
                label: this.state.label,
                code: this.state.code
            });
            NotificationManager.success('',"Spécialité éditée");
        }

        if((this.props.speDeleted !== prevProps.speDeleted) && this.props.speDeleted) {
            this.props.getAllSpecialite({
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                protype: this.state.idTypePDS,
                label: this.state.label,
                code: this.state.code
            });
            NotificationManager.success('',"Spécialité supprimée");
        }
    }

    onChangePage = (data) => this.setState({ page: data });

    handleFilter = (value, key) => {
        clearTimeout(this.timer);
        this.setState({ [key]: value });
    
        this.timer = setTimeout(() => {
            this.props.getAllSpecialite({
                page: 1,
                maxPerPage: this.state.maxPerPage,
                protype: this.state.idTypePDS,
                label: this.state.label,
                code: this.state.code
            });
            this.setState({ speReady: false });
        }, 1000);
    }

    render() {
        const { listSpecialite } = this.state;

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>  
                        Spécialités  
                        <small>Gestion des spécialités des PDS</small>
                    </h1>
                </section>

                <section className="content spe">
                    
                    <table className="box tab-entete spe">
                        <tbody>
                            <tr className="top">
                                <td>
                                    <div>
                                        <p>Code</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>Label</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>Type de PDS</p>
                                    </div>
                                </td>
                                <td className="last">
                                    <ButtonAdd modal="#modalAddEditSpe" handleAdd={() => this.setState({selectedSpe: {}})}/>
                                </td>
                            </tr>
                            <tr className="bottom">
                                <td>
                                    <div>
                                        <div>
                                            <input 
                                            type="text" 
                                            placeholder="..." 
                                            value={this.state.code} 
                                            onChange={(e) => this.handleFilter(e.target.value, "code")} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <div>
                                            <input 
                                            type="text" 
                                            placeholder="..."
                                            value={this.state.label} 
                                            onChange={(e) => this.handleFilter(e.target.value, "label")} />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <div>
                                            <CustomSelect
                                            defaultText={this.state.typePDS}
                                            optionsList={this.state.listTypePDS}
                                            handleOptionClick={(id, name) => this.setState({
                                                ...this.state,
                                                idTypePDS: id,
                                                typePDS: name
                                            })}
                                            />
                                        </div>
                                    </div>
                                </td>
                                <td>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list spe">
                        <tbody>
                        {listSpecialite.map((spe, i) => {
                            return (
                                <tr key={i} className="list-row">
                                    <td>
                                        {spe.code}
                                    </td>
                                    <td>
                                        {spe.name}
                                    </td>
                                    <td>
                                        {spe.medicalprotype.nameMedicalProType}
                                    </td>
                                    <td className="icons last">
                                        <div
                                        className="icon-btn"
                                        data-toggle="modal"
                                        data-target="#modalAddEditSpe"
                                        onClick={() => this.setState({selectedSpe: spe})}>
                                            <img src="/dist/img/icons/icon-edit.png" alt=""/>
                                        </div>
                                        <div
                                        className="icon-btn"
                                        data-toggle="modal"
                                        data-target="#modalDelete"
                                        onClick={() => this.setState({idDelete: spe.id})}>
                                            <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        {listSpecialite.length === 0 && this.state.speReady &&
                        <tr>
                            <td className="text-center">Aucune spécialité trouvée</td>
                        </tr>
                        }
                        </tbody>
                    </table>

                    {this.state.speReady && this.state.maxPage > 1  && 
                    <Pagination
                        maxPage={this.state.maxPage}
                        page={this.state.page}
                        onChangePage={this.onChangePage}
                    ></Pagination>
                    }

                </section>

                <PopupAddEditSpe
                handleSubmit={(submit) => this.setState({ handleSubmit: submit })}
                addSpe={(spe) => this.props.postSpecialite(spe)}
                spe={this.state.selectedSpe}
                editSpe={(spe) => this.props.editSpecialite(spe)}
                pdsList={this.state.listTypePDS}
                />

                <PopupDelete
                type="spécialité"
                deleteSpe={(id) => this.props.deleteSpecialite(id)}
                id={this.state.idDelete}
                />
                
                <PopupConfirmation message={'Êtes-vous sûr ?'} handleSubmit={this.state.handleSubmit}/>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingSpecialite, specialite, spePosted, speEdited, speDeleted } = state.specialite;
    const { loadingProType, protype } = state.type;
    return { loadingSpecialite, specialite, loadingProType, protype, spePosted, speEdited, speDeleted };
}
const actionCreators = {
    getAllSpecialite: specialiteActions.getAllSpecialite,
    getAllProType: protTypeActions.getAllProType,
    postSpecialite: specialiteActions.postSpecialite,
    editSpecialite: specialiteActions.editSpecialite,
    deleteSpecialite: specialiteActions.deleteSpecialite,
};

const Specialite = connect(mapState, actionCreators)(SpecialitePage);

export default Specialite;