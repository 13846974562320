import { combineReducers } from 'redux';
import { zoneGeo } from './zoneGeo.reducer';
import { secteurs } from './secteurs.reducer';
import { authentification } from './authentification.reducer';
import { type } from './protype.reducer';
import { specialite } from './specialite.reducer';
import { holiday } from './holiday.reducer';
import { software } from './logiciel.reducer';
import { typeEtab } from './typeEtab.reducer';
import { users } from './users.reducer';
import { etab } from './etab.reducer';
import { medicalPro } from './medicalPro.reducer';
import { history } from './history.reducer';
import { dashboard } from './dashboard.reducer';
import { loadingBarReducer } from 'react-redux-loading-bar'

const rootReducer = combineReducers({
    zoneGeo,
    secteurs,
    specialite,
    type,
    authentification,
    holiday,
    software,
    typeEtab,
    users,
    etab,
    medicalPro,
    history,
    dashboard,
    loadingBar: loadingBarReducer,
})
export default rootReducer;