import { secteursConstants } from '../constants/secteurs.constants';

export function secteurs(state = {}, action) {
  switch (action.type) {
    case secteursConstants.GET_ALL_COUNTRIES_REQUEST:
      return {
        loading: true,
        countries: {}
      };
    case secteursConstants.GET_ALL_COUNTRIES_SUCCESS:
      return {
        countries: action.listCountries,
        loading: false
      };
    case secteursConstants.GET_ALL_COUNTRIES_FAILURE:
      return { 
        error: action.error,
        loading: false
      };
    case secteursConstants.GET_BY_COUNTRY_REQUEST:
      return {
        loadingSect: true,
        secteurs: {}
      };
    case secteursConstants.GET_BY_COUNTRY_SUCCESS:
      return {
        secteurs: action.listSecteurs,
        loadingSect: false
      };
    case secteursConstants.GET_BY_COUNTRY_FAILURE:
      return { 
        error: action.error,
        loadingSect: false
      };
    case secteursConstants.POST_SECTEUR_REQUEST:
      return {
        secteurPosted: false
      };
    case secteursConstants.POST_SECTEUR_SUCCESS:
      return {
        secteurPosted: true
      };
    case secteursConstants.POST_SECTEUR_FAILURE:
      return { 
        error: action.error,
        secteurPosted: false
      };
    case secteursConstants.EDIT_SECTEUR_REQUEST:
      return {
        secteurEdited: false
      };
    case secteursConstants.EDIT_SECTEUR_SUCCESS:
      return {
        secteurEdited: true
      };
    case secteursConstants.EDIT_SECTEUR_FAILURE:
      return { 
        error: action.error,
        secteurEdited: false
      };
    case secteursConstants.DELETE_SECTEUR_REQUEST:
      return {
        secteurDeleted: false
      };
    case secteursConstants.DELETE_SECTEUR_SUCCESS:
      return {
        secteurDeleted: true
      };
    case secteursConstants.DELETE_SECTEUR_FAILURE:
      return { 
        error: action.error,
        secteurDeleted: false
      };
    default:
      return state
  }
}