import React from 'react';

class PopupDelete extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            id: -1
        }
    }

    handleSubmit = (e) => {
        if(this.state.id !== -1) {
            e.preventDefault();

            switch(this.props.type) {
                case "laboratoire":
                    this.props.deleteLabo(this.state.id);
                    break;
                case "produit":
                    this.props.deleteProd(this.state.id);
                    break;
                case "secteur":
                    this.props.deleteSecteur(this.state.id);
                    break;
                case "utilisateur":
                    this.props.deleteUser(this.state.id);
                    break;
                case "réseau":
                    this.props.deleteReseau(this.state.id);
                    break;
                case "resProd":
                    this.props.deleteResProd(this.state.id);
                    break;
                case "jour férié":
                    this.props.deleteHoliday(this.state.id);
                    break;
                case "spécialité":
                    this.props.deleteSpe(this.state.id);
                    break;
                case "type de PDS":
                    this.props.deletePDS(this.state.id);
                    break;
                case "logiciel":
                    this.props.deleteLogiciel(this.state.id);
                    break;
                case "type d'établissement":
                    this.props.deleteTypeEtab(this.state.id);
                    break;
                case "établissement":
                    this.props.deleteEtablissement(this.state.id);
                    break;
                case "professionnel":
                    this.props.deletePro(this.state.id);
                    break;
                case "zone":
                    this.props.deleteZoneGeo(this.state.id);
                    break;
                default:
                    break;
            }

            this.btnElement.click();
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.id !== prevProps.id) this.setState({ id: this.props.id });
    }

    render() {
        return(
            <div className="modal fade in" id="modalDelete" tabIndex="-1" role="dialog" aria-labelledby="modalDeleteLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">Supprimer un{(this.props.type === "spécialité") || (this.props.type === "zone") ? "e" : ""} {this.props.type === "resProd" ? "produit" : this.props.type}</h1>
                        </div>
                        <div className="modal-body">
                            
                        </div>
                        <div className="modal-footer">
                            <button type="submit" className="btn-save" onClick={this.handleSubmit}>
                                Supprimer
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={button => this.btnElement = button}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopupDelete;