import { userConstants } from '../constants/user.constants';
import { userService } from '../services/user.service';

export const userActions = {
    login,
    getUserConnected,
    logout,
    forgotPassword,
    getCountriesUser,
    loginHistory
};

function login(mail, password) {
    return (dispatch) => {
      dispatch(request({ mail, password }));
      userService.login(mail, password)
      .then(
          user => {      
              dispatch(success(user));    
          },
          error => {
              dispatch(failure(error.toString()));
          }
      );
    };
    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user : user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function getUserConnected() {
    return (dispatch) => {
      dispatch(request());
      userService.getUserConnected()
      .then(
          userConnected => {      
              dispatch(success(userConnected));    
          },
          error => {
              dispatch(failure(error.toString()));
          }
      );
    };
    function request(userConnected) { return { type: userConstants.GET_USER_CONNECTED_REQUEST, userConnected } }
    function success(userConnected) { return { type: userConstants.GET_USER_CONNECTED_SUCCESS, userConnected : userConnected } }
    function failure(error) { return { type: userConstants.GET_USER_CONNECTED_FAILURE, error } }
}

function loginHistory() {
    return (dispatch) => {
      dispatch(request());
      userService.loginHistory()
      .then(
          user => {      
              dispatch(success(user));    
          },
          error => {
              dispatch(failure(error.toString()));
          }
      );
    };
    function request(user) { return { type: userConstants.LOGIN_HISTORY_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_HISTORY_SUCCESS, user : user } }
    function failure(error) { return { type: userConstants.LOGIN_HISTORY_FAILURE, error } }
}

function forgotPassword(email){
    return (dispatch) => {

        dispatch(request(email));
        
    (async () => {
        userService.forgotPassword(email)
            .then(
                response => {
                    dispatch(success(response));                   
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
        })();
    };

    function request(email) { return { type: userConstants.FORGOT_REQUEST, email } }
    function success(response) { return { type: userConstants.FORGOT_SUCCESS, response: response } }
    function failure(error) { return { type: userConstants.FORGOT_FAILURE, error } }
}

function getCountriesUser() {
    return (dispatch) => {
      dispatch(request());
      userService.getCountriesUser()
      .then(
            countriesUser => {      
              dispatch(success(countriesUser));    
          },
          error => {
              dispatch(failure(error.toString()));
          }
      );
    };
    function request() { return { type: userConstants.GET_COUNTRIES_USER_REQUEST } }
    function success(countriesUser) { return { type: userConstants.GET_COUNTRIES_USER_SUCCESS, countriesUser } }
    function failure(error) { return { type: userConstants.GET_COUNTRIES_USER_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}

