import { config } from '../constants/app.constants';
import { authHeader } from '../helpers/auth-header';
import axios from 'axios';

export const holidayService = {
    getHolidays,
    postHoliday,
    deleteHoliday
};

function getHolidays(idCountry) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/holiday/countries/'+idCountry, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listHoliday));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function postHoliday(idCountry, date, desc) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/holiday', {
                    "idArea": idCountry,
                    "name": desc,
                    "date": date
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteHoliday(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/holiday/'+id, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}