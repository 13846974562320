export const medicalProConstants = {   
    GET_MEDICAL_PRO_REQUEST: 'GET_ALL_MEDICAL_PRO_REQUEST',
    GET_MEDICAL_PRO_SUCCESS: 'GET_ALL_MEDICAL_PRO_SUCCESS',
    GET_MEDICAL_PRO_FAILURE: 'GET_ALL_MEDICAL_PRO_FAILURE',

    POST_MEDICAL_PRO_REQUEST: 'POST_MEDICAL_PRO_REQUEST',
    POST_MEDICAL_PRO_SUCCESS: 'POST_MEDICAL_PRO_SUCCESS',
    POST_MEDICAL_PRO_FAILURE: 'POST_MEDICAL_PRO_FAILURE',

    EDIT_MEDICAL_PRO_REQUEST: 'EDIT_MEDICAL_PRO_REQUEST',
    EDIT_MEDICAL_PRO_SUCCESS: 'EDIT_MEDICAL_PRO_SUCCESS',
    EDIT_MEDICAL_PRO_FAILURE: 'EDIT_MEDICAL_PRO_FAILURE',

    DELETE_MEDICAL_PRO_REQUEST: 'DELETE_MEDICAL_PRO_REQUEST',
    DELETE_MEDICAL_PRO_SUCCESS: 'DELETE_MEDICAL_PRO_SUCCESS',
    DELETE_MEDICAL_PRO_FAILURE: 'DELETE_MEDICAL_PRO_FAILURE',

    VALIDATE_MEDICAL_PRO_REQUEST: 'VALIDATE_MEDICAL_PRO_REQUEST',
    VALIDATE_MEDICAL_PRO_SUCCESS: 'VALIDATE_MEDICAL_PRO_SUCCESS',
    VALIDATE_MEDICAL_PRO_FAILURE: 'VALIDATE_MEDICAL_PRO_FAILURE',

    UNVALIDATE_MEDICAL_PRO_REQUEST: 'UNVALIDATE_MEDICAL_PRO_REQUEST',
    UNVALIDATE_MEDICAL_PRO_SUCCESS: 'UNVALIDATE_MEDICAL_PRO_SUCCESS',
    UNVALIDATE_MEDICAL_PRO_FAILURE: 'UNVALIDATE_MEDICAL_PRO_FAILURE',

    POST_ETAB_PRO_REQUEST: 'POST_ETAB_PRO_REQUEST',     // ETAB
    POST_ETAB_PRO_SUCCESS: 'POST_ETAB_PRO_SUCCESS',
    POST_ETAB_PRO_FAILURE: 'POST_ETAB_PRO_FAILURE',

    EDIT_ETAB_PRO_REQUEST: 'EDIT_ETAB_PRO_REQUEST',
    EDIT_ETAB_PRO_SUCCESS: 'EDIT_ETAB_PRO_SUCCESS',
    EDIT_ETAB_PRO_FAILURE: 'EDIT_ETAB_PRO_FAILURE',

    DISABLE_ETAB_PRO_REQUEST: 'DISABLE_ETAB_PRO_REQUEST',
    DISABLE_ETAB_PRO_SUCCESS: 'DISABLE_ETAB_PRO_SUCCESS',
    DISABLE_ETAB_PRO_FAILURE: 'DISABLE_ETAB_PRO_FAILURE',

    DELETE_ETAB_PRO_REQUEST: 'DELETE_ETAB_PRO_REQUEST',
    DELETE_ETAB_PRO_SUCCESS: 'DELETE_ETAB_PRO_SUCCESS',
    DELETE_ETAB_PRO_FAILURE: 'DELETE_ETAB_PRO_FAILURE',

    LINK_PROS_REQUEST: 'LINK_PROS_REQUEST',     // LINK
    LINK_PROS_SUCCESS: 'LINK_PROS_SUCCESS',
    LINK_PROS_FAILURE: 'LINK_PROS_FAILURE',

    GET_ONE_PRO_REQUEST: 'GET_ONE_PRO_REQUEST',     // ONE
    GET_ONE_PRO_SUCCESS: 'GET_ONE_PRO_SUCCESS',
    GET_ONE_PRO_FAILURE: 'GET_ONE_PRO_FAILURE',

    GET_SPE_PRO_REQUEST: 'GET_SPE_PRO_REQUEST',     // SPE
    GET_SPE_PRO_SUCCESS: 'GET_SPE_PRO_SUCCESS',
    GET_SPE_PRO_FAILURE: 'GET_SPE_PRO_FAILURE',

    POST_SPE_PRO_REQUEST: 'POST_SPE_PRO_REQUEST',
    POST_SPE_PRO_SUCCESS: 'POST_SPE_PRO_SUCCESS',
    POST_SPE_PRO_FAILURE: 'POST_SPE_PRO_FAILURE',

    DELETE_SPE_PRO_REQUEST: 'DELETE_SPE_PRO_REQUEST',
    DELETE_SPE_PRO_SUCCESS: 'DELETE_SPE_PRO_SUCCESS',
    DELETE_SPE_PRO_FAILURE: 'DELETE_SPE_PRO_FAILURE',

    DISABLE_SPE_PRO_REQUEST: 'DISABLE_SPE_PRO_REQUEST',
    DISABLE_SPE_PRO_SUCCESS: 'DISABLE_SPE_PRO_SUCCESS',
    DISABLE_SPE_PRO_FAILURE: 'DISABLE_SPE_PRO_FAILURE',

    GET_LOG_PRO_REQUEST: 'GET_LOG_PRO_REQUEST',     // LOG
    GET_LOG_PRO_SUCCESS: 'GET_LOG_PRO_SUCCESS',
    GET_LOG_PRO_FAILURE: 'GET_LOG_PRO_FAILURE',

    POST_LOG_PRO_REQUEST: 'POST_LOG_PRO_REQUEST',
    POST_LOG_PRO_SUCCESS: 'POST_LOG_PRO_SUCCESS',
    POST_LOG_PRO_FAILURE: 'POST_LOG_PRO_FAILURE',

    DELETE_LOG_PRO_REQUEST: 'DELETE_LOG_PRO_REQUEST',
    DELETE_LOG_PRO_SUCCESS: 'DELETE_LOG_PRO_SUCCESS',
    DELETE_LOG_PRO_FAILURE: 'DELETE_LOG_PRO_FAILURE',

    DISABLE_LOG_PRO_REQUEST: 'DISABLE_LOG_PRO_REQUEST',
    DISABLE_LOG_PRO_SUCCESS: 'DISABLE_LOG_PRO_SUCCESS',
    DISABLE_LOG_PRO_FAILURE: 'DISABLE_LOG_PRO_FAILURE',

    GET_ALL_LOG_REQUEST: 'GET_ALL_LOG_REQUEST',
    GET_ALL_LOG_SUCCESS: 'GET_ALL_LOG_SUCCESS',
    GET_ALL_LOG_FAILURE: 'GET_ALL_LOG_FAILURE',

    GET_HISTO_PRO_REQUEST: 'GET_HISTO_PRO_REQUEST',
    GET_HISTO_PRO_SUCCESS: 'GET_HISTO_PRO_SUCCESS',
    GET_HISTO_PRO_FAILURE: 'GET_HISTO_PRO_FAILURE',

    GET_VERFIF_PRO_REQUEST: 'GET_VERFIF_PRO_REQUEST',
    GET_VERFIF_PRO_SUCCESS: 'GET_VERFIF_PRO_SUCCESS',
    GET_VERFIF_PRO_FAILURE: 'GET_VERFIF_PRO_FAILURE',
    
    GET_SPE_TYPE_REQUEST: 'GET_SPE_TYPE_REQUEST',
    GET_SPE_TYPE_SUCCESS: 'GET_SPE_TYPE_SUCCESS',
    GET_SPE_TYPE_FAILURE: 'GET_SPE_TYPE_FAILURE',
    
    GET_PRO_INVALIDATE_REQUEST: 'GET_PRO_INVALIDATE_REQUEST',
    GET_PRO_INVALIDATE_SUCCESS: 'GET_PRO_INVALIDATE_SUCCESS',
    GET_PRO_INVALIDATE_FAILURE: 'GET_PRO_INVALIDATE_FAILURE',
};