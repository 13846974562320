export const protTypeConstants = {   
    PRO_TYPE_REQUEST: 'PRO_TYPE_GETALL_REQUEST',
    PRO_TYPE_SUCCESS: 'PRO_TYPE_GETALL_SUCCESS',
    PRO_TYPE_FAILURE: 'PRO_TYPE_GETALL_FAILURE',

    POST_PRO_TYPE_REQUEST: 'POST_PRO_TYPE_REQUEST',
    POST_PRO_TYPE_SUCCESS: 'POST_PRO_TYPE_SUCCESS',
    POST_PRO_TYPE_FAILURE: 'POST_PRO_TYPE_FAILURE',

    EDIT_PRO_TYPE_REQUEST: 'EDIT_PRO_TYPE_REQUEST',
    EDIT_PRO_TYPE_SUCCESS: 'EDIT_PRO_TYPE_SUCCESS',
    EDIT_PRO_TYPE_FAILURE: 'EDIT_PRO_TYPE_FAILURE',

    DELETE_PRO_TYPE_REQUEST: 'DELETE_PRO_TYPE_REQUEST',
    DELETE_PRO_TYPE_SUCCESS: 'DELETE_PRO_TYPE_SUCCESS',
    DELETE_PRO_TYPE_FAILURE: 'DELETE_PRO_TYPE_FAILURE',
};