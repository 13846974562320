import React from 'react';
import { connect } from 'react-redux';
import { dashboardActions } from '../actions/dashboard.actions';
import { PieChart, Pie, Cell, Tooltip, Legend, BarChart, Bar, XAxis, YAxis, CartesianGrid, ResponsiveContainer } from 'recharts';

class DashboardPage extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            detailsPDS: {},
            detailsInstitution: [],
            detailsCountryPDS: [],
            detailsCountryEtab: [],
            pieData: [
                {
                    "name": "CHU",
                    "value": 68.85
                },
                {
                    "name": "Centre de santé",
                    "value": 7.91
                },
                {
                    "name": "Clinique",
                    "value": 6.85
                },
                {
                    "name": "Infirmerie",
                    "value": 6.14
                },
                {
                    "name": "Cabinet dentaire",
                    "value": 10.25
                }
            ],
            pieData2: [
                {
                    "name": "Algérie",
                    "value": 68.85
                },
                {
                    "name": "Bénin",
                    "value": 7.91
                },
                {
                    "name": "Burkina",
                    "value": 6.85
                },
                {
                    "name": "Cameroun",
                    "value": 6.14
                },
                {
                    "name": "Congo",
                    "value": 10.25
                }
            ],
            barData: [
                {name: 'Algérie', Total: 4000, "Géolocalisés": 2400, amt: 2400},
                {name: 'Bénin', Total: 3000, "Géolocalisés": 1398, amt: 2210},
                {name: 'Burkina', Total: 2000, "Géolocalisés": 9800, amt: 2290},
                {name: 'Cameroun', Total: 2780, "Géolocalisés": 3908, amt: 2000},
                {name: 'Congo', Total: 1890, "Géolocalisés": 4800, amt: 2181},
                {name: 'Gabon', Total: 2390, "Géolocalisés": 3800, amt: 2500},
                {name: 'Guinée', Total: 3490, "Géolocalisés": 4300, amt: 2100},
            ]
        }
    }

    componentDidMount() {
        this.props.getDashPDS();
        this.props.getDashEtab();
        this.props.getDashCountry();
    }

    componentDidUpdate(prevProps) {
        if((this.props.loadingDetailsPDS !== prevProps.loadingDetailsPDS) && !this.props.loadingDetailsPDS) {
            if((this.props.detailsPDS !== undefined) && (this.props.detailsPDS !== "null")){
                this.setState({ detailsPDS: JSON.parse(this.props.detailsPDS) });
            }
            if(this.props.detailsPDS === "null") this.setState({ detailsPDS: {} });
        }

        if((this.props.loadingDetailsInstitution !== prevProps.loadingDetailsInstitution) && !this.props.loadingDetailsInstitution) {
            if((this.props.detailsInstitution !== undefined) && (this.props.detailsInstitution !== "null")){
                this.setState({
                    detailsInstitution: JSON.parse(this.props.detailsInstitution).map((etab, i) => ({
                        "name": etab.type,
                        "value": etab.nbrInstitutions
                    }))
                });
            }
            if(this.props.detailsInstitution === "null") this.setState({ detailsInstitution: [] });
        }

        if((this.props.loadingDetailsCountry !== prevProps.loadingDetailsCountry) && !this.props.loadingDetailsCountry) {
            if((this.props.detailsCountry !== undefined) && (this.props.detailsCountry !== "null")){
                this.setState({
                    ...this.state,
                    detailsCountryPDS: JSON.parse(this.props.detailsCountry).map((c, i) => ({
                        "name": c.name,
                        "value": c.nbrMedicalPro
                    })),
                    detailsCountryEtab: JSON.parse(this.props.detailsCountry).map((c, i) => ({
                        "name": c.name,
                        "Total": c.nbrInstitutions,
                        "Géolocalisés": c.nbrInstitutionsGeolocalisee
                    }))
                });
            }
            if(this.props.detailsCountry === "null") {
                this.setState({
                    ...this.state,
                    detailsCountryPDS: [],
                    detailsCountryEtab: []
                });
            }
        }
    }

    COLORS = [
        '#97bbcd', 
        '#f7464a', 
        '#46bfbd', 
        '#fdb45c', 
        '#4d5360',
        '#dcdcdc',
        '#949fb1',
        '#d796ad',
        '#b92d9c',
        '#e74856',
        '#f1600d',
        '#d80b8f',
        '#6acdf1',
        '#799af7',
        '#9c0fd9',
        '#e5f31b',
        '#f1243f',
        '#526866',
        '#2097e8',
        '#0ca780',
        '#af1ec1',
        '#2a8f6f',
        '#477f73',
        '#fe0256',
        '#ab4839',
        '#5bdcad',
        '#fab14e',
        '#874426',
        '#08c2a3',
        '#b20e8c',
        '#1e26d5',
        '#5693d6'
    ];

    CustomTooltip = ({ active, payload, label }) => {
        if (active) return (
            <div className="custom-tooltip" style={{ backgroundColor: '#000', padding: '2px 5px', opacity: 0.8, borderRadius: 5 }}>
                <label style={{margin: 0, color: "#fff"}}>{`${payload[0].name} : ${payload[0].value}`}</label>
            </div>
        );

        return null;
    };

    render() {
        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Tableau de bord
                        <small>Chiffres clés et statistiques</small>
                    </h1>
                </section>
            
                <section className="content dashboard">

                    <div className="row first">
                        <div className="col-md-5">
                            <div className="box info dashboard">
                                <p>Répartission des établissements par type</p>
                                <ResponsiveContainer width="100%" height={500}>
                                    <PieChart>
                                        <Pie data={this.state.detailsInstitution} color="#000000" dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8" >
                                            {this.state.detailsInstitution.map((entry, index) => <Cell key={`cell-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)}
                                        </Pie>
                                        <Tooltip content={<this.CustomTooltip />} />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="info-box">
                                <span className="info-box-icon bg-green"><i className="fa fa-user-md"></i></span>

                                <div className="info-box-content">
                                    <span className="info-box-text">Nombre total de professionnels de santé</span>
                                    <span className="info-box-number">{this.state.detailsPDS.nbrMedicalPro}</span>

                                    <div className="progress">
                                        <div className="progress-bar" style={{width: `${((this.state.detailsPDS.nbrMedicalProHasInstitute / this.state.detailsPDS.nbrMedicalPro) * 100).toFixed(2)}%`}}></div>
                                    </div>

                                    <span className="progress-description">
                                        dont {this.state.detailsPDS.nbrMedicalProHasInstitute} ({((this.state.detailsPDS.nbrMedicalProHasInstitute / this.state.detailsPDS.nbrMedicalPro) * 100).toFixed(2)}%) ont au moins un lieu de consultation actif
                                    </span>
                                </div>
                            </div>

                            <div className="info-box">
                                <span className="info-box-icon bg-aqua"><i className="fa fa-users"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Nombre total estimé de patients par semaine</span>
                                    <span className="info-box-number">{this.state.detailsPDS.nbrEstimatedPatientsPerWeek}</span>
                                </div>
                            </div>

                            <div className="info-box">
                                <span className="info-box-icon bg-purple"><i className="fa fa-hospital-o"></i></span>
                                <div className="info-box-content">
                                    <span className="info-box-text">Nombre total d'établissements</span>
                                    <span className="info-box-number">{this.state.detailsPDS.nbrInstitutions}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">
                            <div className="box info dashboard">
                                <p>Répartition des établissements par pays</p>
                                <ResponsiveContainer width="100%" height={400}>
                                    <BarChart 
                                    data={this.state.detailsCountryEtab}
                                    margin={{top: 15, right: 30, left: 0, bottom: 5}}
                                    >
                                        <CartesianGrid strokeDasharray="1 1"/>
                                        <XAxis dataKey="name"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Bar dataKey="Total" fill="#97bbcd" />
                                        <Bar dataKey="Géolocalisés" fill="#0073bd" />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="box info dashboard">
                                <p>Répartission des PDS par pays</p>
                                <ResponsiveContainer width="100%" height={400}>
                                    <PieChart>
                                        <Pie data={this.state.detailsCountryPDS} color="#000000" dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8" >
                                            {this.state.detailsCountryPDS.map((entry, index) => <Cell key={`cell2-${index}`} fill={this.COLORS[index % this.COLORS.length]} />)}
                                        </Pie>
                                        <Tooltip content={<this.CustomTooltip />} />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    </div>
                    
                </section>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingDetailsPDS, detailsPDS, loadingDetailsInstitution, detailsInstitution, loadingDetailsCountry, detailsCountry } = state.dashboard;
    return { loadingDetailsPDS, detailsPDS, loadingDetailsInstitution, detailsInstitution, loadingDetailsCountry, detailsCountry };
}
const actionCreators = {
    getDashPDS: dashboardActions.getDashPDS,
    getDashEtab: dashboardActions.getDashEtab,
    getDashCountry: dashboardActions.getDashCountry,
};

const Dashboard = connect(mapState, actionCreators)(DashboardPage);

export default Dashboard