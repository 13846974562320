export const usersConstants = {   
    GET_ALL_USERS_REQUEST: 'GET_ALL_USERS_REQUEST',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    GET_ALL_USERS_FAILURE: 'GET_ALL_USERS_FAILURE',

    POST_USER_REQUEST: 'POST_USER_REQUEST',
    POST_USER_SUCCESS: 'POST_USER_SUCCESS',
    POST_USER_FAILURE: 'POST_USER_FAILURE',

    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',

    DISABLE_USER_REQUEST: 'DISABLE_USER_REQUEST',
    DISABLE_USER_SUCCESS: 'DISABLE_USER_SUCCESS',
    DISABLE_USER_FAILURE: 'DISABLE_USER_FAILURE',
};