import { specialiteConstants } from '../constants/specialite.constants';
import { specialiteService } from '../services/specialite.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const specialiteActions = {
    getAllSpecialite,
    postSpecialite,
    editSpecialite,
    deleteSpecialite
};

function getAllSpecialite(data) {

    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());

        specialiteService.getAllSpecialite(data)
            .then(
                specialite => {      
                    dispatch(success(specialite));  
                    dispatch(hideLoading());  
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: specialiteConstants.SPECIALITE_REQUEST} }
    function success(specialite) { return { type: specialiteConstants.SPECIALITE_SUCCESS, specialite } }
    function failure(error) { return { type: specialiteConstants.SPECIALITE_FAILURE, error } }
}

function postSpecialite(spe) {

    return (dispatch) => {

        dispatch(request());

        specialiteService.postSpecialite(spe)
            .then(
                posted => {      
                    dispatch(success(posted));    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: specialiteConstants.POST_SPECIALITE_REQUEST} }
    function success(posted) { return { type: specialiteConstants.POST_SPECIALITE_SUCCESS, posted } }
    function failure(error) { return { type: specialiteConstants.POST_SPECIALITE_FAILURE, error } }
}

function editSpecialite(spe) {

    return (dispatch) => {

        dispatch(request());

        specialiteService.editSpecialite(spe)
            .then(
                edited => {      
                    dispatch(success(edited));    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: specialiteConstants.EDIT_SPECIALITE_REQUEST} }
    function success(edited) { return { type: specialiteConstants.EDIT_SPECIALITE_SUCCESS, edited } }
    function failure(error) { return { type: specialiteConstants.EDIT_SPECIALITE_FAILURE, error } }
}

function deleteSpecialite(id) {

    return (dispatch) => {

        dispatch(request());

        specialiteService.deleteSpecialite(id)
            .then(
                deleted => {      
                    dispatch(success(deleted));    
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: specialiteConstants.DELETE_SPECIALITE_REQUEST} }
    function success(deleted) { return { type: specialiteConstants.DELETE_SPECIALITE_SUCCESS, deleted } }
    function failure(error) { return { type: specialiteConstants.DELETE_SPECIALITE_FAILURE, error } }
}