export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN_HISTORY_REQUEST: 'USERS_LOGIN_HISTORY_REQUEST',
    LOGIN_HISTORY_SUCCESS: 'USERS_LOGIN_HISTORY_SUCCESS',
    LOGIN_HISTORY_FAILURE: 'USERS_LOGIN_HISTORY_FAILURE',
    
    GET_USER_CONNECTED_REQUEST: 'GET_USER_CONNECTED_REQUEST',
    GET_USER_CONNECTED_SUCCESS: 'GET_USER_CONNECTED_SUCCESS',
    GET_USER_CONNECTED_FAILURE: 'GET_USER_CONNECTED_FAILURE',
    
    GET_COUNTRIES_USER_REQUEST: 'GET_COUNTRIES_USER_REQUEST',
    GET_COUNTRIES_USER_SUCCESS: 'GET_COUNTRIES_USER_SUCCESS',
    GET_COUNTRIES_USER_FAILURE: 'GET_COUNTRIES_USER_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT', 
}   