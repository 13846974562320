import React from 'react';
import CustomSelect from '../shared/CustomSelect';

class PopupAddJourFerie extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            idCountry: this.props.country.id,
            date: "",
            desc: ""
        }
    }

    componentDidUpdate(prevProps) {
        if((this.props.country !== prevProps.country) && this.props.country.id !== undefined) {
            this.setState({idCountry: this.props.country.id});
        }
    }

    handleSubmit = (e) => {
        if((this.state.desc !== "") && (this.state.idCountry !== -1) && (this.state.date !== "")) {
            e.preventDefault();
            this.props.addJourFerie(this.state.idCountry, new Date(this.state.date).toJSON(), this.state.desc);
            this.btnElement.click();
        }
    }

    render() {
        return(
            <div className="modal fade in" id="modalAddJourFerie" tabIndex="-1" role="dialog" aria-labelledby="modalAddJourFerieLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">Ajouter un jour férié</h1>
                        </div>
                        <div className="modal-body">
                            <form id="formAjout">
                                <label>Pays</label>
                                <CustomSelect
                                defaultText={this.props.country.name}
                                optionsList={this.props.countryList}
                                handleOptionClick={(id, name) => this.setState({idCountry: id})}
                                />

                                <label>Date</label>
                                <input
                                type="date"
                                value={this.state.date}
                                onChange={(e) => this.setState({date: e.target.value})}
                                required
                                />

                                <label>Description</label>
                                <input type="text" required value={this.state.desc} onChange={(e) => this.setState({desc: e.target.value})}/>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" form="formAjout" className="btn-save" onClick={this.handleSubmit}>
                                <img src="/dist/img/icons/icon-save.png" alt=""/>
                                Sauvegarder
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            onClick={() => this.setState({
                                desc: "",
                                date: ""
                            })}
                            ref={button => this.btnElement = button}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopupAddJourFerie;