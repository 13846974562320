import React from 'react';
import { connect } from 'react-redux';
import { logicielActions } from '../../actions/logiciel.actions';
import ButtonAdd from '../../components/shared/ButtonAdd';
import PopupDelete from '../../components/shared/PopupDelete';
import PopupAddEditLog from '../../components/shared/PopupAddEditLog';
import { NotificationManager} from 'react-notifications';
import PopupConfirmation from '../../components/shared/PopupConfirmation';

class LogicielsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idDelete: -1,
            selectedLog: {},
            listLogiciels: []
        }
    }

    componentDidMount() {
        this.props.getAllLogiciels();
    }

    componentDidUpdate(prevProps){
        if((this.props.loadingLogiciel !== prevProps.loadingLogiciel) && !this.props.loadingLogiciel){
            if((this.props.logiciel !== "null") && (this.props.logiciel !== undefined)){
                this.setState({ listLogiciels : JSON.parse(this.props.logiciel) });
            }   
            if(this.props.logiciel === "null") this.setState({ listLogiciels: [] });    
        }

        if((this.props.logPosted !== prevProps.logPosted) && this.props.logPosted) {
            this.props.getAllLogiciels();
            NotificationManager.success('',"Logiciel ajouté");
        }

        if((this.props.logEdited !== prevProps.logEdited) && this.props.logEdited) {
            this.props.getAllLogiciels();
            NotificationManager.success('',"Logiciel éditié");
        }

        if((this.props.logDeleted !== prevProps.logDeleted) && this.props.logDeleted) {
            this.props.getAllLogiciels();
            NotificationManager.success('',"Logiciel supprimé");
        }
    }

    render() {
        const { listLogiciels } = this.state;

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>  
                        Logiciels  
                        <small>Gestion des logiciels des PDS</small>
                    </h1>
                </section>

                <section className="content logiciel">
                    
                    <table className="box tab-entete logiciel">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>Label</p>
                                    </div>
                                </td>
                                <td className="last">
                                    <ButtonAdd modal="#modalAddEditLog" handleAdd={() => this.setState({selectedLog: {}})}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list logiciel">
                        <tbody>
                        {listLogiciels.map((log, i) => {
                            return (
                                <tr key={i} className="list-row">
                                    <td>
                                        {log.name}
                                    </td>
                                    <td className="icons last">
                                        <div
                                        className="icon-btn"
                                        data-toggle="modal"
                                        data-target="#modalAddEditLog"
                                        onClick={() => this.setState({selectedLog: log})}>
                                            <img src="/dist/img/icons/icon-edit.png" alt=""/>
                                        </div>
                                        <div
                                        className="icon-btn"
                                        data-toggle="modal"
                                        data-target="#modalDelete"
                                        onClick={() => this.setState({idDelete: log.id})}>
                                            <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        {listLogiciels.length === 0 &&
                            <tr className="list-row">
                                <td>Aucun logiciel trouvé</td>
                            </tr>
                        }
                        </tbody>
                    </table>

                </section>

                <PopupAddEditLog
                handleSubmit={(submit) => this.setState({ handleSubmit: submit })}
                addLogiciel={(log) => this.props.postLogiciel(log)}
                logiciel={this.state.selectedLog}
                editLogiciel={(log) => this.props.editLogiciel(log)}
                />

                <PopupDelete
                type="logiciel"
                deleteLogiciel={(id) => this.props.deleteLogiciel(id)}
                id={this.state.idDelete}
                />
                
                <PopupConfirmation message={'Êtes-vous sûr ?'} handleSubmit={this.state.handleSubmit}/>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingLogiciel, logiciel, logPosted, logEdited, logDeleted } = state.software;
    return { loadingLogiciel, logiciel, logPosted, logEdited, logDeleted };
}
const actionCreators = {
    getAllLogiciels: logicielActions.getAllLogiciels,
    postLogiciel: logicielActions.postLogiciel,
    editLogiciel: logicielActions.editLogiciel,
    deleteLogiciel: logicielActions.deleteLogiciel,
};

const Logiciels = connect(mapState, actionCreators)(LogicielsPage);

export default Logiciels;