import { protTypeConstants } from '../constants/protype.constants';

export function type(state = {}, action) {
  switch (action.type) {
    case protTypeConstants.PRO_TYPE_REQUEST:
      return  Object.assign({}, state, {
        loadingProType: true,
        loadProType: false,
      });
    case protTypeConstants.PRO_TYPE_SUCCESS:
      return  Object.assign({}, state, {
        loadingProType: false,
        loadProType: true,
        protype: action.protype
      });
    case protTypeConstants.PRO_TYPE_FAILURE:
      return  Object.assign({}, state, {          
        error: action.error
      });
    case protTypeConstants.POST_PRO_TYPE_REQUEST:
      return  Object.assign({}, state, {
        pdsPosted: false,
      });
    case protTypeConstants.POST_PRO_TYPE_SUCCESS:
      return  Object.assign({}, state, {
        pdsPosted: true,
      });
    case protTypeConstants.POST_PRO_TYPE_FAILURE:
      return  Object.assign({}, state, { 
        pdsPosted: false,         
        error: action.error
      });
    case protTypeConstants.EDIT_PRO_TYPE_REQUEST:
      return  Object.assign({}, state, {
        pdsEdited: false,
      });
    case protTypeConstants.EDIT_PRO_TYPE_SUCCESS:
      return  Object.assign({}, state, {
        pdsEdited: true,
      });
    case protTypeConstants.EDIT_PRO_TYPE_FAILURE:
      return  Object.assign({}, state, { 
        pdsEdited: false,         
        error: action.error
      });
    case protTypeConstants.DELETE_PRO_TYPE_REQUEST:
      return  Object.assign({}, state, {
        pdsDeleted: false,
      });
    case protTypeConstants.DELETE_PRO_TYPE_SUCCESS:
      return  Object.assign({}, state, {
        pdsDeleted: true,
      });
    case protTypeConstants.DELETE_PRO_TYPE_FAILURE:
      return  Object.assign({}, state, { 
        pdsDeleted: false,         
        error: action.error
      });
    default:
      return state
  }
}