import React from 'react';

class PopupConfirmation extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="modal fade in" id="modalConfirmation"
            tabIndex="-1" role="dialog" aria-labelledby="modalEditLabel" animate="animate">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                                <div>
                                    <div className="modal-header">
                                        <h1 className="modal-title" id="myModalLabel">{this.props.message}</h1>
                                    </div>
                                    <div className="modal-footer">
                                        <button type="submit" form="formAjout" className="btn-save" onClick={(e) => {
                                            this.props.handleSubmit(e);
                                            this.btnElement.click();
                                        }}>
                                            <img src="/dist/img/icons/icon-save.png" alt=""/>
                                            Sauvegarder
                                        </button>
                                        <button
                                        type="button"
                                        className="btn-cancel"
                                        data-dismiss="modal"
                                        ref={button => this.btnElement = button}
                                        >Annuler</button>
                                    </div>
                                </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default PopupConfirmation;