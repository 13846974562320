import { config } from '../constants/app.constants';
import { authHeader } from '../helpers/auth-header';
import axios from 'axios';

export const etabService = {
    getEtablissements,
    postEtablissement,
    editEtablissement,
    deleteEtablissement,
    enableEtablissement,
    getEtabReleve,
    linkEtabs,
    getAllEtabs,
    verifEtablissement,
    getEtabInvalidate,
    getHistoEtab,
    disableEtablissement
};

function getEtabInvalidate(data) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/institution/'+data+'/invalidate', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getEtablissements(idCountry, data) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                let params = {};
                if(data.linksearch && data.linksearch !== null) params = {
                    linksearch: data.linksearch,
                    idToLink: data.idToLink
                };
                else params = {
                    "page" : data.page,
                    "maxPerPage" : data.maxPerPage,
                    "status" : (data.status && data.status !== null) ? data.status : "",
                    "type" : (data.type && data.type !== undefined) ? data.type : "",
                    "zonegeo" : (data.zonegeo && data.zonegeo !== null) ? data.zonegeo : null,
                    "public" : (data.public && data.public !== null) ? data.public : "",
                    "name" : (data.name && data.name !== null) ? data.name : "",
                    "reference" : (data.reference && data.reference !== null) ? data.reference : "",
                };
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/institution/'+idCountry+'/list', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function verifEtablissement(data){

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/verif-name/institution', {
                    "name": data.name,
                    "page": data.page,
                    "maxPerPage": data.maxPerPage,
                    "id": data.id ? data.id : null,
                    "areaName": data.idCountry
                })
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function postEtablissement(country, etab) {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/institution/'+country, {
                    idInstitutionType: etab.idTypeEtab,
                    name: etab.name,
                    mail: etab.email,
                    phone: etab.tel,
                    faxNumber: etab.fax,
                    isPrivate: etab.priv,
                    withInternetConn: etab.internet,
                    withInsurance: etab.assurance,
                    address1: etab.address1,
                    address2: etab.address2,
                    coordLat: etab.coordLat === "" ? null : etab.coordLat,
                    coordLong: etab.coordLong === "" ? null : etab.coordLong,
                    zonegeo: etab.zoneGeo
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function editEtablissement(etab) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/institution/'+etab.id, {
                    idInstitutionType: etab.idTypeEtab,
                    name: etab.name,
                    mail: etab.email,
                    phone: etab.tel,
                    faxNumber: etab.fax,
                    isPrivate: etab.priv,
                    withInternetConn: etab.internet,
                    withInsurance: etab.assurance,
                    address1: etab.address1,
                    address2: etab.address2,
                    coordLat: etab.coordLat === "" ? null : etab.coordLat,
                    coordLong: etab.coordLong === "" ? null : etab.coordLong,
                    zonegeo: etab.zoneGeo
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteEtablissement(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/institution/'+id+'/delete', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function enableEtablissement(id, isEdit) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/institution/'+id+'/validate', {
                    isEdit: isEdit ? 1 : 0
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function disableEtablissement(id, isEdit) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/institution/'+id+'/invalidate', {
                    isEdit: isEdit ? 1 : 0
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getEtabReleve(idCountry, data) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/institution/'+idCountry+'/releve', {
                    page: data !== undefined ? data.page : 1,
                    maxPerPage: data !== undefined ? data.maxPerPage : 100
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function linkEtabs(id1, id2) {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/institution/'+id1+'/link', {
                    idInstitution2: id2
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getAllEtabs() {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/institution', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listInstitution));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getHistoEtab(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/institution/'+id+'/history', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listHistoInstitution));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}