import React from 'react';
import { connect } from 'react-redux';
import { zoneGeoActions } from '../../actions/zoneGeo.actions';
import CustomSelect from '../../components/shared/CustomSelect';
import PopupDelete from '../../components/shared/PopupDelete';
import PopupAddZoneGeo from '../../components/shared/PopupAddZoneGeo';
import { NotificationManager} from 'react-notifications';

class ZoneGeoPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            country: "",
            idCountry: -1,
            countryList: [],
            zoneGeo: {},
            idDelete: -1,
            selectedZG: {},
            initialInput: ""
        }
    }

    componentDidMount() {
        if(this.props.country !== undefined) {
            this.props.getByCountry(this.props.country);
            this.setState({
                ...this.state,
                country: this.props.country,
                idCountry: this.props.idCountry
            });
        }

        this.props.getAllCountries();
    }

    componentDidUpdate(prevProps) {
        if((this.props.country !== prevProps.country) && (this.props.country !== undefined)) {
            this.props.getByCountry(this.props.country);
            this.setState({
                ...this.state,
                country: this.props.country,
                idCountry: this.props.idCountry
            })
        }

        if((this.props.loading !== prevProps.loading) && !this.props.loading) {
            if(this.props.countries !== undefined) this.setState({ countryList: JSON.parse(this.props.countries) });
        }

        if((this.props.loadingZoneGeo !== prevProps.loadingZoneGeo) && !this.props.loadingZoneGeo) {
            if(this.props.zoneGeo !== undefined) this.setState({ zoneGeo: JSON.parse(this.props.zoneGeo) });
        }

        if((this.props.zonePosted !== prevProps.zonePosted) && this.props.zonePosted) {
            this.props.getByCountry(this.state.country);
            NotificationManager.success('', "Zone géographique ajoutée");
        }
        if((this.props.zoneEdited !== prevProps.zoneEdited) && this.props.zoneEdited) {
            this.props.getByCountry(this.state.country);
            NotificationManager.success('', "Zone géographique éditée");
        }
        if((this.props.zoneDeleted !== prevProps.zoneDeleted) && this.props.zoneDeleted) {
            const index = this.state.countryList.map(c => c.id).indexOf(this.state.idDelete);
            if(index !== -1) {
                const newCountries = this.state.countryList.splice(index, 1);
                this.props.setCountry({
                    name: newCountries[0].name,
                    id: newCountries[0].id
                });
                this.setState({
                    ...this.state,
                    country: newCountries[0].name,
                    idCountry: newCountries[0].id
                })
            } else this.props.getByCountry(this.state.country);
            NotificationManager.success('',"Zone géographique supprimée");
        }
    }

    handleOptionClick = (idCountry, nameCountry) => {
        this.props.getByCountry(nameCountry);
        this.setState({
            ...this.state,
            country: nameCountry,
            idCountry: idCountry
        })
    }

    afficheList = () => {
        const zone = this.state.zoneGeo;
        let table = [];

        Object.keys(zone).map((item, i) => {
            let villes = [];

            Object.keys(zone[item]).map((ville, j) => {
                let communes = [];

                if((ville !== "name" && (ville !== "id"))) {
                    Object.keys(zone[item][ville]).map((com, l) => {
                        let quartiers = [];

                        if((com !== "id") && (com !== "name")) {
                            Object.keys(zone[item][ville][com]).map((quart, m) => {
            
                                if((quart !== "id") && (quart !== "name")) {
                                    quartiers.push(
                                        <ul key={`${item}_${ville}_${com}_q${m}`}>
                                            <li toggled="false">
                                                <input
                                                value={zone[item][ville][com][quart].name}
                                                className="invisible-input"
                                                onKeyDown={(e) => {
                                                    if ((e.key === 'Enter') || (e.keyCode === 13)){
                                                        this.props.editZoneGeo(zone[item][ville][com][quart].id, e.target.value)
                                                        e.target.blur()
                                                    }
                                                }}
                                                onChange={(e) => {
                                                    let t = {...zone}
                                                    t[item][ville][com][quart].name = e.target.value;
                                                    this.setState({zoneGeo: t})
                                                }}
                                                onClick={(e) => e.stopPropagation()}
                                                onFocus={() => this.setState({initialInput: zone[item][ville][com][quart].name})}
                                                onBlur={() => {
                                                    let t = {...zone}
                                                    t[item][ville][com][quart].name = this.state.initialInput;
                                                    this.setState({zoneGeo: t})
                                                }}
                                                />
                                                &nbsp;<span className="small">QUARTIER</span>

                                                <div className="last">
                                                    <div 
                                                    className="bouton delete" 
                                                    data-toggle="modal"
                                                    data-target="#modalDelete"
                                                    onClick={() => this.setState({idDelete: zone[item][ville][com][quart].id})}
                                                    >
                                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    )
                                }
                            });

                            communes.push(
                                <ul key={`${item}_${ville}_c${l}`}>
                                    <li onClick={(e) => quartiers.length > 0 ? this.handleListClick(e, `${item}_${ville}_${com}`) : {}} toggled="false" className={quartiers.length > 0 ? "" : "noQuart"}>
                                        <input
                                        value={zone[item][ville][com].name}
                                        className="invisible-input"
                                        onKeyDown={(e) => {
                                            if ((e.key === 'Enter') || (e.keyCode === 13)){
                                                this.props.editZoneGeo(zone[item][ville][com].id, e.target.value)
                                                e.target.blur()
                                            }
                                        }}
                                        onChange={(e) => {
                                            let t = {...zone}
                                            t[item][ville][com].name = e.target.value;
                                            this.setState({zoneGeo: t})
                                        }}
                                        onClick={(e) => e.stopPropagation()}
                                        onFocus={() => this.setState({initialInput: zone[item][ville][com].name})}
                                        onBlur={() => {
                                            let t = {...zone}
                                            t[item][ville][com].name = this.state.initialInput;
                                            this.setState({zoneGeo: t})
                                        }}
                                        />
                                        <span className="small">COMMUNE</span>

                                        <div className="last">
                                            <div 
                                            className="bouton plus"
                                            data-toggle="modal"
                                            data-target="#modalAddZoneGeo"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.setState({selectedZG: zone[item][ville][com]})
                                            }}
                                            >
                                                <i className="fa fa-plus" aria-hidden="true"></i>
                                            </div>
                                            <div 
                                            className="bouton delete" 
                                            disabled={quartiers.length > 0}
                                            data-toggle={quartiers.length > 0 ? "" : "modal"}
                                            data-target={quartiers.length > 0 ? "" : "#modalDelete"}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                if(quartiers.length <= 0) {
                                                    this.setState({idDelete: zone[item][ville][com].id})
                                                }
                                            }}
                                            >
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </li>
                                    <li className={`quart ${item}_${ville}_${com}`} active="false">{quartiers}</li>
                                </ul>
                            )
                        }
                    });

                    villes.push(
                        <ul key={`${item}_v${j}`}>
                            <li onClick={(e) => communes.length > 0 ? this.handleListClick(e, `${item}_${ville}`) : {}} toggled="false" className={communes.length > 0 ? "" : "noQuart"}>
                                <input
                                value={zone[item][ville].name}
                                className="invisible-input"
                                onKeyDown={(e) => {
                                    if ((e.key === 'Enter') || (e.keyCode === 13)){
                                        this.props.editZoneGeo(zone[item][ville].id, e.target.value)
                                        e.target.blur()
                                    }
                                }}
                                onChange={(e) => {
                                    let t = {...zone}
                                    t[item][ville].name = e.target.value;
                                    this.setState({zoneGeo: t})
                                }}
                                onClick={(e) => e.stopPropagation()}
                                onFocus={() => this.setState({initialInput: zone[item][ville].name})}
                                onBlur={() => {
                                    let t = {...zone}
                                    t[item][ville].name = this.state.initialInput;
                                    this.setState({zoneGeo: t})
                                }}
                                />
                                <span className="small">VILLE</span>

                                <div className="last">
                                    <div 
                                    className="bouton plus"
                                    data-toggle="modal"
                                    data-target="#modalAddZoneGeo"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        this.setState({selectedZG: zone[item][ville]})
                                    }}
                                    >
                                        <i className="fa fa-plus" aria-hidden="true"></i>
                                    </div>
                                    <div 
                                    className="bouton delete" 
                                    disabled={communes.length > 0}
                                    data-toggle={communes.length > 0 ? "" : "modal"}
                                    data-target={communes.length > 0 ? "" : "#modalDelete"}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        if(communes.length <= 0) {
                                            this.setState({idDelete: zone[item][ville].id})
                                        }
                                    }}
                                    >
                                        <i className="fa fa-trash" aria-hidden="true"></i>
                                    </div>
                                </div>
                            </li>
                            <li className={`com ${item}_${ville}`} active="false">{communes}</li>
                        </ul>
                    )
                }
            });

            table.push(
                <ul key={i} className="zone">
                    <li onClick={(e) => villes.length > 0 ? this.handleListClick(e, item) : {}} toggled="false" className={villes.length > 0 ? "" : "noQuart"}>
                        <input
                        value={zone[item].name}
                        className="invisible-input"
                        onKeyDown={(e) => {
                            if ((e.key === 'Enter') || (e.keyCode === 13)){
                                this.props.editZoneGeo(zone[item].id, e.target.value)
                                e.target.blur()
                            }
                        }}
                        onChange={(e) => {
                            let t = {...zone}
                            t[item].name = e.target.value;
                            this.setState({zoneGeo: t})
                        }}
                        onClick={(e) => e.stopPropagation()}
                        onFocus={() => this.setState({initialInput: zone[item].name})}
                        onBlur={() => {
                            let t = {...zone}
                            t[item].name = this.state.initialInput;
                            this.setState({zoneGeo: t})
                        }}
                        />
                        <span className="small">ZONE</span>

                        <div className="last">
                            <div 
                            className="bouton plus"
                            data-toggle="modal"
                            data-target="#modalAddZoneGeo"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.setState({selectedZG: zone[item]})
                            }}
                            >
                                <i className="fa fa-plus" aria-hidden="true"></i>
                            </div>
                            <div 
                            className="bouton delete" 
                            disabled={villes.length > 0}
                            data-toggle={villes.length > 0 ? "" : "modal"}
                            data-target={villes.length > 0 ? "" : "#modalDelete"}
                            onClick={(e) => {
                                e.stopPropagation();
                                if(villes.length <= 0) {
                                    this.setState({idDelete: zone[item].id})
                                }
                            }}
                            >
                                <i className="fa fa-trash" aria-hidden="true"></i>
                            </div>
                        </div>
                    </li>
                    <li className={`ville ${item}`} active="false">{villes}</li>
                </ul>
            )
        });

        return table;
    }

    handleListClick = (e, item) => {
        const obj = document.getElementsByClassName(item)[0];
        const active = obj.getAttribute("active");
        obj.setAttribute("active", active === "true" ? "false" : "true");
        const toggled = e.target.getAttribute('toggled');
        e.target.setAttribute("toggled", toggled === "true" ? "false" : "true");
    }

    render() {
        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Zones géographiques
                        <small>Gestion</small>
                    </h1>
                </section>
            
                <section className="content zoneGeo">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="box tab-entete zoneGeo">
                                <CustomSelect
                                defaultText={this.state.country}
                                optionsList={this.state.countryList}
                                handleOptionClick={this.handleOptionClick}
                                />

                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <button
                                className="btn-add"
                                data-toggle="modal"
                                data-target="#modalAddZoneGeo"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    this.setState({selectedZG: {
                                        id: this.state.idCountry,
                                        name: this.state.country
                                    }})
                                }}
                                >
                                    +
                                </button>
                                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                <i 
                                className="fa fa-trash" 
                                aria-hidden="true"
                                disabled={Object.keys(this.state.zoneGeo).length > 0}
                                data-toggle={Object.keys(this.state.zoneGeo).length > 0 ? "" : "modal"}
                                data-target={Object.keys(this.state.zoneGeo).length > 0 ? "" : "#modalDelete"}
                                onClick={() => Object.keys(this.state.zoneGeo).length <= 0 && this.setState({idDelete: this.state.idCountry})}
                                ></i>
                            </div>
                        </div>
                    </div>

                    <div className="listZoneGeo">
                        {this.afficheList()}
                    </div>

                    {Object.keys(this.state.zoneGeo).length === 0 && !this.props.loadingZoneGeo &&
                    <p>&nbsp; &nbsp; &nbsp; Aucune zone géographique</p>
                    }

                </section>

                <PopupDelete
                type="zone"
                deleteZoneGeo={(id) => this.props.deleteZoneGeo(id)}
                id={this.state.idDelete}
                />

                <PopupAddZoneGeo
                postZoneGeo={(idP, name) => this.props.postZoneGeo(idP, name)}
                zoneParent={this.state.selectedZG}
                zgList={this.state.zoneGeo}
                />

            </section>
        )
    }
}

function mapState(state) {
    const { loading, countries, loadingZoneGeo, zoneGeo, idCountry, country, zonePosted, zoneEdited, zoneDeleted } = state.zoneGeo;
    return { loading, countries, loadingZoneGeo, zoneGeo, idCountry, country, zonePosted, zoneEdited, zoneDeleted };
}
const actionCreators = {
    getAllCountries: zoneGeoActions.getAllCountries,
    getByCountry: zoneGeoActions.getByCountry,
    postZoneGeo: zoneGeoActions.postZoneGeo,
    editZoneGeo: zoneGeoActions.editZoneGeo,
    deleteZoneGeo: zoneGeoActions.deleteZoneGeo,
    setCountry: zoneGeoActions.setCountry,
};

const ZoneGeo = connect(mapState, actionCreators)(ZoneGeoPage);

export default ZoneGeo;