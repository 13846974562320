import { holidayConstants } from '../constants/holiday.constants';

export function holiday(state = {}, action) {
  switch (action.type) {
    case holidayConstants.GET_HOLIDAY_BY_COUNTRY_REQUEST:
      return {
        loadingHolidays: true,
        holidays: {}
      };
    case holidayConstants.GET_HOLIDAY_BY_COUNTRY_SUCCESS:
      return {
        holidays: action.holidays,
        loadingHolidays: false
      };
    case holidayConstants.GET_HOLIDAY_BY_COUNTRY_FAILURE:
      return { 
        error: action.error,
        loadingHolidays: false
      };
    case holidayConstants.DELETE_HOLIDAY_REQUEST:
      return {
        holidayDeleted: false
      };
    case holidayConstants.DELETE_HOLIDAY_SUCCESS:
      return {
        holidayDeleted: true
      };
    case holidayConstants.DELETE_HOLIDAY_FAILURE:
      return { 
        holidayDeleted: false
      };
    case holidayConstants.POST_HOLIDAY_REQUEST:
      return {
        holidayPosted: false
      };
    case holidayConstants.POST_HOLIDAY_SUCCESS:
      return {
        holidayPosted: true
      };
    case holidayConstants.POST_HOLIDAY_FAILURE:
      return { 
        holidayPosted: false
      };
    default:
      return state
  }
}