import React from 'react';
import { connect } from 'react-redux';
import { zoneGeoActions } from '../actions/zoneGeo.actions';
import { protTypeActions } from '../actions/protype.actions';
import { specialiteActions } from '../actions/specialite.actions';
import { logicielActions } from '../actions/logiciel.actions';
import { medicalProActions } from '../actions/medicalPro.actions';
import { etabActions } from '../actions/etab.actions';
import { typeEtabActions } from '../actions/typeEtab.actions';
import ButtonAdd from '../components/shared/ButtonAdd';
import PopupAddEditPro from '../components/shared/PopupAddEditPro';
import ButtonExport from '../components/shared/ButtonExport';
import PopupDelete from '../components/shared/PopupDelete';
import PopupLinkPro from '../components/shared/PopupLinkPro';
import { NotificationManager} from 'react-notifications';
import Pagination from '../components/Pagination';
import CustomSelect from '../components/shared/CustomSelect';
import PopupConfirmation from  '../components/shared/PopupConfirmation';

class ProfessionnelsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idDelete: -1,
            searchListType: "",
            typeList: [],
            selectedPro: {},
            speList: [],
            listLogiciels: [],
            linkPro: {},
            readOnly: false,
            validating: false,
            searchLinkPro : false,
            etabList: [],
            typeEtabList: [],
            prosList: [],
            prosLinkList: [],
            proListReady: false,
            idCountry: localStorage.getItem('country'),
            page: 1,
            maxPage: 1,
            maxPerPage: 100,            
            lastname: "",
            firstname : "",
            reference : "",
            protype : "",
            speciality : "",
            status : "",
            orderKey: "Reference",
            doublon: {
                status : false,
                data : {},
                nbrPage : 0,
                page : 0
            },
            orderByKey:{
                Reference : true,
                LastName : false,
                FirstName : false,
                type : false
            }
        };

        this.timer = setTimeout(() => {}, 1);
    }

    componentDidMount() {
        this.props.getUserCountry();
        if(this.props.idCountry !== undefined) this.props.getProfessionnels(this.props.idCountry, {
            page : this.state.page,
            maxPerPage : this.state.maxPerPage
        });

        this.props.getAllProType();
        this.props.getAllSpecialite();
        this.props.getAllLogiciels();
        this.props.getTypeEtab();
        if(this.props.idCountry !== undefined) this.props.getEtablissements(this.props.idCountry, {
            page: 1,
            maxPerPage: 50,
            name: ""
        });
    }

    componentDidUpdate(prevProps, prevState) {
        
        if((this.props.loadingUserCountry !== prevProps.loadingUserCountry) && !this.props.loadingUserCountry ) {
            if(this.props.userCountry !== undefined && this.props.userCountry !== "null") {
                this.setState({
                    ...this.state,
                    countryList: JSON.parse(this.props.userCountry),
                    idCountry: localStorage.getItem('idCountry'),
                    nameCountry: JSON.parse(this.props.userCountry)[0].name,
                    initIdCountry: JSON.parse(this.props.userCountry)[0].id
                });
                let data = {
                    page: this.state.page,
                    maxPerPage: this.state.maxPerPage,
                    lastname: this.state.lastname,
                    firstname : this.state.firstname,
                    reference : this.state.reference,
                    protype : this.state.protype,
                    speciality : this.state.speciality,
                    status : this.state.status
                }
                this.props.getProfessionnels(localStorage.getItem('idCountry'), data);
                this.props.getEtablissements(localStorage.getItem('idCountry'), {
                    page: 1,
                    maxPerPage: 50,
                    name: ""
                });
                this.setState({ proListReady : false });
            } else if(this.props.userCountry === "null") this.setState({ proListReady: true });
        }
        
        if(this.state.idCountry !== "" && this.state.idCountry !== localStorage.getItem('idCountry')) {
            this.setState({ idCountry: localStorage.getItem('idCountry') });
        }

        if(this.props.loadingVerifPro !== prevProps.loadingVerifPro && !this.props.loadingVerifPro) {
            if(this.props.verif) {
                if(JSON.parse(this.props.verif).similaireMedicalPro !== null) {
                    this.setState({
                        doublon: {
                            status : true,
                            data : JSON.parse(this.props.verif).similaireMedicalPro,
                            nbrPage : JSON.parse(this.props.verif).nbrPages,
                            page : this.state.doublon.page
                        }
                    })
                } else this.setState({
                    doublon: {
                        status : false,
                        data : [],
                        nbrPage : 0,
                        page : 0
                    }
                });
            }
        }

        if((this.props.idCountry !== prevProps.idCountry) && (this.props.idCountry !== undefined)) {
            this.props.getProfessionnels(this.props.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            this.props.getEtablissements(this.props.idCountry, {
                page: 1,
                maxPerPage: 50,
                name: ""
            });
        }

        if(
            (this.state.status !== prevState.status) ||
            (this.state.protype !== prevState.protype)
        ){
            this.props.getProfessionnels(this.state.idCountry, {
                page : 1,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            this.setState({ proListReady: false });
        }
            
        if((this.props.loadingMedicalPros !== prevProps.loadingMedicalPros) && !this.props.loadingMedicalPros) {
            if(this.props.medicalPros !== undefined) {
                if(JSON.parse(this.props.medicalPros).listMedicalPro !== null) {
                    const medicalPro = JSON.parse(this.props.medicalPros).listMedicalPro;
                    this.setState({
                        ...this.state,
                        prosList: (this.state.searchLinkPro) ? this.state.prosList : this.formatedListPro(medicalPro),
                        prosLinkList: (this.state.searchLinkPro) ? this.formatedListPro(medicalPro) : this.state.prosList,
                        maxPage: (this.state.searchLinkPro) ? this.state.maxPage : JSON.parse(this.props.medicalPros).nbrPages,
                        proListReady : true,
                        searchLinkPro : false,
                        selectedPro: Object.keys(this.state.selectedPro).length > 0 && medicalPro !== null ? medicalPro[medicalPro.map((p) => p.Id).indexOf(this.state.selectedPro.Id)] !== undefined ? medicalPro[medicalPro.map((p) => p.Id).indexOf(this.state.selectedPro.Id)] : this.state.selectedPro : this.state.selectedPro
                    });
                } else {
                    this.setState({
                        prosList: (this.state.searchLinkPro) ? this.state.prosList : [],
                        proListReady : true,
                        maxPage: 0,
                        searchLinkPro : false,
                        prosLinkList: (this.state.searchLinkPro) ? [] : this.state.prosList
                    });
                }
            }
        }

        if((this.props.loadingProType !== prevProps.loadingProType) && !this.props.loadingProType) {
            if(this.props.protype !== undefined) {
                let protype = [{id: "Tous", code: "Tous", name: "Tous", status: true, createdAt: "2020-10-29T00:00:00+01:00"}].concat(JSON.parse(this.props.protype))
                this.setState({ typeList: protype });
            }
        }

        if((this.props.loadingSpecialite !== prevProps.loadingSpecialite) && !this.props.loadingSpecialite) {
            if((JSON.parse(this.props.specialite).listSpeciality !== null) && (this.props.specialite !== undefined)){
                this.setState({ speList : JSON.parse(this.props.specialite).listSpeciality });
            }  
        }

        if((this.props.loadingLogiciel !== prevProps.loadingLogiciel) && !this.props.loadingLogiciel){
            if((this.props.logiciel !== "null") && (this.props.logiciel !== undefined)){
                this.setState({ listLogiciels : JSON.parse(this.props.logiciel) });
            }   
            if(this.props.logiciel === "null") this.setState({ listLogiciels: [] });      
        }

        if((this.props.loadingTypeEtab !== prevProps.loadingTypeEtab) && !this.props.loadingTypeEtab) {
            if((this.props.typesEtab !== undefined && (this.props.typesEtab !== "null"))) {
                this.setState({ typeEtabList: JSON.parse(this.props.typesEtab) });
            }
            if(this.props.protype === "null") this.setState({ typeEtabList: [] });
        }

        if((this.props.loadingEtabs !== prevProps.loadingEtabs) && !this.props.loadingEtabs) {
            if(this.props.etabs !== undefined) {
                if(JSON.parse(this.props.etabs).listInstitutionByArea !== null) {
                    this.setState({ etabList: JSON.parse(this.props.etabs).listInstitutionByArea });
                } else this.setState({ etabList: [] });
            }
        }

        if(this.state.page !== prevState.page){
            this.props.getProfessionnels(this.state.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
        }

        // NOTIFICATIONS

        if((this.props.medicalProPosted !== prevProps.medicalProPosted) && this.props.medicalProPosted) {
            this.props.getProfessionnels(this.state.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            NotificationManager.success('', "Professionnel ajouté");
        }

        if((this.props.medicalProEdited !== prevProps.medicalProEdited) && this.props.medicalProEdited) {
            this.props.getProfessionnels(this.state.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            NotificationManager.success('', "Professionnel édité");
        }

        if((this.props.medicalProDeleted !== prevProps.medicalProDeleted) && this.props.medicalProDeleted) {
            this.props.getProfessionnels(this.state.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            NotificationManager.success('', "Professionnel supprimé");
        }

        if((this.props.medicalProValidated !== prevProps.medicalProValidated) && this.props.medicalProValidated) {
            this.props.getProfessionnels(this.state.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            NotificationManager.success('', "Professionnel validé");
        }

        if((this.props.medicalProUnvalidated !== prevProps.medicalProUnvalidated) && this.props.medicalProUnvalidated) {
            this.props.getProfessionnels(this.state.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            NotificationManager.success('', "Professionnel annulé");
        }

        if((this.props.prosLinked !== prevProps.prosLinked) && this.props.prosLinked) {
            this.props.getProfessionnels(this.state.idCountry, {
                page : this.state.page,
                maxPerPage : this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname : this.state.firstname,
                reference : this.state.reference,
                protype : this.state.protype,
                speciality : this.state.speciality,
                status : this.state.status
            });
            NotificationManager.success('', "Professionnels reliés");
        }
    }

    handleOrderByKey(liste, key){
        if(this.getKeyState(key) === false) liste.reverse(); 
        else liste.sort((a, b) =>{
            if(this.state.orderKey && (this.byString(a, this.state.orderKey) !== undefined)){
                if(this.byString(a, this.state.orderKey) < this.byString(b, this.state.orderKey)) return -1;
                if(this.byString(a, this.state.orderKey) > this.byString(b, this.state.orderKey)) return 1;
            }
            return 0;
        });
    }

    formatedListPro = (data) => {        
        if(data !== null && data !== undefined){
            for(let i =0; i < data.length; i++){
                Object.assign(data[i], { specilite: (data[i].MedicalProSpeciality[0] !== undefined) ? data[i].MedicalProSpeciality[0].nameMedicalProSpeciality : "" } )
                Object.assign(data[i], { type: data[i].MedicalProType.nameMedicalProType });
            }
            return data;
        } else return [];
    }

    handlePressFilterbyKey = (key) => this.setState({
        orderByKey: { [key]: !this.getKeyState(key) },
        orderKey: key,
    });

    getKeyState = (key) => {
        let keyState = "";
        if(key === "Reference") {
            keyState = this.state.orderByKey.Reference;
        }else if(key === "LastName"){
            keyState = this.state.orderByKey.LastName;
        }else if(key === "FirstName"){
            keyState = this.state.orderByKey.FirstName;
        }else if(key === "type"){
            keyState = this.state.orderByKey.type;
        }
        return keyState;
    }

    byString = (o, s) =>{
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');

        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                if(s === "Reference" || s === "LastName" || s === "FirstName" || s === "type") o = o[k].toLowerCase();
                else o = o[k];            
            } else return;
        }

        return o;
    }

    generateEtabOptions = () => {
        const etabs = this.state.etabList;
        const types = this.state.typeEtabList
        let table = [];

        types.map((t) => {
            table.push({
                label: t.name,
                options: []
            })
        })

        etabs.map((e) => {
            table[table.map((g) => g.label).indexOf(e.InstitutionType.nameInstitutionType)].options.push(
                {
                    value: e.id,
                    label: e.name,
                    ref: e.reference,
                    ad: e.zonesGeo.addresszoneGeo,
                    isPrivate: e.isPrivate
                }
            )
        });
        return table;
    }

    onChangePage = (data) => this.setState({ page: data });

    searchLinkPro = (value) => { 
        this.setState({ searchLinkPro: true });      
        this.props.getProfessionnels(this.state.idCountry, {
            page : 1,
            maxPerPage : 50,
            linksearch: value,
            idToLink: this.state.linkPro.Id
        });
    }

    handleFilter = (value, key) => {
        clearTimeout(this.timer);
        this.setState({ [key]: value });
    
        this.timer = setTimeout(() => {
            this.props.getProfessionnels(this.state.idCountry, {
                page: 1,
                maxPerPage: this.state.maxPerPage,
                lastname: this.state.lastname,
                firstname: this.state.firstname,
                reference: this.state.reference,
                protype: this.state.protype,
                speciality: this.state.speciality,
                status: this.state.status
            });
            this.setState({ proListReady: false });
        }, 1000);
    }

    render() {
        const { proListReady, maxPage } = this.state;
        const displayProp = (this.state.prosList.length > 0) ? this.state.prosList : [];
        this.handleOrderByKey(displayProp, this.state.orderKey);

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Professionnels
                        <small>Annuaire des PDS</small>
                    </h1>
                </section>
            
                <section className="content">

                    <div className="row professionnels">
                        <div className="col-md-12">
                            <table className="box tab-entete professionnels">
                                <tbody>
                                    <tr>
                                        <td>
                                            <div>
                                                <p>Réf.</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.Reference !== false && this.state.orderKey === "Reference" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("Reference")}/>
                                            </div>
                                        </td>
                                        <td className="LastName">
                                            <div>
                                                <p>Nom</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.LastName !== false && this.state.orderKey === "LastName" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("LastName")}/>
                                            </div>
                                        </td>
                                        <td className="FirstName">
                                            <div>
                                                <p>Prenom</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.FirstName !== false && this.state.orderKey === "FirstName" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("FirstName")}/>
                                            </div>
                                        </td>
                                        <td className="type">                                            
                                            <div>
                                                <p>Type</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.type !== false && this.state.orderKey === "type" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("type")}/>
                                            </div>
                                        </td>
                                        <td className="spe"><div><p>Spécialités</p></div></td>
                                        <td className="stat"><div><p>Statut</p></div></td>
                                        <td className="etab"><div><p>Étab. actifs</p></div></td>
                                        <td className="last">
                                            <ButtonExport />
                                            <ButtonAdd modal="#modalAddEditPro" handleAdd={() => this.setState({
                                                ...this.state,
                                                selectedPro: {},
                                                readOnly: false,
                                                validating: false
                                            })}/>
                                        </td>
                                    </tr>
                                    <tr className="bottom">
                                        <td>
                                            <div>
                                                <div>
                                                    <input 
                                                    type="text" 
                                                    placeholder="..." 
                                                    value={this.state.reference} 
                                                    onChange={(e) => this.handleFilter(e.target.value, "reference")} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>   
                                            <div>
                                                <div>
                                                    <input 
                                                    type="text" 
                                                    placeholder="..." 
                                                    value={this.state.lastname} 
                                                    onChange={(e) => this.handleFilter(e.target.value, "lastname")} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    <input 
                                                    type="text" 
                                                    placeholder="..." 
                                                    value={this.state.firstname} 
                                                    onChange={(e) => this.handleFilter(e.target.value, "firstname")} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    <CustomSelect
                                                    defaultText="Tous"
                                                    optionsList={this.state.typeList}
                                                    handleOptionClick={(id, name) => this.setState({ protype: id !== "Tous" ? id : "" })}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    <input 
                                                    type="text" 
                                                    placeholder="..." 
                                                    value={this.state.speciality} 
                                                    onChange={(e) => this.handleFilter(e.target.value, "speciality")} />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="stat">
                                            <div>
                                                <div>
                                                    <CustomSelect
                                                    defaultText="Tous"
                                                    width={"130px"}
                                                    optionsList={[
                                                        {id: "", name: "Tous"},
                                                        {id: 2, name: "Actif"},
                                                        {id: 0, name: "Non valide"},
                                                        {id: 1, name: "Inactif"}
                                                    ]}
                                                    handleOptionClick={(id, name) => this.setState({ status: id })}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table className="list professionnels">
                                <tbody>
                                    {displayProp.map((pro, i) => {
                                        return (
                                        <tr className="list-row" key={`pro${i}`}>
                                            <td
                                            data-toggle="modal"
                                            data-target="#modalAddEditPro"
                                            onClick={() => this.setState({
                                                ...this.state,
                                                selectedPro: pro,
                                                readOnly: true,
                                                validating: false
                                            })}>
                                                {pro.Reference}
                                            </td>
                                            <td className="LastName uppercase"
                                            data-toggle="modal"
                                            data-target="#modalAddEditPro"
                                            onClick={() => this.setState({
                                                ...this.state,
                                                selectedPro: pro,
                                                readOnly: true,
                                                validating: false
                                            })}>
                                                {pro.LastName}
                                            </td>
                                            <td className="FirstName"
                                            data-toggle="modal"
                                            data-target="#modalAddEditPro"
                                            onClick={() => this.setState({
                                                ...this.state,
                                                selectedPro: pro,
                                                readOnly: true,
                                                validating: false
                                            })}>
                                                {pro.FirstName}
                                            </td>
                                            <td className="type">
                                                {pro.MedicalProType.nameMedicalProType}
                                            </td>
                                            <td className="spe">
                                                {pro.MedicalProSpeciality.map((spe, i) => {
                                                    let endStr = (i+1) !== pro.MedicalProSpeciality.length ? ", " : "";
                                                    return spe.nameMedicalProSpeciality + endStr;
                                                })}
                                            </td>
                                            <td className="planning stat">
                                                <div className={`tag ${pro.Status === 0 ? "warning" : pro.Status === 2 ? "valid" : "alert"}`}>
                                                    <p>
                                                        {pro.Status === 0 ? "Non validé" : pro.Status === 2 ? "Actif" : "Inactif"}
                                                    </p>
                                                </div>
                                            </td>
                                            <td className="etab planning">
                                                <div className={`tag ${pro.MedicalProsInstitutions.filter(e => e.StatusInstitution).length > 0 ? "valid" : "alert"}`}>
                                                    <p>
                                                        {pro.MedicalProsInstitutions.filter(e => e.StatusInstitution).length}
                                                    </p>
                                                </div>
                                            </td>
                                            <td className="icons last">

                                                {/* boutons admin */}
                                                {(this.props.roleUser === "ROLE_ADMIN" || this.props.roleUser === "ROLE_QADMIN") &&
                                                <>
                                                <div
                                                className="icon-btn"
                                                data-toggle="modal"
                                                data-target="#modalAddEditPro"
                                                onClick={() => this.setState({
                                                    ...this.state,
                                                    selectedPro: pro,
                                                    readOnly: pro.Status ? false : true,
                                                    validating: pro.Status ? false : true
                                                })}>
                                                    <img src={`/dist/img/icons/icon-${pro.Status ? "edit" : "valid"}.png`} alt=""/>
                                                </div>
                                                <div
                                                className="icon-btn"
                                                data-toggle="modal"
                                                data-target="#modalLink"
                                                onClick={() => this.setState({linkPro: pro})}>
                                                    <img src="/dist/img/icons/icon-link.png" alt=""/>
                                                </div>
                                                {pro.Status ?
                                                <div
                                                className="icon-btn"
                                                data-toggle="modal"
                                                data-target="#modalDelete"
                                                onClick={() => this.setState({idDelete: pro.Id})}>
                                                    <img src={`/dist/img/icons/icon-delete.png`} alt=""/>
                                                </div>
                                                : 
                                                <div
                                                className="icon-btn"
                                                onClick={() => this.props.unvalidateMedicalPro(pro.Id, pro.isEdit)}>
                                                    <img src={`/dist/img/icons/icon-times.png`} alt=""/>
                                                </div>
                                                }
                                                </>}

                                                {/** bouton edit autres roles */}
                                                {this.props.roleUser !== "ROLE_ADMIN" && this.props.roleUser !== "ROLE_QADMIN" &&
                                                <div
                                                className="icon-btn"
                                                data-toggle={(pro.isEdit && pro.Status === 0) || pro.Status ? "modal": ""}
                                                data-target={(pro.isEdit && pro.Status === 0) || pro.Status ? "#modalAddEditPro" : ""}
                                                onClick={() => ((pro.isEdit && pro.Status === 0) || pro.Status) && this.setState({
                                                    ...this.state,
                                                    selectedPro: pro,
                                                    readOnly: false,
                                                    validating: false
                                                })}
                                                disabled={!pro.isEdit && pro.Status === 0}
                                                >
                                                    <img src={`/dist/img/icons/icon-edit.png`} alt=""/>
                                                </div>
                                                }
                                            </td>
                                        </tr>
                                        )
                                    })}
                                    {this.state.prosList.length === 0 && !this.props.loadingMedicalPros &&
                                    <tr>
                                        <td><p>Aucun professionnel de santé</p></td>
                                    </tr>
                                    }
                                </tbody>
                            </table>

                            {proListReady && maxPage > 1 && 
                            <Pagination
                                maxPage={this.state.maxPage}
                                page={this.state.page}
                                onChangePage={this.onChangePage}
                            ></Pagination>
                            }
                        </div>
                    </div>

                </section>

                <PopupAddEditPro
                handleSubmit={(submit) => this.setState({ handleSubmit: submit })}
                addPro={(pro) => this.props.postMedicalPro(pro)}
                pro={this.state.selectedPro}
                editPro={(pro) => this.props.editMedicalPro(pro)}
                pdsList={this.state.typeList}
                speList={this.state.speList}
                logList={this.state.listLogiciels}
                typeEtabList={this.state.typeEtabList}
                etabCountryList={this.state.typeEtabList.length > 0 ? this.generateEtabOptions() : []}
                updatePros={() => this.props.getProfessionnels(this.state.idCountry, {
                    page: 1,
                    maxPerPage: 50,
                    lastname: this.state.lastname,
                    firstname : this.state.firstname,
                    reference : this.state.reference,
                    protype : this.state.protype,
                    speciality : this.state.speciality,
                    status : this.state.status
                })}
                readOnly={this.state.readOnly}
                validating={this.state.validating}
                idCountry={this.state.idCountry}
                deletePro={(id) => this.setState({idDelete: id})}
                enablePro={(id, isEdit) => this.props.validateMedicalPro(id, isEdit)}
                enableEditPro={(id, isEdit) => {
                    this.props.validateMedicalPro(id, isEdit);
                    this.setState({
                        ...this.state,
                        validating: false,
                        readOnly: false
                    })
                }}
                disablePro={(id, isEdit) => this.props.unvalidateMedicalPro(id, isEdit)}
                typePDSLoading={this.props.loadingProType}                
                verif={(data) => this.props.verifPro(data)}
                doublons={this.state.doublon}
                />

                <PopupLinkPro
                searchPro={(value)=> this.searchLinkPro(value)}
                pro={this.state.linkPro}
                prosList={this.state.prosLinkList}
                typeList={this.state.typeList}
                linkPros={(id1, id2) => this.props.linkPros(id1, id2)}
                loading={this.props.loadingMedicalPros}
                />

                <PopupDelete
                type="professionnel"
                deletePro={(id) => this.props.deleteMedicalPro(id)}
                id={this.state.idDelete}
                />
                
                <PopupConfirmation message={'Êtes-vous sûr ?'} handleSubmit={this.state.handleSubmit}/>

            </section>
        )
    }
}

function mapState(state) {
    const { idCountry, country, loadingUserCountry, userCountry, } = state.zoneGeo;
    const { loadingProType, protype } = state.type;
    const { loadingSpecialite, specialite } = state.specialite;
    const { loadingLogiciel, logiciel } = state.software;
    const { verif, loadingVerifPro, loadingMedicalPros, medicalPros, medicalProPosted, medicalProEdited, medicalProDeleted, medicalProValidated, prosLinked, medicalProUnvalidated } = state.medicalPro;
    const { loadingEtabs, etabs } = state.etab;
    const { loadingTypeEtab, typesEtab } = state.typeEtab;
    const { roleUser } = state.authentification;
    return {
        verif, loadingVerifPro,
        loadingUserCountry, userCountry, idCountry, country,
        loadingProType, protype, loadingSpecialite, specialite, loadingLogiciel, logiciel,
        loadingMedicalPros, medicalPros, medicalProPosted, medicalProEdited,
        loadingEtabs, etabs, loadingTypeEtab, typesEtab,
        medicalProDeleted, medicalProValidated, prosLinked, medicalProUnvalidated, roleUser };
}
const actionCreators = {
    getUserCountry: zoneGeoActions.getUserCountry,
    getAllProType: protTypeActions.getAllProType,
    getAllSpecialite: specialiteActions.getAllSpecialite,
    getAllLogiciels: logicielActions.getAllLogiciels,
    getProfessionnels: medicalProActions.getMedicalPros,
    postMedicalPro: medicalProActions.postMedicalPro,
    editMedicalPro: medicalProActions.editMedicalPro,
    getTypeEtab: typeEtabActions.getAllTypeEtab,
    getEtablissements: etabActions.getEtablissements,
    deleteMedicalPro: medicalProActions.deleteMedicalPro,
    verifPro: medicalProActions.verifPro,
    validateMedicalPro: medicalProActions.validateMedicalPro,
    linkPros: medicalProActions.linkPros,
    unvalidateMedicalPro: medicalProActions.unvalidateMedicalPro,
};

const Professionnels = connect(mapState, actionCreators)(ProfessionnelsPage);

export default Professionnels;