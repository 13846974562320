export const specialiteConstants = {   
    SPECIALITE_REQUEST: 'SPECIALITE_GETALL_REQUEST',
    SPECIALITE_SUCCESS: 'SPECIALITE_GETALL_SUCCESS',
    SPECIALITE_FAILURE: 'SPECIALITE_GETALL_FAILURE',

    POST_SPECIALITE_REQUEST: 'POST_SPECIALITE_REQUEST',
    POST_SPECIALITE_SUCCESS: 'POST_SPECIALITE_SUCCESS',
    POST_SPECIALITE_FAILURE: 'POST_SPECIALITE_FAILURE',

    EDIT_SPECIALITE_REQUEST: 'EDIT_SPECIALITE_REQUEST',
    EDIT_SPECIALITE_SUCCESS: 'EDIT_SPECIALITE_SUCCESS',
    EDIT_SPECIALITE_FAILURE: 'EDIT_SPECIALITE_FAILURE',

    DELETE_SPECIALITE_REQUEST: 'DELETE_SPECIALITE_REQUEST',
    DELETE_SPECIALITE_SUCCESS: 'DELETE_SPECIALITE_SUCCESS',
    DELETE_SPECIALITE_FAILURE: 'DELETE_SPECIALITE_FAILURE',
};