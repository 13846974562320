import React from "react";

class CustomSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      defaultSelectText: "",
      showOptionList: false,
    };

    if(this.props.setChild) this.props.setChild(this);
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    this.setState({ defaultSelectText: this.props.defaultText });
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if(this.props.defaultText !== prevProps.defaultText) this.setState({ defaultSelectText: this.props.defaultText });
  }

  handleClickOutside = e => {
    if (
      !e.target.classList.contains("custom-select-option") &&
      !e.target.classList.contains("selected-text")
    ) this.setState({ showOptionList: false });
  };

  handleListDisplay = () => {
    if(this.props.disabled !== true) this.setState(prevState => { return { showOptionList: !prevState.showOptionList }; });
  };

  handleOptionClick = e => {
    const idCountry = e.target.getAttribute("data-id");
    const nameCountry = e.target.getAttribute("data-name");
    const label = e.target.getAttribute("data-label");

    this.props.handleOptionClick(idCountry, nameCountry, label);
    
    this.setState({
      defaultSelectText: nameCountry,
      showOptionList: false
    })
  };

  render() {
    const { optionsList } = this.props;
    const { showOptionList, defaultSelectText } = this.state;
    return (
      <div className={`custom-select-container ${this.props.disabled ? "disabled" : ""}`} style={(this.props.width !== undefined)? {minWidth : this.props.width} : {}}>
        <div
          className={showOptionList ? "selected-text active" : "selected-text"}
          onClick={this.handleListDisplay}
        >
          {defaultSelectText}
        </div>
        {showOptionList && (
          <ul className="select-options">
            {optionsList.map(option => {
              return (
                <li
                  className="custom-select-option"
                  data-name={option.name}
                  data-id={option.id}
                  data-label={option.label}
                  key={option.label ? option.label : option.id}
                  onClick={this.handleOptionClick}
                >
                  {option.name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  }
}

export default CustomSelect;
