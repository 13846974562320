import React from 'react';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from 'leaflet';


class PopupCoord extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            lat: this.props.coord.lat,
            lon: this.props.coord.lon,
            zoom: 16,
            popupText: this.props.popupText
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.coord !== prevProps.coord) {
            this.setState({
                ...this.state,
                lat: this.props.coord.lat,
                lon: this.props.coord.lon,
                popupText: this.props.popupText
            })
        }

        if((this.props.open !== prevProps.open) && this.props.open) {
            const map = this.refs.map.leafletElement;
            setTimeout(function() { map.invalidateSize(); }, 300);
        }
    }

    afficheCluster = () => {
        let table = [];
        let etabs = this.props.etabs;

        const iconDefault = L.icon({
            iconUrl: '/dist/img/icons/marker2.png',
            iconSize: [30, 37],
            iconAnchor: [15, 37],
            popupAnchor: [-1, -35],
            shadowUrl: '/dist/img/icons/marker-shadow.png',
            shadowSize: [37, 37]
        });

        for(let i=0; i<etabs.length; i++) {
            if((etabs[i].CoordLat !== this.state.lat) || (etabs[i].CoordLong !== this.state.lon)) {
                table.push(
                    <Marker icon={iconDefault} key={`marker${i}`} position={[etabs[i].CoordLat, etabs[i].CoordLong]}>
                        <Popup>
                            {etabs[i].reference} - {etabs[i].name}
                        </Popup>
                    </Marker>
                )
            }
            
        }
        return table;
    }

    render() {
        const iconHome = L.icon({
            iconUrl: '/dist/img/icons/marker1.png',
            iconSize: [30, 37],
            iconAnchor: [15, 37],
            popupAnchor: [-1, -35],
            shadowUrl: '/dist/img/icons/marker-shadow.png',
            shadowSize: [37, 37]
        });

        return(
            <div className="modal fade in" id="modalCoord" tabIndex="-1" role="dialog" aria-labelledby="modalCoordLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">Coordonnées GPS des établissements</h1>
                        </div>
                        <div className="modal-body">
                        <Map ref="map" center={[this.state.lat, this.state.lon]} zoom={this.state.zoom} setParams={true}>
                            <TileLayer
                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <MarkerClusterGroup>
                                <Marker icon={iconHome} position={[this.state.lat, this.state.lon]}>
                                    <Popup>
                                        {this.state.popupText.ref} - {this.state.popupText.name}
                                    </Popup>
                                </Marker>
                                {this.afficheCluster()}
                            </MarkerClusterGroup>
                        </Map>
                        </div>
                        <div className="modal-footer">
                            <button
                            type="button"
                            className="btn-save"
                            data-dismiss="modal"
                            ref={button => this.btnElement = button}
                            onClick={this.props.closeModal}
                            >OK</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopupCoord;