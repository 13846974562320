import { etabConstants } from '../constants/etab.constants';

export function etab(state = {}, action) {
  switch (action.type) {
    case etabConstants.GET_ETAB_BY_COUNTRY_REQUEST:
      return Object.assign({}, state, {
        loadingEtabs: true,
        etabs: {}
      });
    case etabConstants.GET_ETAB_BY_COUNTRY_SUCCESS:
      return Object.assign({}, state, {
        etabs: action.etabs,
        loadingEtabs: false
      });
    case etabConstants.GET_ETAB_BY_COUNTRY_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        loadingEtabs: false
      });
    case etabConstants.EDIT_ETAB_REQUEST:
      return Object.assign({}, state, {
        etabEdited: false
      });
    case etabConstants.EDIT_ETAB_SUCCESS:
      return Object.assign({}, state, {
        etabEdited: true
      });
    case etabConstants.EDIT_ETAB_FAILURE:
      return Object.assign({}, state, { 
        etabEdited: false
      });
    case etabConstants.POST_ETAB_REQUEST:
      return Object.assign({}, state, {
        etabPosted: false
      });
    case etabConstants.POST_ETAB_SUCCESS:
      return Object.assign({}, state, {
        etabPosted: true
      });
    case etabConstants.POST_ETAB_FAILURE:
      return Object.assign({}, state, { 
        etabPosted: false
      });
    case etabConstants.DELETE_ETAB_REQUEST:
      return Object.assign({}, state, {
        etabDeleted: false
      });
    case etabConstants.DELETE_ETAB_SUCCESS:
      return Object.assign({}, state, {
        etabDeleted: true
      });
    case etabConstants.DELETE_ETAB_FAILURE:
      return Object.assign({}, state, {
        etabDeleted: false
      });
    case etabConstants.ENABLE_ETAB_REQUEST:
      return Object.assign({}, state, {
        etabEnabled: false
      });
    case etabConstants.ENABLE_ETAB_SUCCESS:
      return Object.assign({}, state, {
        etabEnabled: true
      });
    case etabConstants.ENABLE_ETAB_FAILURE:
      return Object.assign({}, state, {
        etabEnabled: false
      });
    case etabConstants.DISABLE_ETAB_REQUEST:
      return Object.assign({}, state, {
        etabDisabled: false
      });
    case etabConstants.DISABLE_ETAB_SUCCESS:
      return Object.assign({}, state, {
        etabDisabled: true
      });
    case etabConstants.DISABLE_ETAB_FAILURE:
      return Object.assign({}, state, {
        etabDisabled: false
      });
    case etabConstants.GET_ETAB_RELEVE_REQUEST:
      return Object.assign({}, state, {
        loadingReleve: true,
        etabsReleve: {}
      });
    case etabConstants.GET_ETAB_RELEVE_SUCCESS:
      return Object.assign({}, state, {
        etabsReleve: action.etabsReleve,
        loadingReleve: false
      });
    case etabConstants.GET_ETAB_RELEVE_FAILURE:
      return Object.assign({}, state, { 
        error: action.error,
        loadingReleve: false
      });
    case etabConstants.LINK_ETABS_REQUEST:
      return Object.assign({}, state, {
        etabsLinked: false
      });
    case etabConstants.LINK_ETABS_SUCCESS:
      return Object.assign({}, state, {
        etabsLinked: true
      });
    case etabConstants.LINK_ETABS_FAILURE:
      return Object.assign({}, state, {
        etabsLinked: false
      });
    case etabConstants.GET_ALL_ETAB_REQUEST:
      return {
        loadingAllEtabs: true,
        allEtabs: {}
      };
    case etabConstants.GET_ALL_ETAB_SUCCESS:
      return {
        allEtabs: action.allEtabs,
        loadingAllEtabs: false
      };
    case etabConstants.GET_ALL_ETAB_FAILURE:
      return { 
        error: action.error,
        loadingAllEtabs: false
      };
    case etabConstants.GET_HISTO_ETAB_REQUEST:
      return {
        loadingHistoEtab: true,
        histo: {}
      };
    case etabConstants.GET_HISTO_ETAB_SUCCESS:
      return {
        histo: action.histo,
        loadingHistoEtab: false
      };
    case etabConstants.GET_HISTO_ETAB_FAILURE:
      return { 
        error: action.error,
        loadingHistoEtab: false
      };
    case etabConstants.GET_ETAB_INVALIDATE_REQUEST:
      return {
        loadingInvalidateEtab: true,
        invalidate: {}
      };
    case etabConstants.GET_ETAB_INVALIDATE_SUCCESS:
      return {
        invalidate: action.invalidate,
        loadingInvalidateEtab: false
      };
    case etabConstants.GET_ETAB_INVALIDATE_FAILURE:
      return { 
        error: action.error,
        loadingInvalidateEtab: false
      };
    case etabConstants.GET_VERFIF_ETAB_REQUEST:
      return {
        loadingVerifEtab: true,
        verif: {}
      };
    case etabConstants.GET_VERFIF_ETAB_SUCCESS:
      return {
        verif: action.verif,
        loadingVerifEtab: false
      };
    case etabConstants.GET_VERFIF_ETAB_FAILURE:
      return { 
        error: action.error,
        loadingVerifEtab: false
      };
    default:
      return state
  }
}