import { config } from '../constants/app.constants';
import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import * as CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

export const userService = {
    login,
    logout,
    forgotPassword,
    loginHistory,
    getUserConnected,
    getCountriesUser
};

function login(username, password) {

    return new Promise((resolve, reject) => {
        try {
            axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Content-Type': 'application/json'
                    }
                }).post('/login_check', {
                    "username": username,
                    "password": password
                }).then(function (response) {
                    try {
                        Cookies.set('loggedIn', true, { expires: 0.416667 });
                        localStorage.setItem('user', JSON.stringify(response.data));
                        let encryptUsername = CryptoJS.AES.encrypt(username, config.cryptedKey);
                        let encryptPassword = CryptoJS.AES.encrypt(password, config.cryptedKey);
                        localStorage.setItem('userInfo', [encryptUsername.toString(), encryptPassword.toString()]);
                        resolve(response.data);
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
        }
        catch (error) {
            reject(error);
        }
    });
}

function loginHistory() {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json'
                    }
                }).post('/api/user/login', {})
                .then(function (response) {
                    try {
                        resolve(response.data);
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getUserConnected() {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/userdatafrica', {}).then(function (response) {
                    try {
                        localStorage.setItem('role', response.data.UserConnected[0].roles[0]);
                        resolve(response.data.UserConnected[0]);
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function logout() {
    localStorage.clear();
}

function forgotPassword(email) {
    
    return new Promise((resolve, reject) => {
        try {
            (async () => {
                await axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Content-Type': 'application/json'
                    }
                }).post('/request-password', {
                    "email": email
                }).then(function (response) {
                    if(response.data.code === "900" || response.data.code === 900){
                        reject(response.data);
                    }else{
                        resolve(JSON.stringify(response.data)); 
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })();
        }
        catch (error) {
            reject(error);
        }
    });
}

function getCountriesUser() {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: { 
                        'Authorization': 'Bearer ' + res.token, 
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/user/area/countries', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listCountryByUser));
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}