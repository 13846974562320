export const typeEtabConstants = {   
    TYPE_ETAB_REQUEST: 'TYPE_ETAB_GETALL_REQUEST',
    TYPE_ETAB_SUCCESS: 'TYPE_ETAB_GETALL_SUCCESS',
    TYPE_ETAB_FAILURE: 'TYPE_ETAB_GETALL_FAILURE',

    POST_TYPE_ETAB_REQUEST: 'POST_TYPE_ETAB_REQUEST',
    POST_TYPE_ETAB_SUCCESS: 'POST_TYPE_ETAB_SUCCESS',
    POST_TYPE_ETAB_FAILURE: 'POST_TYPE_ETAB_FAILURE',

    EDIT_TYPE_ETAB_REQUEST: 'EDIT_TYPE_ETAB_REQUEST',
    EDIT_TYPE_ETAB_SUCCESS: 'EDIT_TYPE_ETAB_SUCCESS',
    EDIT_TYPE_ETAB_FAILURE: 'EDIT_TYPE_ETAB_FAILURE',

    DELETE_TYPE_ETAB_REQUEST: 'DELETE_TYPE_ETAB_REQUEST',
    DELETE_TYPE_ETAB_SUCCESS: 'DELETE_TYPE_ETAB_SUCCESS',
    DELETE_TYPE_ETAB_FAILURE: 'DELETE_TYPE_ETAB_FAILURE',
};