import { zoneGeoConstants } from '../constants/zoneGeo.constants';
import { zoneGeoService } from '../services/zoneGeo.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const zoneGeoActions = {
    getAllCountries,
    getByCountry,
    setCountry,
    getAllZonesGeo,
    postZoneGeo,
    editZoneGeo,
    deleteZoneGeo,
    getUserCountry
};

function getAllZonesGeo(data) {
    return (dispatch) => {

        dispatch(request());
        
        zoneGeoService.getAllZonesGeo(data)
            .then(
                listZones => {
                    dispatch(success(listZones));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: zoneGeoConstants.GET_ALL_ZONES_REQUEST } }
    function success(listZones) { return { type: zoneGeoConstants.GET_ALL_ZONES_SUCCESS, listZones } }
    function failure(error) { return { type: zoneGeoConstants.GET_ALL_ZONES_FAILURE, error } }
}

function getAllCountries() {
    return (dispatch) => {

        dispatch(request());
        
        zoneGeoService.getAllCountries()
            .then(
                listCountries => {
                    dispatch(success(listCountries));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: zoneGeoConstants.GET_ALL_COUNTRIES_REQUEST } }
    function success(listCountries) { return { type: zoneGeoConstants.GET_ALL_COUNTRIES_SUCCESS, listCountries } }
    function failure(error) { return { type: zoneGeoConstants.GET_ALL_COUNTRIES_FAILURE, error } }
}

function getByCountry(nameCountry) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        zoneGeoService.getByCountry(nameCountry)
            .then(
                listZoneGeo => {
                    dispatch(success(listZoneGeo));   
                    dispatch(hideLoading());             
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: zoneGeoConstants.GET_BY_COUNTRY_REQUEST } }
    function success(listZoneGeo) { return { type: zoneGeoConstants.GET_BY_COUNTRY_SUCCESS, listZoneGeo } }
    function failure(error) { return { type: zoneGeoConstants.GET_BY_COUNTRY_FAILURE, error } }
}

function setCountry(country) {
    return (dispatch) => {

        dispatch(success(country));
        
    };

    function success(country) { return { type: zoneGeoConstants.SET_COUNTRY, country } }
}

function postZoneGeo(idP, name) {
    return (dispatch) => {

        dispatch(request());
        
        zoneGeoService.postZoneGeo(idP, name)
            .then(
                zonePosted => {
                    dispatch(success(zonePosted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: zoneGeoConstants.POST_ZONE_GEO_REQUEST } }
    function success(zonePosted) { return { type: zoneGeoConstants.POST_ZONE_GEO_SUCCESS, zonePosted } }
    function failure(error) { return { type: zoneGeoConstants.POST_ZONE_GEO_FAILURE, error } }
}

function editZoneGeo(id, name) {
    return (dispatch) => {

        dispatch(request());
        
        zoneGeoService.editZoneGeo(id, name)
            .then(
                zoneEdited => {
                    dispatch(success(zoneEdited));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: zoneGeoConstants.EDIT_ZONE_GEO_REQUEST } }
    function success(zoneEdited) { return { type: zoneGeoConstants.EDIT_ZONE_GEO_SUCCESS, zoneEdited } }
    function failure(error) { return { type: zoneGeoConstants.EDIT_ZONE_GEO_FAILURE, error } }
}

function deleteZoneGeo(id) {
    return (dispatch) => {

        dispatch(request());
        
        zoneGeoService.deleteZoneGeo(id)
            .then(
                zoneDeleted => {
                    dispatch(success(zoneDeleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: zoneGeoConstants.DELETE_ZONE_GEO_REQUEST } }
    function success(zoneDeleted) { return { type: zoneGeoConstants.DELETE_ZONE_GEO_SUCCESS, zoneDeleted } }
    function failure(error) { return { type: zoneGeoConstants.DELETE_ZONE_GEO_FAILURE, error } }
}

function getUserCountry() {
    return (dispatch) => {

        dispatch(request());
        
        zoneGeoService.getUserCountry()
            .then(
                listUserCountry => {
                    dispatch(success(listUserCountry));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: zoneGeoConstants.GET_USER_COUNTRY_REQUEST } }
    function success(listUserCountry) { return { type: zoneGeoConstants.GET_USER_COUNTRY_SUCCESS, listUserCountry } }
    function failure(error) { return { type: zoneGeoConstants.GET_USER_COUNTRY_FAILURE, error } }
}