import React from 'react';
import { NavLink } from "react-router-dom";
import { connect } from 'react-redux';
import { userActions } from '../../actions/user.actions';
import { zoneGeoActions } from '../../actions/zoneGeo.actions';
import Cookies from 'js-cookie';
import { NotificationManager} from 'react-notifications';
import ReactCountryFlag from "react-country-flag";

class HeaderBarPage extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            showUserMenu : false,
            showCountryMenu : false,
            countryList: [],
            logStatus: true,
            country: "",
            userData: {}
        }
    }

    groupeList = [
        {
            id: "admin",
            name: "Administrateur",
            val: "ROLE_ADMIN"
        },
        {
            id: "homme_pays",
            name: "Homme Pays",
            val: "ROLE_HP"
        },
        {
            id: "qadmin",
            name: "QueryAdmins",
            val: "ROLE_QADMIN"
        },
        {
            id: "delegue",
            name: "Délégué",
            val: "ROLE_DELEGUE"
        },
        {
            id: "superviseur",
            name: "Superviseur",
            val: "ROLE_SUPERVISEUR"
        },
        {
            id: "directeur_pays",
            name: "Directeur Pays",
            val: "ROLE_DP"
        },
        {
            id: "district_sale_manager",
            name: "District Sale Manager",
            val: "ROLE_DSM"
        },
        {
            id: "directeur_ventes",
            name: "Directeur des Ventes",
            val: "ROLE_DDV"
        },
        {
            id: "moniteur",
            name: "Moniteurs",
            val: "ROLE_MONITEUR"
        },
        {
            id: "support",
            name: "Supports",
            val: "ROLE_SUPPORT"
        },
        {
            id: "geolocation",
            name: "Géolocalisation",
            val: "ROLE_GEOLOCATION"
        },
        {
            id: "operator",
            name: "Opérateur",
            val: "ROLE_OPERATOR"
        }
    ]

    getLogStatus = () => {        
        this.id = setInterval(() => {
            let log = Cookies.get('loggedIn');
            if(log === "true") this.setState({ logStatus: true });
            else this.setState({ logStatus: false });
        }, 1000);
    }

    componentDidMount(){
        this.getLogStatus();
        this.props.getCountriesUser();
        this.props.getUserConnected();
    }

    pushMenu = (e) => {
        e.preventDefault();
        var body = document.body;
        if(body.clientWidth > 768){
            if(body.className.indexOf('sidebar-collapse') === -1) body.className += ' sidebar-collapse';
            else body.className = body.className.replace(' sidebar-collapse', '');
        } else{
            if(body.className.indexOf('sidebar-open') === -1) body.className += ' sidebar-open';
            else body.className = body.className.replace(' sidebar-open','');
        }
    }

    componentDidUpdate(prevProps, prevState) {

        if((this.state.logStatus !== prevState.logStatus) && !this.state.logStatus){
            clearInterval(this.id);
            this.handleLogOut();
        }

        if((this.props.loadingCountriesUser !== prevProps.loadingCountriesUser) && !this.props.loadingCountriesUser) {
            if(
                this.props.countriesUser !== undefined && this.props.countriesUser !== "null"
                && Object.keys(this.props.countriesUser).length !== 0
            ) {
                
                if(localStorage.getItem('nameCountry')) {
                    this.props.setCountry({
                        id: localStorage.getItem('idCountry'),
                        name: localStorage.getItem('nameCountry')
                    });
                    this.setState({
                        ...this.state,
                        countryList: JSON.parse(this.props.countriesUser),
                        country: localStorage.getItem('nameCountry')
                    })
                } else {
                    this.state.country === "" && this.props.setCountry(JSON.parse(this.props.countriesUser)[0]);
                    this.setState({
                        countryList: JSON.parse(this.props.countriesUser),
                        country: this.state.country === "" ? JSON.parse(this.props.countriesUser)[0].name : this.state.country
                    });
                    localStorage.setItem('idCountry', JSON.parse(this.props.countriesUser)[0].id)
                    localStorage.setItem('nameCountry', JSON.parse(this.props.countriesUser)[0].name)
                }                
            }
        }

        if((this.props.loadingRoleUser !== prevProps.loadingRoleUser) && !this.props.loadingRoleUser) {
            if(this.props.userData !== undefined && this.props.userData !== "null") {
                if(this.props.userData.active === 1) {
                  clearInterval(this.id);
                  this.handleLogOut();
                } else this.setState({ userData: this.props.userData });
            }
        }
    }

    handleLogOut = (e) => {
        if(e) e.preventDefault();
        NotificationManager.success('', "Déconnexion en cours");
        setTimeout(() => {
            this.props.logout();
            this.props.userLogOut();
            window.location = "/";
            NotificationManager.success('', "Deconnecté");
        }, 2000);
    }

    changeCountry = (country) => {
        this.props.setCountry(country);
        this.setState({
            ...this.state,
            showCountryMenu: !this.state.showCountryMenu,
            country: country.name
        });
        localStorage.setItem('idCountry', country.id);
        localStorage.setItem('nameCountry', country.name);

    }

    render() {
        var that = this;
        const { showUserMenu, showCountryMenu } = this.state;

        return (
            <header className="main-header">
                {/* Logo */}
                <a href="#" className="logo">
                    {/* mini logo for sidebar mini 50x50 pixels */}
                    <span className="logo-mini"><b>Data</b>frica</span>
                    {/* logo for regular state and mobile devices */}
                    <span className="logo-lg"><b>Data</b>frica</span>
                </a>
                {/* Header Navbar: style can be found in header.less */}
                <nav className="navbar navbar-static-top" role="navigation">
                    {/* Sidebar toggle button*/}
                    <a href="#" className="sidebar-toggle" data-toggle="offcanvas" role="button" onClick={that.pushMenu}>
                        <span className="sr-only">Basculer la navigation</span>
                    </a>
                    <div className="navbar-custom-menu">
                        <ul className="nav navbar-nav">

                            {/* <li className="lang lang-menu">

                                {localStorage.getItem("currentLanguage") === "fr" ?
                                    <div
                                        className="headerLang"
                                        onClick={() => {
                                            localStorage.setItem('currentLanguage', "en");
                                            window.location.reload(false);
                                        }}
                                    >
                                        <ReactCountryFlag
                                            svg
                                            className="langFlag"
                                            countryCode="FR"
                                            aria-label="Français"
                                            title="Français"
                                            style={{ width: "1.7em", height: "1.7em" }}
                                        />
                                    </div>
                                :
                                    <div
                                        className="headerLang"
                                        onClick={() => {
                                            localStorage.setItem('currentLanguage', "fr");
                                            window.location.reload(false);
                                        }}
                                    >
                                        <ReactCountryFlag
                                            svg
                                            className="langFlag"
                                            countryCode="GB"
                                            aria-label="Anglais"
                                            title="Anglais"
                                            style={{ width: "1.7em", height: "1.7em" }}
                                        />
                                    </div>
                                }

                            </li> */}
                            
                            <li className="country country-menu">
                                <a
                                className="country_a_dropdown"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showCountryMenu: !this.state.showCountryMenu })
                                }}
                                href="#"
                                >
                                    <i className="fa fa-globe"></i>
                                    <span>{this.state.country}</span>
                                </a>
                                <ul className="dropdown-menu" style={showCountryMenu ? {display:"block"} : {display:"none"}}>
                                    <li>
                                        <ul className="menu">
                                            {this.state.countryList.map((c, i) => {
                                                return (
                                                    <li key={i} onClick={() => this.changeCountry(c)}>
                                                        <span>{c.name}</span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </li>
                                    <li className="footer">
                                        <span onClick={() => this.props.getCountriesUser()}>Mettre à jour les pays</span>
                                    </li>
                                </ul>
                            </li>

                            {/* User Account: style can be found in dropdown.less */}
                            <li className="user user-menu">
                                <a
                                className="user_a_dropdown"
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({ showUserMenu: !this.state.showUserMenu })
                                }}
                                href="#"
                                >
                                    <i className="fa fa-user"></i>
                                    
                                    <span className="hidden-xs">{this.state.userData.firstName} {this.state.userData.lastName}
                                    <i className="caret"></i></span>
                                </a>
                                <ul className="dropdown-menu" style={showUserMenu ? {display:"block"} : {display:"none"}}>
                                    <li className="user-header bg-light-blue">
                                        <p>
                                            {this.state.userData.firstName} {this.state.userData.lastName}
                                            <small>{Object.keys(this.state.userData).length > 0 && this.groupeList[this.groupeList.map((r) => r.val).indexOf(this.state.userData.roles[0])].name}</small>
                                        </p>
                                    </li>
                                    <li className="user-footer">
                                        <div className="pull-left">
                                            <NavLink exact to="/profile/edit" onClick={() => this.setState({ showUserMenu: !this.state.showUserMenu })} className="btn btn-default btn-flat">
                                                Mon compte
                                            </NavLink>
                                        </div>
                                        <div className="pull-right" >
                                            <a onClick={this.handleLogOut} href="#" className="btn btn-default btn-flat">Déconnexion</a>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </nav>
            </header>
        )
    }
}

function mapState(state) {
    const { loggedIn, loadingRoleUser, userData, loadingCountriesUser, countriesUser } = state.authentification;
    const { country } = state.zoneGeo;
    return { loggedIn, country, loadingRoleUser, userData, loadingCountriesUser, countriesUser };
}
const actionCreators = {
    logout: userActions.logout,
    getUserConnected: userActions.getUserConnected,
    getCountriesUser: userActions.getCountriesUser,
    setCountry: zoneGeoActions.setCountry,
};

const HeaderBar = connect(mapState, actionCreators)(HeaderBarPage);

export default HeaderBar;
