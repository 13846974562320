import React from 'react';
import { connect } from 'react-redux';
import { usersActions } from '../../actions/users.actions';
import Moment from 'react-moment';
import ButtonAdd from '../../components/shared/ButtonAdd';
import PopupAddEditUser from '../../components/shared/PopupAddEditUser';
import PopupDelete from '../../components/shared/PopupDelete';
import { NotificationManager} from 'react-notifications';
import Pagination from '../../components/Pagination';
import PopupConfirmation from '../../components/shared/PopupConfirmation';

class UtilisateursPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            usersList: [],
            selectedUser: {},
            idDelete: -1,
            search: "",
            lastname: "",
            firstname: "",
            username: "",
            searchPrenom: "",
            searchId: "",
            groupeList: [
                {
                    id: "admin",
                    name: "Administrateur",
                    val: "ROLE_ADMIN"
                },
                {
                    id: "homme_pays",
                    name: "Homme Pays",
                    val: "ROLE_HP"
                },
                {
                    id: "qadmin",
                    name: "QueryAdmins",
                    val: "ROLE_QADMIN"
                },
                {
                    id: "delegue",
                    name: "Délégués",
                    val: "ROLE_DELEGUE"
                },
                {
                    id: "superviseur",
                    name: "Superviseurs",
                    val: "ROLE_SUPERVISEUR"
                },
                {
                    id: "directeur_pays",
                    name: "Directeur Pays",
                    val: "ROLE_DP"
                },
                {
                    id: "district_sale_manager",
                    name: "District Sale Manager",
                    val: "ROLE_DSM"
                },
                {
                    id: "directeur_ventes",
                    name: "Directeur des Ventes",
                    val: "ROLE_DDV"
                },
                {
                    id: "moniteur",
                    name: "Moniteurs",
                    val: "ROLE_MONITEUR"
                },
                {
                    id: "support",
                    name: "Supports",
                    val: "ROLE_SUPPORT"
                },
                {
                    id: "geolocation",
                    name: "Géolocalisation",
                    val: "ROLE_GEOLOCATION"
                },
                {
                    id: "operator",
                    name: "Opérateur",
                    val: "ROLE_OPERATOR"
                }
            ],
            page: 1,
            maxPage: 1,
            maxPerPage: 100,
            usersReady: false,
            orderKey: "active",
            orderByKey:{
                active: true,
                accessDemo: false,
                firstName: false,
                lastName: false,
                roleName: false,
                lastactivity: false,
                lastpwddatechange: false,
                username: false
            }
        };

        this.timer = setTimeout(() => {}, 1);
    }

    componentDidMount() {
        this.props.getAllUsers({
            page: this.state.page,
            maxPerPage: this.state.maxPerPage
        });
    }

    componentDidUpdate(prevProps, prevState) {

        if((this.props.loadingUsers !== prevProps.loadingUsers) && !this.props.loadingUsers) {
            if(this.props.users !== undefined) {
                if(JSON.parse(this.props.users).listUser !== null) this.setState({
                    ...this.state,
                    usersList: JSON.parse(this.props.users).listUser,
                    maxPage: JSON.parse(this.props.users).nbrPages,
                    usersReady: true
                });
                else this.setState({
                    ...this.state,
                    usersList: [],
                    usersReady: true
                });
            } 
        }

        // PAGINATION

        if(this.state.page !== prevState.page){
            this.props.getAllUsers({
                page: this.state.page,
                maxPerPage: this.state.maxPerPage,
                username: this.state.searchId,
                firstname: this.state.searchPrenom,
                lastname: this.state.search
            });
        }

        // NOTIFICATIONS

        if((this.props.userDisabled !== prevProps.userDisabled) && this.props.userDisabled) {
            this.props.getAllUsers({
                page: this.state.page,
                maxPerPage: this.state.maxPerPage
            });
            NotificationManager.success('', "Utilisateur activé/désactivé");
        }

        if((this.props.userPosted !== prevProps.userPosted) && this.props.userPosted) {
            const data = JSON.parse(this.props.userPostedData);
            if(data.code === 900) NotificationManager.warning('', data.response);
            else {
                this.props.getAllUsers({
                    page: this.state.page,
                    maxPerPage: this.state.maxPerPage
                });
                NotificationManager.success('', "Utilisateur ajouté");
            }
        }

        if((this.props.userDeleted !== prevProps.userDeleted) && this.props.userDeleted) {
            this.props.getAllUsers({
                page: this.state.page,
                maxPerPage: this.state.maxPerPage
            });
            NotificationManager.success('', "Utilisateur supprimé");
        }

        if((this.props.userEdited !== prevProps.userEdited) && this.props.userEdited) {
            const data = JSON.parse(this.props.userEditedData);
            if(data.code === 900) NotificationManager.warning('', data.response);
            else {
                this.props.getAllUsers({
                    page: this.state.page,
                    maxPerPage: this.state.maxPerPage
                });
                NotificationManager.success('', "Utilisateur modifié");
            }
        }
    }

    byString = (o, s) =>{
        s = s.replace(/\[(\w+)\]/g, '.$1'); // convert indexes to properties
        s = s.replace(/^\./, '');           // strip a leading dot
        var a = s.split('.');

        for (var i = 0, n = a.length; i < n; ++i) {
            var k = a[i];
            if (k in o) {
                if(s === "firstName" || s === "lastName" || s === "username" || s === "roleName") o = o[k].toLowerCase();
                else if(s === "role") o = o[0][k];
                else o = o[k];           
            } else return;
        }

        return o;
    }
    
    handleOrderByKey(liste, key){
        if(this.getKeyState(key) === false) liste.reverse(); 
        else liste.sort((a, b) =>{
            if(this.state.orderKey && (this.byString(a, this.state.orderKey) !== undefined)){
                if(this.byString(a, this.state.orderKey) < this.byString(b, this.state.orderKey)) return -1;
                if(this.byString(a, this.state.orderKey) > this.byString(b, this.state.orderKey)) return 1;
            }
            return 0;
        });
    }

    formatedListUser = (data) => {
        for(let i = 0; i < data.length ; i++){
            Object.assign(data[i], { roleName: this.state.groupeList[this.state.groupeList.map((r) => r.val).indexOf(data[i].roles[0])].name});            
        }
        return data;
    } 

    handlePressFilterbyKey = (key) => this.setState({
        orderByKey: { [key]: !this.getKeyState(key) },
        orderKey: key,
    });

    getKeyState = (key) => {
        let keyState = "";
        if(key === "active") {
            keyState = this.state.orderByKey.active;
        }else if(key === "firstName"){
            keyState = this.state.orderByKey.firstName;
        }else if(key === "lastName"){
            keyState = this.state.orderByKey.lastName;
        }else if(key === "roleName"){
            keyState = this.state.orderByKey.roleName;
        }else if(key === "lastactivity"){
            keyState = this.state.orderByKey.lastactivity;
        }else if(key === "username"){
            keyState = this.state.orderByKey.username;
        }
        return keyState;
    }

    onChangePage = (data) => this.setState({ page: data });

    handleFilter = (value, key) => {
        clearTimeout(this.timer);
        this.setState({ [key]: value });
    
        this.timer = setTimeout(() => {
            this.props.getAllUsers({
                page: 1,
                maxPerPage: this.state.maxPerPage,
                username: this.state.searchId,
                firstname: this.state.searchPrenom,
                lastname: this.state.search
            });
            this.setState({ usersReady: false });
        }, 1000);
    }

    render() {
        const { usersReady, maxPage } = this.state;
        this.handleOrderByKey(this.state.usersList, this.state.orderKey);

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Utilisateurs
                        <small>Gestion des utilisateurs</small>
                    </h1>
                </section>
            
                <section className="content users">

                    <div className="row">
                        <div className="col-md-12">
                            <table className="box tab-entete users">
                                <tbody>
                                    <tr>
                                        <td className="small text-center">
                                            <div>
                                                <p>Statut</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.active !== false && this.state.orderKey === "active" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("active")}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <p>Nom</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.lastName !== false && this.state.orderKey === "lastName" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("lastName")}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <p>Prénom</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.firstName !== false && this.state.orderKey === "firstName" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("firstName")}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <p>Groupe</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.roleName !== false && this.state.orderKey === "roleName" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("roleName")}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <p>Identifiant</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.username !== false && this.state.orderKey === "username" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("username")}/>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <p>Dernière activité</p>
                                                <img style={{width:"20px",height:"20px"}} src={`/dist/img/icons/${this.state.orderByKey.lastactivity !== false && this.state.orderKey === "lastactivity" ? "icon-a-z" : "icon-z-a"  }.png`} alt="" onClick={() =>this.handlePressFilterbyKey("lastactivity")}/>
                                            </div>
                                        </td>
                                        <td>
                                            <ButtonAdd modal="#modalAddEditUser" handleAdd={() => this.setState({selectedUser: {}})}/>
                                        </td>
                                    </tr>
                                    <tr className="bottom">
                                        <td className="small text-center">
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    <input 
                                                    type="text" 
                                                    placeholder="..." 
                                                    value={this.state.search} 
                                                    onChange={(e) => this.handleFilter(e.target.value, "search")} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    <input
                                                    type="text"
                                                    placeholder="..."
                                                    value={this.state.searchPrenom}
                                                    onChange={(e) => this.handleFilter(e.target.value, "searchPrenom")} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>                                            
                                        </td>
                                        <td>
                                            <div>
                                                <div>
                                                    <input
                                                    type="text"
                                                    placeholder="..."
                                                    value={this.state.searchId}
                                                    onChange={(e) => this.handleFilter(e.target.value, "searchId")} />
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <table className="list users">
                        <tbody>
                            {this.state.usersList.map((user, i) => {
                                return (
                                    <tr key={i} className="list-row">
                                        <td className="small text-center planning">
                                            <div className={`tag ${user.active === 0 ? "valid" : "warning"}`}>
                                                <p>{user.active === 0 ? "Actif" : "Désactivé"}</p>
                                            </div>
                                        </td>
                                        <td className="uppercase">
                                            {user.lastName}
                                        </td>
                                        <td>
                                            {user.firstName}
                                        </td>
                                        <td>
                                            {this.state.groupeList[this.state.groupeList.map((r) => r.val).indexOf(user.roles[0])].name}
                                        </td>
                                        <td>
                                            {user.username}
                                        </td>
                                        <td className="showOverflow">
                                            <span
                                            className="infobulle"
                                            aria-label={new Date(user.lastactivity).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric', hour: '2-digit', minute: '2-digit' })}
                                            >
                                                {user.lastactivity !== null &&
                                                <Moment locale="fr" fromNow>{new Date(user.lastactivity)}</Moment>
                                                }
                                                
                                            </span>
                                        </td>
                                        <td className="icons">
                                            <div
                                            className="icon-btn"
                                            data-toggle="modal"
                                            data-target="#modalAddEditUser"
                                            onClick={() => this.setState({selectedUser: user})}>
                                                <img src="/dist/img/icons/icon-edit.png" alt=""/>
                                            </div>
                                            <div
                                            className="icon-btn"
                                            data-toggle="modal"
                                            data-target="#modalDelete"
                                            onClick={() => this.setState({idDelete: user.id})}>
                                                <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                            {this.state.usersList.length === 0 &&
                            <tr>
                                <td className="text-center">Aucun utilisateur trouvé</td>
                            </tr>
                            }
                        </tbody>
                    </table>

                    {usersReady && maxPage > 1  && 
                    <Pagination
                        maxPage={this.state.maxPage}
                        page={this.state.page}
                        onChangePage={this.onChangePage}
                    ></Pagination>
                    }

                </section>

                <PopupAddEditUser
                handleSubmit={(submit) => this.setState({ handleSubmit: submit })}
                addUser={(user) => this.props.postUser(user)}
                user={this.state.selectedUser}
                editUser={(user) => this.props.editUser(user)}
                />

                <PopupDelete
                type="utilisateur"
                deleteUser={(id) => this.props.deleteUser(id)}
                id={this.state.idDelete}
                />
                
                <PopupConfirmation message={'Êtes-vous sûr ?'} handleSubmit={this.state.handleSubmit}/>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingUsers, users, userDisabled, userPosted, userDeleted, userEdited, userPostedData, userEditedData } = state.users;
    return { loadingUsers, users, userDisabled, userPosted, userDeleted, userEdited, userPostedData, userEditedData };
}
const actionCreators = {
    getAllUsers: usersActions.getAllUsers,
    disableUser: usersActions.disableUser,
    postUser: usersActions.postUser,
    deleteUser: usersActions.deleteUser,
    editUser: usersActions.editUser
};

const Utilisateurs = connect(mapState, actionCreators)(UtilisateursPage);

export default Utilisateurs;