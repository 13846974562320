import { config } from '../constants/app.constants';
import { authHeader } from '../helpers/auth-header';
import axios from 'axios';

export const proTypeService = {
    getAllProType,
    postProType,
    editProType,
    deleteProType
};

function getAllProType() {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('/api/medicalprotype', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMedicalProType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function postProType(pds) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('/api/medicalprotype', {
                    "name": pds.name,
                    "code": pds.code
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function editProType(pds) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('/api/medicalprotype/'+pds.id, {
                    "name": pds.name,
                    "code": pds.code
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteProType(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('/api/medicalprotype/'+id, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}