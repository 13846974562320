import { etabConstants } from '../constants/etab.constants';
import { etabService } from '../services/etab.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const etabActions = {
    getEtablissements,
    postEtablissement,
    editEtablissement,
    deleteEtablissement,
    enableEtablissement,
    getEtabReleve,
    linkEtabs,
    getAllEtabs,
    verifEtablissement,
    getEtabInvalidate,
    getHistoEtab,
    disableEtablissement
};

function getEtablissements(idCountry, data) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        etabService.getEtablissements(idCountry, data)
            .then(
                etabs => {
                    dispatch(success(etabs));
                    dispatch(hideLoading());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: etabConstants.GET_ETAB_BY_COUNTRY_REQUEST } }
    function success(etabs) { return { type: etabConstants.GET_ETAB_BY_COUNTRY_SUCCESS, etabs } }
    function failure(error) { return { type: etabConstants.GET_ETAB_BY_COUNTRY_FAILURE, error } }
}

function getEtabInvalidate(data) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.getEtabInvalidate(data)
            .then(
                invalidate => {
                    dispatch(success(invalidate));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.GET_ETAB_INVALIDATE_REQUEST } }
    function success(invalidate) { return { type: etabConstants.GET_ETAB_INVALIDATE_SUCCESS, invalidate } }
    function failure(error) { return { type: etabConstants.GET_ETAB_INVALIDATE_FAILURE, error } }
}

function verifEtablissement(data) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        etabService.verifEtablissement(data)
            .then(
                verif => {
                    dispatch(success(verif));
                    dispatch(hideLoading());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: etabConstants.GET_VERFIF_ETAB_REQUEST } }
    function success(verif) { return { type: etabConstants.GET_VERFIF_ETAB_SUCCESS, verif } }
    function failure(error) { return { type: etabConstants.GET_VERFIF_ETAB_FAILURE, error } }
}

function postEtablissement(country, etab) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.postEtablissement(country, etab)
            .then(
                posted => {
                    dispatch(success(posted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.POST_ETAB_REQUEST } }
    function success(posted) { return { type: etabConstants.POST_ETAB_SUCCESS, posted } }
    function failure(error) { return { type: etabConstants.POST_ETAB_FAILURE, error } }
}

function editEtablissement(etab) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.editEtablissement(etab)
            .then(
                edited => {
                    dispatch(success(edited));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.EDIT_ETAB_REQUEST } }
    function success(edited) { return { type: etabConstants.EDIT_ETAB_SUCCESS, edited } }
    function failure(error) { return { type: etabConstants.EDIT_ETAB_FAILURE, error } }
}

function deleteEtablissement(id) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.deleteEtablissement(id)
            .then(
                deleted => {
                    dispatch(success(deleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.DELETE_ETAB_REQUEST } }
    function success(deleted) { return { type: etabConstants.DELETE_ETAB_SUCCESS, deleted } }
    function failure(error) { return { type: etabConstants.DELETE_ETAB_FAILURE, error } }
}

function enableEtablissement(id, isEdit) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.enableEtablissement(id, isEdit)
            .then(
                enabled => {
                    dispatch(success(enabled));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.ENABLE_ETAB_REQUEST } }
    function success(enabled) { return { type: etabConstants.ENABLE_ETAB_SUCCESS, enabled } }
    function failure(error) { return { type: etabConstants.ENABLE_ETAB_FAILURE, error } }
}

function disableEtablissement(id, isEdit) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.disableEtablissement(id, isEdit)
            .then(
                disabled => {
                    dispatch(success(disabled));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.DISABLE_ETAB_REQUEST } }
    function success(disabled) { return { type: etabConstants.DISABLE_ETAB_SUCCESS, disabled } }
    function failure(error) { return { type: etabConstants.DISABLE_ETAB_FAILURE, error } }
}

function getEtabReleve(idCountry, data) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        etabService.getEtabReleve(idCountry, data)
            .then(
                etabsReleve => {
                    dispatch(success(etabsReleve));  
                    dispatch(hideLoading());              
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: etabConstants.GET_ETAB_RELEVE_REQUEST } }
    function success(etabsReleve) { return { type: etabConstants.GET_ETAB_RELEVE_SUCCESS, etabsReleve } }
    function failure(error) { return { type: etabConstants.GET_ETAB_RELEVE_FAILURE, error } }
}

function linkEtabs(id1, id2) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.linkEtabs(id1, id2)
            .then(
                etabsLinked => {
                    dispatch(success(etabsLinked));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.LINK_ETABS_REQUEST } }
    function success(etabsLinked) { return { type: etabConstants.LINK_ETABS_SUCCESS, etabsLinked } }
    function failure(error) { return { type: etabConstants.LINK_ETABS_FAILURE, error } }
}

function getAllEtabs() {
    return (dispatch) => {

        dispatch(request());
        
        etabService.getAllEtabs()
            .then(
                allEtabs => {
                    dispatch(success(allEtabs));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.GET_ALL_ETAB_REQUEST } }
    function success(allEtabs) { return { type: etabConstants.GET_ALL_ETAB_SUCCESS, allEtabs } }
    function failure(error) { return { type: etabConstants.GET_ALL_ETAB_FAILURE, error } }
}

function getHistoEtab(id) {
    return (dispatch) => {

        dispatch(request());
        
        etabService.getHistoEtab(id)
            .then(
                histo => {
                    dispatch(success(histo));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: etabConstants.GET_HISTO_ETAB_REQUEST } }
    function success(histo) { return { type: etabConstants.GET_HISTO_ETAB_SUCCESS, histo } }
    function failure(error) { return { type: etabConstants.GET_HISTO_ETAB_FAILURE, error } }
}