import React from 'react';
import Select from 'react-select';
import ReactLoading from 'react-loading';

class PopupLinkPro extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pro: {},
            rattach: null,
            search: ""
        };

        this.timer = setTimeout(() => {}, 1);
    }

    componentDidUpdate(prevProps, prevState) {

        if(this.props.pro !== prevProps.pro) {
            this.setState({
                pro: this.props.pro,
                rattach: null,
                search: ""
            });
        }
    }

    generateProOptions = () => {
        const pros = this.props.prosList;
        const types = this.props.typeList
        let table = [];

        types.map((t) => {
            table.push({
                label: t.name,
                options: []
            })
        })

        pros.map((e) => {
            if(e !== this.state.pro) {
                table[table.map((g) => g.label).indexOf(e.MedicalProType.nameMedicalProType)].options.push(
                    {
                        value: e.Id,
                        label: `${e.Reference} ${e.FirstName} ${e.LastName}`,
                        ref: e.Reference,
                        ad: e.MedicalProType.nameMedicalProType
                    }
                )
            }
        });
        return table;
    }

    handleSubmit = (e) => {
        if((Object.keys(this.state.pro).length !== 0) && (this.state.rattach !== null)) {
            e.preventDefault();
            this.props.linkPros(this.state.pro.Id, this.state.rattach.value);
            this.btnElement.click();
        }
    }

    handleFilter = (value, key) => {
        clearTimeout(this.timer);
        this.setState({ [key]: value });
    
        this.timer = setTimeout(() => this.props.searchPro(this.state.search), 1000);
    }

    render() {
        const formatGroupLabel = data => (<div><span>{data.label}</span></div>);

        return(
            <div className="modal fade in" id="modalLink" tabIndex="-1" role="dialog" aria-labelledby="modalLinkLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">Rattacher le PDS à un autre</h1>
                        </div>
                        <div className="modal-body">
                            <div className="box alert">
                                <img src="/dist/img/icons/icon-info.png" alt=""/>
                                <h2>Opération irréversible !</h2>
                                <p>
                                Vous vous apprêtez à rattacher le PDS <b>{this.props.pro.FirstName} {this.props.pro.LastName}</b> ({this.props.pro.Reference}) à un autre.<br/>
                                <b>Cette opération est irréversible.</b> Seuls les lieux de consulation seront transférés vers
                                le PDS de rattachement. <b>Le reste des informations disparaîtra.</b>
                                </p>
                            </div>

                            <label style={{display: 'inline-block'}}>PDS de rattachement</label>
                            {this.props.loading && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader pds" />}
                            <Select
                            options={this.props.typeList.length !== 0 ? this.generateProOptions() : []}
                            formatGroupLabel={formatGroupLabel}
                            value={this.state.rattach}
                            onInputChange={(newValue) => this.handleFilter(newValue, "search")}
                            onChange={(newValue) => this.setState({ rattach: newValue })}
                            placeholder="Entrez le nom d'un PDS"
                            formatOptionLabel={(option, { context }) => {
                                /* context can be either `menu` or `value`
                                    menu - dropdown
                                    value - value displayed
                                */
                                return context === 'menu' ? (
                                    <div className="select-react-option">
                                        <div>
                                            <p>{option.label}</p>
                                        </div>
                                        <small>{option.ad}</small>
                                    </div>
                                ) : option.label;
                            }}
                            isClearable
                            className="react-select-container"
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="submit" form="formAjout" className="btn-save" onClick={this.handleSubmit}>
                                Rattacher
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={button => this.btnElement = button}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopupLinkPro;