import React from 'react';
import PopupChooseZoneGeo from './PopupChooseZoneGeo';
import Select from 'react-select';

class PopupAddZoneGeo extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            zoneParent: null,
            name: ""
        }
    }

    componentDidUpdate(prevProps) {
        if((this.props.zoneParent !== prevProps.zoneParent) && this.props.zoneParent.id !== undefined) {
            this.setState({zoneParent: {
                value: this.props.zoneParent.id,
                label: this.props.zoneParent.name
            }});
        }
    }

    handleSubmit = (e) => {
        if((this.state.zoneParent !== null) && (this.state.name !== "")) {
            e.preventDefault();
            this.props.postZoneGeo(this.state.zoneParent.value, this.state.name);
            this.btnElement.click();
        }
    }

    handleZoneSearch = (country, province, ville, commune, zoneID) => this.setState({zoneGeo: {
        value: zoneID,
        label: commune ? commune : ville ? ville : province ? province : country,
        ad: `${country}${province ? `, ${province}` : ""}${ville ? `, ${ville}` : ""}${commune ? `, ${commune}` : ""}`
    }});

    generateZGOptions = () => {
        const zone = this.props.zgList;
        let table = [
            {
                label: "Pays",
                options: []
            },
            {
                label: "Zone",
                options: []
            },
            {
                label: "Ville",
                options: []
            },
            {
                label: "Commune",
                options: []
            },
            {
                label: "Quartier",
                options: []
            },
        ];

        Object.keys(zone).map((item) => {

            if((item !== "name") && (item !== "id")) {
                Object.keys(zone[item]).map((zoneG) => {

                    if((zoneG !== "name") && (zoneG !== "id")) {
                        Object.keys(zone[item][zoneG]).map((ville) => {
        
                            if((ville !== "name") && (ville !== "id")) {
                                Object.keys(zone[item][zoneG][ville]).map((com) => {
        
                                    if((com !== "name") && (com !== "id")) {
                                        Object.keys(zone[item][zoneG][ville][com]).map((quart) => {
        
                                            if((quart !== "name") && (quart !== "id")) {
                                                table[table.map((g) => g.label).indexOf("Quartier")].options.push(
                                                    {
                                                        value: zone[item][zoneG][ville][com][quart].id,
                                                        label: zone[item][zoneG][ville][com][quart].name,
                                                        ad: `${zone[item].name}, ${zone[item][zoneG].name}, ${zone[item][zoneG][ville].name}, ${zone[item][zoneG][ville][com].name}, ${zone[item][zoneG][ville][com][quart].name}`
                                                    }
                                                )
                                            }
                                        });

                                        table[table.map((g) => g.label).indexOf("Commune")].options.push(
                                            {
                                                value: zone[item][zoneG][ville][com].id,
                                                label: zone[item][zoneG][ville][com].name,
                                                ad: `${zone[item].name}, ${zone[item][zoneG].name}, ${zone[item][zoneG][ville].name}, ${zone[item][zoneG][ville][com].name}`
                                            }
                                        )
                                    }
                                });
        
                                table[table.map((g) => g.label).indexOf("Ville")].options.push(
                                    {
                                        value: zone[item][zoneG][ville].id,
                                        label: zone[item][zoneG][ville].name,
                                        ad: `${zone[item].name}, ${zone[item][zoneG].name}, ${zone[item][zoneG][ville].name}`
                                    }
                                )
                            }
                        });
        
                        table[table.map((g) => g.label).indexOf("Zone")].options.push(
                            {
                                value: zone[item][zoneG].id,
                                label: zone[item][zoneG].name,
                                ad: `${zone[item].name}, ${zone[item][zoneG].name}`
                            }
                        )
                    }
                });

                table[table.map((g) => g.label).indexOf("Pays")].options.push(
                    {
                        value: zone[item].id,
                        label: zone[item].name,
                        ad: `${zone[item].name}`
                    }
                )
            }
        });
        
        return table;
    }

    render() {
        const formatGroupLabel = data => (<div><span>{data.label}</span></div>);

        return(
            <div className="modal fade in" id="modalAddZoneGeo" tabIndex="-1" role="dialog" aria-labelledby="modalAddZoneGeoLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">Ajouter une zone géographique</h1>
                        </div>
                        <div className="modal-body">
                            <form id="formAjout">
                                <label>Zone géographique parente</label>
                                <div className="zoneGeo-select">
                                    <Select
                                    options={this.generateZGOptions()}
                                    formatGroupLabel={formatGroupLabel}
                                    value={this.state.zoneParent}
                                    onChange={(newValue) => this.setState({zoneParent: newValue})}
                                    placeholder="Entrez une zone..."
                                    formatOptionLabel={(option, { context }) => {
                                        /* context can be either `menu` or `value`
                                            menu - dropdown
                                            value - value displayed
                                        */
                                        return context === 'menu' ? (
                                            <div className="select-react-option">
                                                <p>{option.label}</p>
                                                <small>{option.ad}</small>
                                            </div>
                                        ) : option.label;
                                    }}
                                    isClearable
                                    className="react-select-container"
                                    />
                                    <div
                                    className="searchZoneGeo"
                                    data-toggle="modal"
                                    data-target="#modalChooseZoneGeo"
                                    >
                                        <i
                                        className="fa fa-search"
                                        aria-hidden="true"
                                        ></i>
                                    </div>
                                </div>

                                <label>Nom</label>
                                <input type="text" 
                                required 
                                value={this.state.name} 
                                placeholder="Nom de la nouvelle zone"
                                onChange={(e) => this.setState({name: e.target.value})}/>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" form="formAjout" className="btn-save" onClick={this.handleSubmit}>
                                <img src="/dist/img/icons/icon-save.png" alt=""/>
                                Sauvegarder
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            onClick={() => this.setState({ name: "" })}
                            ref={button => this.btnElement = button}
                            >Annuler</button>
                        </div>
                    </div>
                </div>

                <PopupChooseZoneGeo
                submitZone={(country, province, ville, commune, zoneID) => this.handleZoneSearch(country, province, ville, commune, zoneID)}
                />
            </div>
        )
    }
}

export default PopupAddZoneGeo;