import React from 'react';
import CustomSelect from './CustomSelect';
import { connect } from 'react-redux';
import { zoneGeoActions } from '../../actions/zoneGeo.actions';

class PopupAddEditUserPage extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            countryList: [],
            lastName: "",
            firstName: "",
            groupe: "Sélectionnez un groupe",
            groupeList: [
                {
                    id: "admin",
                    name: "Administrateur",
                    val: "ROLE_ADMIN"
                },
                {
                    id: "homme_pays",
                    name: "Homme Pays",
                    val: "ROLE_HP"
                },
                {
                    id: "qadmin",
                    name: "QueryAdmins",
                    val: "ROLE_QADMIN"
                },
                {
                    id: "delegue",
                    name: "Délégués",
                    val: "ROLE_DELEGUE"
                },
                {
                    id: "superviseur",
                    name: "Superviseurs",
                    val: "ROLE_SUPERVISEUR"
                },
                {
                    id: "directeur_pays",
                    name: "Directeur Pays",
                    val: "ROLE_DP"
                },
                {
                    id: "district_sale_manager",
                    name: "District Sale Manager",
                    val: "ROLE_DSM"
                },
                {
                    id: "directeur_ventes",
                    name: "Directeur des Ventes",
                    val: "ROLE_DDV"
                },
                {
                    id: "moniteur",
                    name: "Moniteurs",
                    val: "ROLE_MONITEUR"
                },
                {
                    id: "support",
                    name: "Supports",
                    val: "ROLE_SUPPORT"
                },
                {
                    id: "geolocation",
                    name: "Géolocalisation",
                    val: "ROLE_GEOLOCATION"
                },
                {
                    id: "operator",
                    name: "Opérateur",
                    val: "ROLE_OPERATOR"
                }
            ],
            role: "",
            emailAsUsername: false,
            username: "",
            email: "",
            pwd: "",
            pwdConfirm: "",
            countryRestrictedList: [],
            country: "",
            countryRestricted: "",
            modifMdp: false,
            selectedCountry: {
                name: "Sélectionnez un pays"
            },
            changePwd: false
        }

        this.state = this.initialState;
    }

    componentDidMount() {
        this.props.getAllCountries();
    }

    componentDidUpdate(prevProps) {
        if((this.props.loading !== prevProps.loading) && !this.props.loading) {
            if(this.props.countries !== undefined){
                this.initialState = {
                    ...this.initialState,
                    countryList: JSON.parse(this.props.countries)
                };
                this.setState(this.initialState);
            }
        }

        if(this.props.user !== prevProps.user) {

            const edit = Object.keys(this.props.user).length > 0;
            const grp = edit ? this.state.groupeList.map((r) => r.val).indexOf(this.props.user.roles[0]) : 0;

            this.initialState = {
                ...this.initialState,
                lastName: edit ? this.props.user.lastName : "",
                firstName: edit ? this.props.user.firstName : "",
                role: edit ? this.state.groupeList[grp].id : "",
                groupe: edit ? this.state.groupeList[grp].name : "Sélectionnez un groupe",
                emailAsUsername: edit ? this.props.user.email === this.props.user.username ? true : false : false,
                username: edit ? this.props.user.username : "",
                email: edit ? this.props.user.email !== null ? this.props.user.email : "" : "",
                pwd: "",
                pwdConfirm: "",
                countryRestrictedList: edit ? this.props.user.restrictedArea : [],
                country: "",
                countryRestricted: "",
                modifMdp: false,
                selectedCountry: edit ? {
                    id: this.props.user.restrictedArea[0] ? this.props.user.restrictedArea[0].idArea : null,
                    name: this.props.user.restrictedArea[0] ? this.props.user.restrictedArea[0].nameArea : "Sélectionnez un pays"
                } : { name: "Sélectionnez un pays" }
            }

            this.setState(this.initialState);
        }

        if((this.props.userPosted !== prevProps.userPosted) && this.props.userPosted) {
            const data = JSON.parse(this.props.userPostedData);
            if(data.code === 200) this.btnElement.click();
        }
        if((this.props.userEdited !== prevProps.userEdited) && this.props.userEdited) {
            const data = JSON.parse(this.props.userEditedData);
            if(data.code === "200") this.btnElement.click();
        }
    }

    handleSubmit = (e) => {
        if(this.state.role !== "") {
            if((this.state.lastName !== "") && (this.state.firstName !== "")) {

                let pays =
                    (this.state.role === "homme_pays") 
                    || (this.state.role === "directeur_ventes") 
                    || (this.state.role === "support") 
                    || (this.state.role === "operator") 
                    || (this.state.role === "geolocation");
                let delegue = this.state.role === "delegue" && this.state.selectedCountry.id;

                if(Object.keys(this.props.user).length > 0) {   //edit user

                    if(!this.state.changePwd || (this.state.changePwd && this.state.pwd.length >= 5 && this.state.pwd === this.state.pwdConfirm)) {
                        if(this.state.emailAsUsername && this.state.email !== "") {
                            e.preventDefault();
    
                            this.props.editUser({
                                id: this.props.user.id,
                                role: this.state.role,
                                lastName: this.state.lastName,
                                firstName: this.state.firstName,
                                username: this.state.email,
                                email: this.state.email,
                                isLoginEqualsEmail: true,
                                password: this.state.pwd,
                                changePwd: this.state.changePwd,
                                area: pays ? this.state.countryRestrictedList.map((c) => c.idArea) : delegue ? [this.state.selectedCountry.id] : []
                            });
                            
                            //this.btnElement.click()
                        } else if(this.state.username !== "") {
                            e.preventDefault();
    
                            this.props.editUser({
                                id: this.props.user.id,
                                role: this.state.role,
                                lastName: this.state.lastName,
                                firstName: this.state.firstName,
                                username: this.state.username,
                                email: this.state.email,
                                isLoginEqualsEmail: false,
                                password: this.state.pwd,
                                changePwd: this.state.changePwd,
                                area: pays ? this.state.countryRestrictedList.map((c) => c.idArea) : delegue ? [this.state.selectedCountry.id] : []
                            });
                            
                            //this.btnElement.click()
                        }
                    }

                } else {    //post user

                    if((this.state.pwd.length >= 5) && (this.state.pwd === this.state.pwdConfirm)) { //check mot de passe
    
                        if(this.state.emailAsUsername && (this.state.email !== "")) {
                            e.preventDefault();
    
                            this.props.addUser({
                                role: this.state.role,
                                lastName: this.state.lastName,
                                firstName: this.state.firstName,
                                username: this.state.email,
                                email: this.state.email,
                                password: this.state.pwd,
                                isLoginEqualsEmail: true,
                                area: pays ? this.state.countryRestrictedList.map((c) => c.idArea) : delegue ? [this.state.selectedCountry.id] : []
                            });
                            
                            //this.btnElement.click()
                        } else if(this.state.username !== "") {
                            e.preventDefault();

                            this.props.addUser({
                                role: this.state.role,
                                lastName: this.state.lastName,
                                firstName: this.state.firstName,
                                username: this.state.username,
                                email: this.state.email,
                                password: this.state.pwd,
                                isLoginEqualsEmail: false,
                                area: pays ? this.state.countryRestrictedList.map((c) => c.idArea) : delegue ? [this.state.selectedCountry.id] : []
                            });
                            
                            //this.btnElement.click()
                        }
    
                    } else if(this.state.pwd !== this.state.pwdConfirm) {
                        e.preventDefault();
                        //msg erreur mdp pas identiques
                    }

                }

            }
        } else {
            e.preventDefault();
            //msg erreur pas de groupe
        }
    }

    handleCountryRestriction = () => {
        let cP = this.state.countryList.slice();
        let cR = this.state.countryRestrictedList.slice();

        if(this.state.country !== "") {
            let selectC = cP.map((c) => c.name).indexOf(this.state.country);

            cR.push({
                idArea: cP[selectC].id,
                nameArea: cP[selectC].name
            });

            this.setState({
                ...this.state,
                country: "",
                countryList: cP,
                countryRestricted: "",
                countryRestrictedList: cR
            })
        }
    }

    handleCountryPresence = () => {
        let cP = this.state.countryList.slice();
        let cR = this.state.countryRestrictedList.slice();

        if(this.state.countryRestricted !== "") {
            let selectC = cR.map((c) => c.nameArea).indexOf(this.state.countryRestricted);

            if(selectC !== -1) cR.splice(selectC, 1);

            this.setState({
                ...this.state,
                country: "",
                countryList: cP,
                countryRestricted: "",
                countryRestrictedList: cR
            })
        }
    }

    render() {
        const edit = Object.keys(this.props.user).length > 0;
        const cP = this.state.countryList.slice();
        const cR = this.state.countryRestrictedList.slice();
        cR.map((cRes) => {
            let selectC = cP.map((c) => c.id).indexOf(cRes.idArea);
            if(selectC !== -1) cP.splice(selectC, 1);
        });

        return(
            <div className="modal fade in" id="modalAddEditUser" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditUserLabel" animate="animate" onClick={() => this.btnElement.click()}>
                <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un utilisateur</h1>
                        </div>
                        <div className="modal-body">
                            <form id="formAjout">

                                <label>Groupe</label>
                                <CustomSelect
                                defaultText={this.state.groupe}
                                optionsList={this.state.groupeList}
                                handleOptionClick={(role, name) => this.setState({ role: role })}
                                />

                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Nom</label>
                                        <input 
                                        type="text" 
                                        required 
                                        value={this.state.lastName} 
                                        onChange={(e) => this.setState({lastName: e.target.value})}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <label>Prénom</label>
                                        <input 
                                        type="text" 
                                        required 
                                        value={this.state.firstName} 
                                        onChange={(e) => this.setState({firstName: e.target.value})}
                                        />
                                    </div>
                                </div>

                                <div className="check">
                                    <input
                                    type="checkbox"
                                    id="emailAsUsername"
                                    checked={this.state.emailAsUsername}
                                    onChange={() => this.setState({emailAsUsername: !this.state.emailAsUsername})}
                                    />
                                    <label htmlFor="emailAsUsername">Utiliser son courriel comme identifiant</label>
                                </div>

                                {!this.state.emailAsUsername &&
                                    <div>
                                        <label>Identifiant</label>
                                        <input 
                                        type="text" 
                                        required 
                                        value={this.state.username} 
                                        onChange={(e) => this.setState({username: e.target.value})}
                                        />
                                    </div>
                                }

                                <label>Adresse email</label>
                                <input 
                                type="text" 
                                required={this.state.emailAsUsername} 
                                value={this.state.email} 
                                onChange={(e) => this.setState({email: e.target.value})}
                                />

                                {!edit ? (
                                    <div className="row">
                                        <div className="col-md-6">
                                            <label>Mot de passe (au moins 5 caractères)</label>
                                            <input 
                                            type="password" 
                                            required 
                                            minLength="5" 
                                            value={this.state.pwd} 
                                            onChange={(e) => this.setState({pwd: e.target.value})}
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label>Confirmez le mot de passe</label>
                                            <input 
                                            type="password" 
                                            required
                                            minLength="5" 
                                            value={this.state.pwdConfirm} 
                                            onChange={(e) => this.setState({pwdConfirm: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className="check">
                                            <input
                                            type="checkbox"
                                            id="changePwd"
                                            checked={this.state.changePwd}
                                            onChange={() => this.setState({changePwd: !this.state.changePwd})}
                                            />
                                            <label htmlFor="changePwd">Je veux également modifier le mot de passe</label>
                                        </div>
                                        {this.state.changePwd && (
                                        <div className="row">
                                            <div className="col-md-6">
                                                <label>Mot de passe (au moins 5 caractères)</label>
                                                <input 
                                                type="password" 
                                                required 
                                                minLength="5" 
                                                value={this.state.pwd} 
                                                onChange={(e) => this.setState({pwd: e.target.value})}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label>Confirmez le mot de passe</label>
                                                <input 
                                                type="password" 
                                                required 
                                                minLength="5" 
                                                value={this.state.pwdConfirm} 
                                                onChange={(e) => this.setState({pwdConfirm: e.target.value})}
                                                />
                                            </div>
                                        </div>
                                        )}
                                    </>
                                )}

                                {
                                (this.state.role === "homme_pays") 
                                || (this.state.role === "directeur_ventes") 
                                || (this.state.role === "support") 
                                || (this.state.role === "operator") 
                                || (this.state.role === "geolocation") 
                                ? (
                                    <div className="row">
                                        <div className="col-md-5">
                                            <label>Pays disponibles</label>
                                            <select className="listSelect" size="5" value={this.state.country} onChange={(e) => this.setState({country: e.target.value})}>
                                                <option value=""></option>
                                                {cP.map((country, i) => (
                                                    <option key={i} value={country.name}>{country.name}</option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-md-2">
                                            <label>Actions</label>
                                            <button type="button" onClick={this.handleCountryRestriction}>&rarr;</button>
                                            <button type="button" onClick={this.handleCountryPresence}>&larr;</button>
                                        </div>
                                        <div className="col-md-5">
                                            <label>Pays dont l'accès est global</label>
                                            <select className="listSelect" size="5" value={this.state.countryRestricted} onChange={(e) => this.setState({countryRestricted: e.target.value})}>
                                                <option value=""></option>
                                                {this.state.countryRestrictedList.map((country, i) => (
                                                    <option key={i} value={country.name}>{country.name || country.nameArea}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                ) : this.state.role === "delegue" && (
                                    <div>
                                        <label>Pays</label>
                                        <CustomSelect
                                        defaultText={this.state.selectedCountry.name}
                                        optionsList={this.state.countryList}
                                        handleOptionClick={(id, name) => this.setState({selectedCountry: {
                                            id: id,
                                            name: name
                                        }})}
                                        />
                                    </div>
                                )}

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button 
                            type="submit" 
                            form="formAjout" 
                            className="btn-save"
                            data-toggle={ Object.keys(this.props.user).length > 0 ? 'modal' : '' }
                            data-target={'#modalConfirmation'}
                            disabled={!(
                                (this.state.role !== "") && (this.state.lastName !== "") && (this.state.firstName !== "") &&
                                ( (this.state.emailAsUsername && this.state.email !== "") || (this.state.username !== "" && !this.state.emailAsUsername) ) &&
                                ( !this.state.changePwd || (this.state.changePwd && this.state.pwd.length >= 5 && this.state.pwd === this.state.pwdConfirm) )
                                &&
                                (
                                    (Object.keys(this.props.user).length > 0)
                                    ||
                                    ((this.state.pwd.length >= 5) && (this.state.pwd === this.state.pwdConfirm))
                                )
                            )}
                            onClick={(e) => {
                                e.preventDefault();
                                if(Object.keys(this.props.user).length > 0) this.props.handleSubmit(this.handleSubmit);
                                else this.handleSubmit(e);
                            }}>
                                <img src="/dist/img/icons/icon-save.png" alt=""/>
                                Sauvegarder
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={button => this.btnElement = button}
                            onClick={() => this.setState(this.initialState)}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { loading, countries } = state.zoneGeo;
    const { userPosted, userEdited, userPostedData, userEditedData } = state.users;
    return { loading, countries, userPosted, userEdited, userPostedData, userEditedData };
}
const actionCreators = {
    getAllCountries: zoneGeoActions.getAllCountries,
};

const PopupAddEditUser = connect(mapState, actionCreators)(PopupAddEditUserPage);
export default PopupAddEditUser;