import { holidayConstants } from '../constants/holiday.constants';
import { holidayService } from '../services/holiday.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const holidayActions = {
    getHolidays,
    postHoliday,
    deleteHoliday
};

function getHolidays(idCountry) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        holidayService.getHolidays(idCountry)
            .then(
                holidays => {
                    dispatch(success(holidays));   
                    dispatch(hideLoading());             
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: holidayConstants.GET_HOLIDAY_BY_COUNTRY_REQUEST } }
    function success(holidays) { return { type: holidayConstants.GET_HOLIDAY_BY_COUNTRY_SUCCESS, holidays } }
    function failure(error) { return { type: holidayConstants.GET_HOLIDAY_BY_COUNTRY_FAILURE, error } }
}

function postHoliday(idCountry, date, desc) {
    return (dispatch) => {

        dispatch(request());
        
        holidayService.postHoliday(idCountry, date, desc)
            .then(
                posted => {
                    dispatch(success(posted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: holidayConstants.POST_HOLIDAY_REQUEST } }
    function success(posted) { return { type: holidayConstants.POST_HOLIDAY_SUCCESS, posted } }
    function failure(error) { return { type: holidayConstants.POST_HOLIDAY_FAILURE, error } }
}

function deleteHoliday(id) {
    return (dispatch) => {

        dispatch(request());
        
        holidayService.deleteHoliday(id)
            .then(
                deleted => {
                    dispatch(success(deleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: holidayConstants.DELETE_HOLIDAY_REQUEST } }
    function success(deleted) { return { type: holidayConstants.DELETE_HOLIDAY_SUCCESS, deleted } }
    function failure(error) { return { type: holidayConstants.DELETE_HOLIDAY_FAILURE, error } }
}