import React from 'react';
import { connect } from 'react-redux';
import { etabActions } from '../actions/etab.actions';
import { zoneGeoActions } from '../actions/zoneGeo.actions';
import { medicalProActions } from '../actions/medicalPro.actions';
import { NavLink } from "react-router-dom";

class NavigationMenuPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showParam: false,
            InvalidateEtab : 0,
            InvalidatePro: 0, 
            country : -1,
            roleUser: this.props.roleUser,
        }
    }

    componentDidMount(){
        this.props.getUserCountry();
    }

    componentDidUpdate(prevProps, prevState) {

        if((this.props.idCountry !== prevProps.idCountry) && (this.props.idCountry !== undefined)) {
            this.props.getProInvalidate(this.props.idCountry);
            this.props.getEtabInvalidate(this.props.idCountry);
            this.setState({ country : this.props.idCountry });
        }

        if(this.props.roleUser !== prevProps.roleUser) this.setState({ roleUser: this.props.roleUser });

        /*if(this.state.country !== localStorage.getItem('idCountry')){
            this.setState({
                country : localStorage.getItem('idCountry')
            })
            this.props.getProInvalidate(localStorage.getItem('idCountry'))
            this.props.getEtabInvalidate(localStorage.getItem('idCountry'))
        }*/

        if((this.props.loadingInvalidateEtab !== prevProps.loadingInvalidateEtab) && !this.props.loadingInvalidateEtab) {
            if(this.props.invalidate !== undefined && this.props.invalidate !== "null"){
                this.setState({ InvalidateEtab : JSON.parse(this.props.invalidate).numbreOfInvalidatedInstitution });
            }
        }

        if((this.props.loadingInvalidatePro !== prevProps.loadingInvalidatePro) && !this.props.loadingInvalidatePro) {
            if(this.props.invalidatePro !== undefined && this.props.invalidatePro !== "null"){
                this.setState({ InvalidatePro : JSON.parse(this.props.invalidatePro).numbreOfInvalidatedMedicalPro });
            }
        }

    }
    render() {
        const { InvalidateEtab, InvalidatePro } =  this.state;

        return(
            <aside className="main-sidebar">
                {/* sidebar: style can be found in sidebar.less */}
                <section className="sidebar" >
                    {/* sidebar menu: : style can be found in sidebar.less */}
                    <ul className="sidebar-menu tree" data-widget="tree">
                        <li>
                            <NavLink exact to="/">
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-dashboard.png" alt=""/>
                                </div>
                                <span>Tableau de bord</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/etablissements">
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-hopital.png" alt=""/>
                                </div>
                                <span className="invalide">{InvalidateEtab}</span>
                                <span>Établissements</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/professionnels">
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-pds.png" alt=""/>
                                </div>
                                <span className="invalide">{InvalidatePro}</span>
                                <span>Professionnels de santé</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/institutions/check-list">
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-globe.png" alt=""/>
                                </div>
                                <span>Relevé des coordonnées</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/listings">
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-doc.png" alt=""/>
                                </div>
                                <span>Listings</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/jours-feries">
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-calendrier.png" alt=""/>
                                </div>
                                <span>Jours fériés</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/historique">
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-historique.png" alt=""/>
                                </div>
                                <span>Historique</span>
                            </NavLink>
                        </li>
                        <li className={`treeview-tree`} onClick={() => this.setState({showParam: !this.state.showParam})}>
                            <a href="#" onClick={(e) => e.preventDefault()}>
                                <div className="icon">
                                    <img src="/dist/img/icons/icon-param.png" alt=""/>
                                </div>
                                <span>Paramétrage</span>
                                <span className="pull-right-container">
                                    <i className={`fa fa-angle-${this.state.showParam ? "down" : "left"} pull-right`}></i>
                                </span>
                            </a>
                            <ul className="treeview--menu" style={{display: 'block', height: this.state.showParam ? "180px" : "0px"}}>
                                <li><NavLink to="/specialites"><i className="fa fa-circle-o"></i> Spécialités</NavLink></li>
                                <li><NavLink to="/logiciels"><i className="fa fa-circle-o"></i> Logiciels</NavLink></li>
                                <li><NavLink to="/zones-geographiques"><i className="fa fa-circle-o"></i> Zones géographiques</NavLink></li>
                                <li><NavLink to="/types-etablissement"><i className="fa fa-circle-o"></i> Types d'établissement</NavLink></li>
                                <li><NavLink to="/types-pds"><i className="fa fa-circle-o"></i> Types de PDS</NavLink></li>
                                <li><NavLink to="/utilisateurs"><i className="fa fa-circle-o"></i> Utilisateurs</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                </section>
                {/* /.sidebar */}
            </aside>
        )
    }
}

function mapState(state) {
    const { idCountry, country, loadingUserCountry, userCountry } = state.zoneGeo;
    const { loadingInvalidateEtab, invalidate} = state.etab;
    const { loadingInvalidatePro, invalidatePro} = state.medicalPro;
    return { loadingUserCountry, userCountry, idCountry, country,  loadingInvalidatePro, invalidatePro, loadingInvalidateEtab, invalidate};
}
const actionCreators = {
    getUserCountry: zoneGeoActions.getUserCountry,
    getProInvalidate: medicalProActions.getProInvalidate,
    getEtabInvalidate: etabActions.getEtabInvalidate
};
const NavigationMenu = connect(mapState, actionCreators)(NavigationMenuPage);

export default NavigationMenu;