import { medicalProConstants } from '../constants/medicalPro.constants';
import { medicalProService } from '../services/medicalPro.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const medicalProActions = {
    getMedicalPros,
    postMedicalPro,
    editMedicalPro,
    postEtabPro,
    editEtabPro,
    disableEtabPro,
    getOnePDS,
    getSpePro,
    postSpePro,
    disableSpePro,
    deleteSpePro,
    getLogiciels,
    getLogPro,
    postLogPro,
    disableLogPro,
    deleteLogPro,
    deleteMedicalPro,
    validateMedicalPro,
    getSpeByType,
    deleteEtabPro,
    linkPros,
    verifPro,
    getProInvalidate,
    getHistoPDS,
    unvalidateMedicalPro
};

function getSpeByType(idType) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.getSpeByType(idType)
            .then(
                speByType => {
                    dispatch(success(speByType));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_SPE_TYPE_REQUEST } }
    function success(speByType) { return { type: medicalProConstants.GET_SPE_TYPE_SUCCESS, speByType } }
    function failure(error) { return { type: medicalProConstants.GET_SPE_TYPE_FAILURE, error } }
}

function getProInvalidate(data) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.getProInvalidate(data)
            .then(
                invalidate => {
                    dispatch(success(invalidate));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_PRO_INVALIDATE_REQUEST } }
    function success(invalidate) { return { type: medicalProConstants.GET_PRO_INVALIDATE_SUCCESS, invalidate } }
    function failure(error) { return { type: medicalProConstants.GET_PRO_INVALIDATE_FAILURE, error } }
}

function verifPro(data) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        medicalProService.verifPro( data)
            .then(
                verif => {
                    dispatch(success(verif));
                    dispatch(hideLoading());
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_VERFIF_PRO_REQUEST } }
    function success(verif) { return { type: medicalProConstants.GET_VERFIF_PRO_SUCCESS, verif } }
    function failure(error) { return { type: medicalProConstants.GET_VERFIF_PRO_FAILURE, error } }
}

function getMedicalPros(idCountry, data) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        medicalProService.getMedicalPros(idCountry, data)
            .then(
                listMedicalPros => {
                    dispatch(success(listMedicalPros));         
                    dispatch(hideLoading());       
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_MEDICAL_PRO_REQUEST } }
    function success(listMedicalPros) { return { type: medicalProConstants.GET_MEDICAL_PRO_SUCCESS, listMedicalPros } }
    function failure(error) { return { type: medicalProConstants.GET_MEDICAL_PRO_FAILURE, error } }
}

function postMedicalPro(pro) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.postMedicalPro(pro)
            .then(
                posted => {
                    dispatch(success(posted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.POST_MEDICAL_PRO_REQUEST } }
    function success(posted) { return { type: medicalProConstants.POST_MEDICAL_PRO_SUCCESS, posted } }
    function failure(error) { return { type: medicalProConstants.POST_MEDICAL_PRO_FAILURE, error } }
}

function editMedicalPro(pro) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.editMedicalPro(pro)
            .then(
                edited => {
                    dispatch(success(edited));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.EDIT_MEDICAL_PRO_REQUEST } }
    function success(edited) { return { type: medicalProConstants.EDIT_MEDICAL_PRO_SUCCESS, edited } }
    function failure(error) { return { type: medicalProConstants.EDIT_MEDICAL_PRO_FAILURE, error } }
}

function deleteMedicalPro(idPro) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.deleteMedicalPro(idPro)
            .then(
                deleted => {
                    dispatch(success(deleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.DELETE_MEDICAL_PRO_REQUEST } }
    function success(deleted) { return { type: medicalProConstants.DELETE_MEDICAL_PRO_SUCCESS, deleted } }
    function failure(error) { return { type: medicalProConstants.DELETE_MEDICAL_PRO_FAILURE, error } }
}

function validateMedicalPro(idPro, isEdit) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.validateMedicalPro(idPro, isEdit)
            .then(
                validated => {
                    dispatch(success(validated));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.VALIDATE_MEDICAL_PRO_REQUEST } }
    function success(validated) { return { type: medicalProConstants.VALIDATE_MEDICAL_PRO_SUCCESS, validated } }
    function failure(error) { return { type: medicalProConstants.VALIDATE_MEDICAL_PRO_FAILURE, error } }
}

function unvalidateMedicalPro(idPro, isEdit) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.unvalidateMedicalPro(idPro, isEdit)
            .then(
                unvalidated => {
                    dispatch(success(unvalidated));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.UNVALIDATE_MEDICAL_PRO_REQUEST } }
    function success(unvalidated) { return { type: medicalProConstants.UNVALIDATE_MEDICAL_PRO_SUCCESS, unvalidated } }
    function failure(error) { return { type: medicalProConstants.UNVALIDATE_MEDICAL_PRO_FAILURE, error } }
}

function linkPros(id1, id2) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.linkPros(id1, id2)
            .then(
                prosLinked => {
                    dispatch(success(prosLinked));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.LINK_PROS_REQUEST } }
    function success(prosLinked) { return { type: medicalProConstants.LINK_PROS_SUCCESS, prosLinked } }
    function failure(error) { return { type: medicalProConstants.LINK_PROS_FAILURE, error } }
}

function postEtabPro(idPro, etab) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.postEtabPro(idPro, etab)
            .then(
                etabProPosted => {
                    dispatch(success(etabProPosted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.POST_ETAB_PRO_REQUEST } }
    function success(etabProPosted) { return { type: medicalProConstants.POST_ETAB_PRO_SUCCESS, etabProPosted } }
    function failure(error) { return { type: medicalProConstants.POST_ETAB_PRO_FAILURE, error } }
}

function editEtabPro(idPro, etab) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.editEtabPro(idPro, etab)
            .then(
                etabProEdited => {
                    dispatch(success(etabProEdited));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.EDIT_ETAB_PRO_REQUEST } }
    function success(etabProEdited) { return { type: medicalProConstants.EDIT_ETAB_PRO_SUCCESS, etabProEdited } }
    function failure(error) { return { type: medicalProConstants.EDIT_ETAB_PRO_FAILURE, error } }
}

function disableEtabPro(idPro, idEtab) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.disableEtabPro(idPro, idEtab)
            .then(
                etabProDisabled => {
                    dispatch(success(etabProDisabled));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.DISABLE_ETAB_PRO_REQUEST } }
    function success(etabProDisabled) { return { type: medicalProConstants.DISABLE_ETAB_PRO_SUCCESS, etabProDisabled } }
    function failure(error) { return { type: medicalProConstants.DISABLE_ETAB_PRO_FAILURE, error } }
}

function deleteEtabPro(idPro, idEtab) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.deleteEtabPro(idPro, idEtab)
            .then(
                etabProDeleted => {
                    dispatch(success(etabProDeleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.DELETE_ETAB_PRO_REQUEST } }
    function success(etabProDeleted) { return { type: medicalProConstants.DELETE_ETAB_PRO_SUCCESS, etabProDeleted } }
    function failure(error) { return { type: medicalProConstants.DELETE_ETAB_PRO_FAILURE, error } }
}

function getOnePDS(id) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.getOnePDS(id)
            .then(
                medicalPro => {
                    dispatch(success(medicalPro));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_ONE_PRO_REQUEST } }
    function success(medicalPro) { return { type: medicalProConstants.GET_ONE_PRO_SUCCESS, medicalPro } }
    function failure(error) { return { type: medicalProConstants.GET_ONE_PRO_FAILURE, error } }
}

function getHistoPDS(id) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.getHistoPDS(id)
            .then(
                histo => {
                    dispatch(success(histo));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_HISTO_PRO_REQUEST } }
    function success(histo) { return { type: medicalProConstants.GET_HISTO_PRO_SUCCESS, histo } }
    function failure(error) { return { type: medicalProConstants.GET_HISTO_PRO_FAILURE, error } }
}

//SPECIALITES

function getSpePro(idPro) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.getSpePro(idPro)
            .then(
                listSpePro => {
                    dispatch(success(listSpePro));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_SPE_PRO_REQUEST } }
    function success(listSpePro) { return { type: medicalProConstants.GET_SPE_PRO_SUCCESS, listSpePro } }
    function failure(error) { return { type: medicalProConstants.GET_SPE_PRO_FAILURE, error } }
}

function postSpePro(idPro, idSpe) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.postSpePro(idPro, idSpe)
            .then(
                speProPosted => {
                    dispatch(success(speProPosted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.POST_SPE_PRO_REQUEST } }
    function success(speProPosted) { return { type: medicalProConstants.POST_SPE_PRO_SUCCESS, speProPosted } }
    function failure(error) { return { type: medicalProConstants.POST_SPE_PRO_FAILURE, error } }
}

function disableSpePro(idPro, idSpe) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.disableSpePro(idPro, idSpe)
            .then(
                speProDisabled => {
                    dispatch(success(speProDisabled));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.DISABLE_SPE_PRO_REQUEST } }
    function success(speProDisabled) { return { type: medicalProConstants.DISABLE_SPE_PRO_SUCCESS, speProDisabled } }
    function failure(error) { return { type: medicalProConstants.DISABLE_SPE_PRO_FAILURE, error } }
}

function deleteSpePro(idPro, idSpe) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.deleteSpePro(idPro, idSpe)
            .then(
                speProDeleted => {
                    dispatch(success(speProDeleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.DELETE_SPE_PRO_REQUEST } }
    function success(speProDeleted) { return { type: medicalProConstants.DELETE_SPE_PRO_SUCCESS, speProDeleted } }
    function failure(error) { return { type: medicalProConstants.DELETE_SPE_PRO_FAILURE, error } }
}

//LOGICIELS

function getLogiciels() {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.getLogiciels()
            .then(
                listLogiciels => {
                    dispatch(success(listLogiciels));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_ALL_LOG_REQUEST } }
    function success(listLogiciels) { return { type: medicalProConstants.GET_ALL_LOG_SUCCESS, listLogiciels } }
    function failure(error) { return { type: medicalProConstants.GET_ALL_LOG_FAILURE, error } }
}

function getLogPro(idPro) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.getLogPro(idPro)
            .then(
                listLogPro => {
                    dispatch(success(listLogPro));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.GET_LOG_PRO_REQUEST } }
    function success(listLogPro) { return { type: medicalProConstants.GET_LOG_PRO_SUCCESS, listLogPro } }
    function failure(error) { return { type: medicalProConstants.GET_LOG_PRO_FAILURE, error } }
}

function postLogPro(idPro, idLog) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.postLogPro(idPro, idLog)
            .then(
                logProPosted => {
                    dispatch(success(logProPosted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.POST_LOG_PRO_REQUEST } }
    function success(logProPosted) { return { type: medicalProConstants.POST_LOG_PRO_SUCCESS, logProPosted } }
    function failure(error) { return { type: medicalProConstants.POST_LOG_PRO_FAILURE, error } }
}

function disableLogPro(idPro, idLog) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.disableLogPro(idPro, idLog)
            .then(
                logProDisabled => {
                    dispatch(success(logProDisabled));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.DISABLE_LOG_PRO_REQUEST } }
    function success(logProDisabled) { return { type: medicalProConstants.DISABLE_LOG_PRO_SUCCESS, logProDisabled } }
    function failure(error) { return { type: medicalProConstants.DISABLE_LOG_PRO_FAILURE, error } }
}

function deleteLogPro(idPro, idLog) {
    return (dispatch) => {

        dispatch(request());
        
        medicalProService.deleteLogPro(idPro, idLog)
            .then(
                logProDeleted => {
                    dispatch(success(logProDeleted));                
                },
                error => {
                    dispatch(failure(error.toString()));
                }
            );
    };

    function request() { return { type: medicalProConstants.DELETE_LOG_PRO_REQUEST } }
    function success(logProDeleted) { return { type: medicalProConstants.DELETE_LOG_PRO_SUCCESS, logProDeleted } }
    function failure(error) { return { type: medicalProConstants.DELETE_LOG_PRO_FAILURE, error } }
}