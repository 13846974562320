import React from 'react';

class ButtonExport extends React.Component {

    handleClick = () => {
        //action
    }

    render() {
        return(
            <button
            className="btn-export"
            onClick={this.handleClick}
            >
                <img src="/dist/img/icons/icon-export.png" alt=""/>
                Tout exporter
            </button>
        )
    }
}

export default ButtonExport;