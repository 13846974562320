import { historyConstants } from '../constants/history.constants';
import { historyService } from '../services/history.service';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

export const historyActions = {
    getHistory
};

function getHistory(data) {
    return (dispatch) => {

        dispatch(request());
        dispatch(showLoading());
        
        historyService.getHistory(data)
            .then(
                history => {
                    dispatch(success(history));   
                    dispatch(hideLoading());             
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(hideLoading());
                }
            );
    };

    function request() { return { type: historyConstants.GET_HISTORY_REQUEST } }
    function success(history) { return { type: historyConstants.GET_HISTORY_SUCCESS, history } }
    function failure(error) { return { type: historyConstants.GET_HISTORY_FAILURE, error } }
}