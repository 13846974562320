import { config } from '../constants/app.constants';
import { authHeader } from '../helpers/auth-header';
import axios from 'axios';

export const usersService = {
    getAllUsers,
    disableUser,
    postUser,
    deleteUser,
    editUser
};


function getAllUsers(data) {

    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if(data !== undefined){
                params = {                    
                    page: (data.page !== undefined) ? data.page : 1,
                    maxPerPage: (data.maxPerPage !== undefined) ? data.maxPerPage : 50,
                    search : (data.search !== undefined && data.search !== "") ? data.search : "",
                    firstname : (data.firstname !== undefined && data.firstname !== "") ? data.firstname : "",
                    lastname : (data.lastname !== undefined && data.lastname !== "") ? data.lastname : "",
                    username : (data.username !== undefined && data.username !== "") ? data.username : ""
                }
            }
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/list/userdatafrica', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function postUser(user) {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/userdatafrica', {
                    "roles": user.role,
                    "lastName": user.lastName,
                    "firstName": user.firstName,
                    "username": user.username,
                    "email": user.email === "" ? null : user.email,
                    "password": user.password,
                    "accessDemo": user.accessDemo,
                    "area": user.area,
                    "isLoginEqualsEmail": user.isLoginEqualsEmail
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function editUser(user) {
    
    return new Promise((resolve, reject) => {
        try {
            let params = {};
            if(user !== undefined){
                params = {                    
                    "roles": user.role,
                    "lastName": user.lastName,
                    "firstName": user.firstName,
                    "username": user.username,
                    "email": user.email === "" ? null : user.email,
                    "accessDemo": user.accessDemo,
                    "area": user.area,
                    "isLoginEqualsEmail": user.isLoginEqualsEmail
                }
            }
            if(user.changePwd) params = Object.assign(params, { "password": user.password });
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/userdatafrica/'+user.id, params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteUser(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/userdatafrica/delete/'+id, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function disableUser(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/userdatafrica/disable/'+id, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}