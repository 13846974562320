export const secteursConstants = {   
    GET_ALL_COUNTRIES_REQUEST: 'COUNTRIES_GETALL_REQUEST',
    GET_ALL_COUNTRIES_SUCCESS: 'COUNTRIES_GETALL_SUCCESS',
    GET_ALL_COUNTRIES_FAILURE: 'COUNTRIES_GETALL_FAILURE',

    GET_BY_COUNTRY_REQUEST: 'GET_SECTORS_BY_COUNTRY_REQUEST',
    GET_BY_COUNTRY_SUCCESS: 'GET_SECTORS_BY_COUNTRY_SUCCESS',
    GET_BY_COUNTRY_FAILURE: 'GET_SECTORS_BY_COUNTRY_FAILURE',

    POST_SECTEUR_REQUEST: 'POST_SECTEUR_REQUEST',
    POST_SECTEUR_SUCCESS: 'POST_SECTEUR_SUCCESS',
    POST_SECTEUR_FAILURE: 'POST_SECTEUR_FAILURE',

    EDIT_SECTEUR_REQUEST: 'EDIT_SECTEUR_REQUEST',
    EDIT_SECTEUR_SUCCESS: 'EDIT_SECTEUR_SUCCESS',
    EDIT_SECTEUR_FAILURE: 'EDIT_SECTEUR_FAILURE',

    DELETE_SECTEUR_REQUEST: 'DELETE_SECTEUR_REQUEST',
    DELETE_SECTEUR_SUCCESS: 'DELETE_SECTEUR_SUCCESS',
    DELETE_SECTEUR_FAILURE: 'DELETE_SECTEUR_FAILURE',
};