import React from 'react';
import Select from 'react-select';
import ReactLoading from 'react-loading';

class PopupLinkEtab extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            etab: {},
            rattach: null,
            etabList: [],
            search: ""
        };

        this.timer = setTimeout(() => {}, 1);
    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.etab !== prevProps.etab) {
            this.setState({
                etab: this.props.etab,
                rattach: null,
                search: ""
            });
        }

        if(this.props.etabList !== prevProps.etabList) this.setState({ etabList: this.props.etabList });
    }

    handleSubmit = (e) => {
        if(Object.keys(this.state.etab).length !== 0) {
            e.preventDefault();
            this.props.linkEtabs(this.state.etab.id, this.state.rattach.value);
            this.btnElement.click()
        }
    }

    generateEtabOptions = () => {
        const etabs = this.state.etabList;
        const types = this.props.typeEtabList
        let table = [];

        types.map((t) => {
            table.push({
                label: t.name,
                options: []
            })
        })

        etabs.map((e) => {
            if(e !== this.state.etab) {
                table[table.map((g) => g.label).indexOf(e.InstitutionType.nameInstitutionType)].options.push(
                    {
                        value: e.id,
                        label: `${e.reference} ${e.name}`,
                        ref: e.reference,
                        name: e.name,
                        ad: e.zonesGeo.addresszoneGeo,
                        isPrivate: e.isPrivate
                    }
                )
            }
        });
        return table;
    }

    handleFilter = (value, key) => {
        clearTimeout(this.timer);
        this.setState({ [key]: value });
    
        this.timer = setTimeout(() => this.props.searchEtab(this.state.search), 1000);
    }

    render() {
        const formatGroupLabel = data => (<div><span>{data.label}</span></div>);
        
        return(
            <div className="modal fade in" id="modalLink" tabIndex="-1" role="dialog" aria-labelledby="modalLinkLabel" animate="animate">
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">Rattacher l'établissement à un autre</h1>
                        </div>
                        <div className="modal-body">
                            <div className="box alert">
                                <img src="/dist/img/icons/icon-info.png" alt=""/>
                                <h2>Opération irréversible !</h2>
                                <p>
                                Vous vous apprêtez à rattacher l'établissement <b>{this.props.etab.name}</b> ({this.props.etab.reference}) à un autre.<br/>
                                <b>Cette opération est irréversible.</b> Seuls les PDS de l'établissement seront transférés vers
                                l'établissement de rattachement. <b>Le reste des informations disparaîtra.</b>
                                </p>
                            </div>

                            <label style={{display: 'inline-block'}}>Etablissement de rattachement</label>
                            {this.props.loading && <ReactLoading type={"spinningBubbles"} color={"#0073bd"} height={20} width={20} className="spinner-loader etab" />}
                            <Select
                            options={this.props.typeEtabList.length !== 0 ? this.generateEtabOptions() : []}
                            formatGroupLabel={formatGroupLabel}
                            value={this.state.rattach}
                            onInputChange={(newValue) => this.handleFilter(newValue, "search")}
                            onChange={(newValue) => this.setState({ rattach: newValue })}
                            placeholder="Entrez une zone..."
                            formatOptionLabel={(option, { context }) => {
                                /* context can be either `menu` or `value`
                                    menu - dropdown
                                    value - value displayed
                                */
                                return context === 'menu' ? (
                                    <div className="select-react-option">
                                        <div>
                                            <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                                <p>
                                                    {option.isPrivate === 1 ? "Public"
                                                    : option.isPrivate === 2 ? "Privé"
                                                    : option.isPrivate === 3 ? "Semi-privé"
                                                    : "Inconnu"}
                                                </p>
                                            </div>
                                            <p>{option.ref} - {option.name}</p>
                                        </div>
                                        <small>{option.ad}</small>
                                    </div>
                                ) : option.name;
                            }}
                            isClearable
                            className="react-select-container"
                            />
                        </div>
                        <div className="modal-footer">
                            <button type="submit" form="formAjout" className="btn-save" onClick={this.handleSubmit}>
                                Rattacher
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={button => this.btnElement = button}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default PopupLinkEtab;