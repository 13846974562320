import React from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../actions/user.actions';
import { NotificationManager} from 'react-notifications';
import ReactLoading from 'react-loading';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        submitted: false,
        submittedPassword: false,
        errorLogin : false,
        forgotPassword: false,
        errorMessage: null,
        email: '',
        emailForgot: '',
        password: ''
    };
  }
  
  componentDidMount(){
    try { this.userLogged(); }
    catch(error) { console.log(error) } 
  }

  userLogged = () => {
      try {
        const user = localStorage.getItem("user");    
        if(user){
            if(JSON.parse(user).token){
                this.props.userLogin();
                this.props.navigation.navigate("/", {});
            } else {
                this.setState({ submitted: false });
                this.props.logout();
                NotificationManager.error('', "Error login");
            }
        } else if(this.state.submitted) this.setState({ errorLogin: true });    
      } catch (error) {
        if(this.state.submitted) this.setState({ errorLogin: true });
      }
  }

  componentDidUpdate(prevProps){
    if((this.props.loggedIn !== prevProps.loggedIn) && !this.props.loggingIn){
      NotificationManager.success("","Connecté");
      this.props.loginHistory();
      this.userLogged();
    }
  }

  handleForgotPassword = (event) => {
    event.preventDefault();
    this.setState({ submittedPassword: true });
    this.setState({ forgotPassword: !this.state.forgotPassword });
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitted: true });
    const { email, password } = this.state;
    if(email && password) this.props.login(email, password);
  }

  handleSubmitForgot = (event) => {
    event.preventDefault();
    this.setState({ submittedPassword: true });
    const { emailForgot } = this.state;
    if(emailForgot) this.props.forgotPassword(emailForgot);
  }

  render() {
    const { loggingIn, loggedIn } = this.props;
    const { email, password, submitted,submittedPassword,emailForgot, forgotPassword } = this.state;

    return (
      <section className="content" id="login-section">
          <div className="login-box">
            <div className="login-logo">
                <b>Data</b>frica
            </div>
            {!forgotPassword ? (
            <div className="login-box-body">
                <p className="login-box-msg" translate="">Connectez-vous à votre compte</p>
                <form onSubmit={this.handleSubmit}>

                    {(!loggingIn && !loggedIn && submitted) &&  
                        <p>Erreur de login ou mot de passe</p>
                    }   
                    <div className="input-group">
                        <input
                        type="text"
                        value={this.state.email}
                        
                        onChange={(event) => this.setState({email: event.target.value, submitted:false})}
                        placeholder="Identifiant"
                        required
                        />
                        <span className="input-group-addon"><i className="fa fa-user"></i></span>
                    </div>
                    {submitted && !email &&
                    <p>Identifiant requis</p>
                    }

                    <div className="input-group">
                        <input
                        type="password"
                        value={this.state.password}
                        onChange={(event) => this.setState({password: event.target.value, submitted:false})}
                        placeholder="Mot de passe"
                        required
                        />
                        <span className="input-group-addon"><i className="fa fa-user"></i></span>
                    </div>
                    {submitted && !password &&
                    <p>Mot de passe requis</p>
                    }
                    <div className="row">
                        <div className="col-xs-4 pull-right">
                            <button type="submit" className="btn btn-primary btn-block btn-flat" disabled={(email !== "" &&  password !== "" ? false : true)}>
                                {this.props.loggingIn ? 
                                <ReactLoading type={"spinningBubbles"} color={"#ffffff"} height={20} width={20} className="spinner-loader" />
                                :
                                <span>Connexion</span>
                                }
                            </button>
                        </div>
                    </div>
                </form>
                <p>
                    <span className="link_password" onClick={()=>this.handleForgotPassword}>J'ai oublié mon mot de passe</span>
                </p>
            </div>
            ) 
            : 
            (
                <div className="login-box-body">
                    <p className="login-box-msg" translate="">Si vous avez lié une adresse électronique avec votre compte, vous pouvez l'utiliser afin de changer votre mot de passe. Si ce n'est pas le cas ou si vous rencontrez des difficultés, contactez l'un des administrateurs de votre entreprise.</p>
                    <form onSubmit={this.handleSubmitForgot}>

                        {/* {(!loggingIn && !loggedIn && submitted) && 
                            <p>Erreur de login ou mot de passe</p>
                        }    */}
                        <div className="input-group">
                            <input
                            type="text"
                            value={emailForgot}
                            onChange={(event) => this.setState({emailForgot: event.target.value})}
                            placeholder="email"
                            required
                            />
                            <span className="input-group-addon"><i className="fa fa-envelope"></i></span>
                        </div>
                        {submittedPassword && !emailForgot &&
                        <p>Email requis</p>
                        }

                        <div className="row">
                            <div className="col-xs-4 pull-right">
                                <button  type="submit" className="btn btn-primary btn-block btn-flat" disabled={(emailForgot !== "" ? false : true)}>
                                    <span >Envoyer</span>
                                </button>
                            </div>
                        </div>
                    </form>
                    <p>
                        <div  className="link_password" onClick={() => this.handleForgotPassword}>
                          Je me rappelle de mon mot de passe
                        </div>
                    </p>
                </div>
            )
            }
          </div>
      </section>
    )
    }
}

function mapState(state) {
    const { loggingIn, loggedIn, user, logout, errorLog } = state.authentification;
    return { loggingIn, loggedIn, user, logout, errorLog };
}
const actionCreators = {
    login: userActions.login,
    logout: userActions.logout,
    forgotPassword: userActions.forgotPassword,
    loginHistory: userActions.loginHistory,
};

const Login = connect(mapState, actionCreators)(LoginPage);

export default Login;