import { historyConstants } from '../constants/history.constants';

export function history(state = {}, action) {
  switch (action.type) {
    case historyConstants.GET_HISTORY_REQUEST:
      return {
        loadingHistory: true,
        history: {}
      };
    case historyConstants.GET_HISTORY_SUCCESS:
      return {
        history: action.history,
        loadingHistory: false
      };
    case historyConstants.GET_HISTORY_FAILURE:
      return { 
        error: action.error,
        loadingHistory: false
      };
    default:
      return state
  }
}