import React from 'react';
import CustomSelect from './CustomSelect';

class PopupAddEditSpe extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            name: "",
            idPDS: -1,
            pds: "Sélectionnez un type de professionnel de santé",
            code: ""
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.spe !== prevProps.spe) {

            const edit = Object.keys(this.props.spe).length > 0;

            this.setState({
                ...this.state,
                name: edit ? this.props.spe.name : "",
                idPDS: edit ? this.props.spe.medicalprotype.idMedicalProType : -1,
                pds: edit ? this.props.spe.medicalprotype.nameMedicalProType : "Sélectionnez un type de professionnel de santé",
                code: edit ? this.props.spe.code : ""
            })
        }
    }

    handleSubmit = (e) => {
        if((this.state.name !== "") && (this.state.idPDS !== -1) && (this.state.code !== "")) {
            e.preventDefault();

            if(Object.keys(this.props.spe).length > 0) {
                this.props.editSpe({
                    id: this.props.spe.id,
                    name: this.state.name,
                    idPDS: this.state.idPDS,
                    code: this.state.code,
                });
            } else this.props.addSpe({
                name: this.state.name,
                idPDS: this.state.idPDS,
                code: this.state.code,
            });
            
            this.btnElement.click();
        }
    }

    render() {
        const edit = Object.keys(this.props.spe).length > 0;

        return(
            <div>
                <div className="modal fade in" id="modalAddEditSpe" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditSpeLabel" animate="animate">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} une spécialité</h1>
                            </div>
                            <div className="modal-body">
                                <form id="formAjout">

                                    <label>Code</label>
                                    <input
                                    type="text"
                                    required
                                    value={this.state.code} 
                                    onChange={(e) => this.setState({code: e.target.value})}
                                    />

                                    <label>Type</label>
                                    <CustomSelect
                                    defaultText={this.state.pds}
                                    optionsList={this.props.pdsList}
                                    handleOptionClick={(id, name) => this.setState({idPDS: id})}
                                    />

                                    <label>Label</label>
                                    <input 
                                    type="text" 
                                    required 
                                    value={this.state.name} 
                                    onChange={(e) => this.setState({name: e.target.value})}
                                    />

                                </form>
                            </div>
                            <div className="modal-footer">
                                <button
                                type="submit"
                                form="formAjout"
                                className="btn-save"
                                disabled={false}
                                data-toggle={ Object.keys(this.props.spe).length > 0 ? 'modal' : '' }
                                data-target={'#modalConfirmation'}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if(Object.keys(this.props.spe).length > 0) this.props.handleSubmit(this.handleSubmit);
                                    else this.handleSubmit(e);
                                }}>
                                    <img src="/dist/img/icons/icon-save.png" alt=""/>
                                    Sauvegarder
                                </button>
                                <button
                                type="button"
                                className="btn-cancel"
                                data-dismiss="modal"
                                ref={button => this.btnElement = button}
                                >Annuler</button>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        )
    }
}

export default PopupAddEditSpe;