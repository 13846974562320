import { config } from '../constants/app.constants';
import { authHeader } from '../helpers/auth-header';
import axios from 'axios';

export const medicalProService = {
    getMedicalPros,
    postMedicalPro,
    editMedicalPro,
    postEtabPro,
    editEtabPro,
    disableEtabPro,
    getOnePDS,
    getSpePro,
    postSpePro,
    disableSpePro,
    deleteSpePro,
    getLogiciels,
    getLogPro,
    postLogPro,
    getSpeByType,
    disableLogPro,
    deleteLogPro,
    deleteMedicalPro,
    validateMedicalPro,
    verifPro,
    deleteEtabPro,
    linkPros,
    getProInvalidate,
    getHistoPDS,
    unvalidateMedicalPro
};


function getProInvalidate(data) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+data+'/invalidate', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getMedicalPros(idCountry, data) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel/'+idCountry+'/list', {
                    page: data !== undefined ? data.page : 1,
                    maxPerPage: data !== undefined ? data.maxPerPage : 100,
                    lastname: data.lastname,
                    firstname : data.firstname,
                    reference : data.reference,
                    protype : data.protype,
                    speciality : data.speciality,
                    status : data.status,
                    linksearch : data.linksearch,
                    idToLink: data.idToLink
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function postMedicalPro(pro) {
    return new Promise((resolve, reject) => {
        try {
            let params = {
                "idArea": pro.idCountry,
                "idMedicalProType": pro.idPDS,
                "gender": pro.sexe,
                "title": pro.titre,
                "lastname": pro.name,
                "firstname": pro.fname,
                "mail": pro.email,
                "mobile": pro.telP,
                "land_line": pro.telF,
                "fax_number": pro.fax,
                "is_computerized": pro.informatise,
                "is_thought_leader": pro.leaderOpinion,
                "is_ethica_friend": pro.ethicaFriend,
                "phonenumbertoretrive": "",
                "emailtoretrive": "",
                "comment": "",
                "orgn_code": "",
                "idInstitution": pro.idInstitution,
                "savedonethica": pro.savedOnEthica,
                "is_retired": pro.isRetired,
                "is_death": pro.isDeath
            }
            if(pro.Speciality !== -1) params = Object.assign(params, { "idMainSpeciality": pro.Speciality });
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel', params)
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function editMedicalPro(pro) {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/professionnel/'+pro.id, {
                    "idArea": pro.idCountry,
                    "idMedicalProType": pro.idPDS,
                    "gender": pro.sexe,
                    "title": pro.titre,
                    "lastname": pro.name,
                    "firstname": pro.fname,
                    "mail": pro.email,
                    "mobile": pro.telP,
                    "land_line": pro.telF,
                    "fax_number": pro.fax,
                    "is_computerized": pro.informatise,
                    "is_thought_leader": pro.leaderOpinion,
                    "is_ethica_friend": pro.ethicaFriend,
                    "phonenumbertoretrive": "",
                    "emailtoretrive": "",
                    "comment": "",
                    "orgn_code": "",
                    "savedonethica": pro.savedOnEthica,
                    "is_retired": pro.isRetired,
                    "is_death": pro.isDeath,
                    "idMainSpeciality": pro.Speciality
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteMedicalPro(idPro) {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/professionnel/'+idPro+'/delete', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function validateMedicalPro(idPro, isEdit) {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/professionnel/'+idPro+'/validate', {
                    isEdit: isEdit ? 1 : 0
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function unvalidateMedicalPro(idPro, isEdit) {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/professionnel/'+idPro+'/invalidate', {
                    isEdit: isEdit ? 1 : 0
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function linkPros(id1, id2) {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel/'+id1+'/link', {
                    idProfessionnel2: id2
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function verifPro(data){

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/verif-name/professionnel', {
                    "lastName": data.name,
                    "firstName": data.firstName,
                    "page": data.page,
                    "maxPerPage": data.maxPerPage,
                    "id": data.id ? data.id : null,
                    "areaId": data.idCountry
                })
                .then(function (response) {
                    try {
                        resolve(JSON.stringify(response.data));
                    } catch (error) {
                        reject(error);
                    }
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

// ETAB

function postEtabPro(idPro, etab) {
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel/'+idPro+'/location', {
                    "idInstitution": etab.id,
                    "NbPatientsDay": etab.nbPatientDay,
                    "WithInternetConn": etab.internet,
                    "IsHeadDept": etab.chef,
                    "HasSecretary": etab.secretaire,
                    "OnAppointment": etab.rdv,
                    "ProspectingScore": "",
                    "Schedule": etab.schedule
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function editEtabPro(idPro, etab) {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).put('api/professionnel/'+idPro+'/location', {
                    "idInstitution": etab.id,
                    "NbPatientsDay": etab.nbPatientDay,
                    "WithInternetConn": etab.internet,
                    "IsHeadDept": etab.chef,
                    "HasSecretary": etab.secretaire,
                    "OnAppointment": etab.rdv,
                    "ProspectingScore": "",
                    "Schedule": etab.schedule
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function disableEtabPro(idPro, idEtab) {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/professionnel/'+idPro+'/location/disable', { data: {
                    "idInstitution": idEtab,
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteEtabPro(idPro, idEtab) {
    
    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/professionnel/'+idPro+'/location/delete', { data: {
                    "idInstitution": idEtab,
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getSpeByType(idType) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/medicalprotype/'+idType+'/speciality', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listSpecialityByMedicalProType));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getOnePDS(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+id, {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.MedicalPro));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getHistoPDS(id) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+id+'/history', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listHistoMedicalPro));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

//SPECIALITES

function getSpePro(idPro) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+idPro+'/speciality', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMedicalProSpeciality));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function postSpePro(idPro, idSpe) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel/'+idPro+'/speciality', {
                    idSpeciality: idSpe
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function disableSpePro(idPro, idSpe) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/professionnel/'+idPro+'/speciality/disable', { data: {
                    idSpeciality: idSpe
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteSpePro(idPro, idSpe) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/professionnel/'+idPro+'/speciality/delete', { data: {
                    idSpeciality: idSpe
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

//LOGICIELS

function getLogiciels() {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/software', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listSoftware));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function getLogPro(idPro) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).get('api/professionnel/'+idPro+'/software', {})
                .then(function (response) {
                    resolve(JSON.stringify(response.data.listMedicalProSoftware));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function postLogPro(idPro, idLog) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).post('api/professionnel/'+idPro+'/software', {
                    idSoftware: idLog
                })
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function disableLogPro(idPro, idLog) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/professionnel/'+idPro+'/software/disable', { data: {
                    idSoftware: idLog
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}

function deleteLogPro(idPro, idLog) {

    return new Promise((resolve, reject) => {
        try {
            authHeader()
            .then(res => {
                axios.create({
                    baseURL: config.baseUrl,
                    headers: {
                        'Authorization': 'Bearer ' + res.token,
                        'Content-Type': 'application/json',
                        'Accept-Language': localStorage.getItem('currentLanguage')
                    }
                }).delete('api/professionnel/'+idPro+'/software/delete', { data: {
                    idSoftware: idLog
                }})
                .then(function (response) {
                    resolve(JSON.stringify(response.data));    
                })
                .catch(function (error) {
                    reject(error);
                });
            })
            .catch(function (error) {
                reject(error);
            });
        }
        catch (error) {
            reject(error);
        }
    });
}