import React from 'react';
import { connect } from 'react-redux';
import { historyActions } from '../actions/history.actions';
import Moment from 'react-moment';
import Pagination from '../components/Pagination';

class HistoriquePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            history: [],
            page: 1,
            maxPage: 1,
            maxPerPage: 100,
            histoReady: false
        }
    }

    componentDidMount() {
        this.props.getHistory({
            page : this.state.page,
            maxPerPage : this.state.maxPerPage
        })
    }

    componentDidUpdate(prevProps, prevState) {

        if((this.props.loadingHistory !== prevProps.loadingHistory) && !this.props.loadingHistory) {
            if((this.props.history !== undefined) && (this.props.history !== "null")){
                this.setState({
                    history: JSON.parse(this.props.history).listHisto,
                    maxPage: JSON.parse(this.props.history).nbrPages,
                    histoReady: true
                });
            }
        }

        if(this.state.page !== prevState.page){
            this.props.getHistory({
                page : this.state.page,
                maxPerPage : this.state.maxPerPage
            });
        }
    }

    onChangePage = (data) => this.setState({ page: data });

    render() {
        const { histoReady, maxPage } = this.state;

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>
                        Historique
                        <small>Les dernières actions des utilisateurs</small>
                    </h1>
                </section>
            
                <section className="content historique">

                    <table className="box tab-entete historique">
                        <tbody>
                            <tr>
                                <td className="action">
                                    <div>
                                        <p>Action</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>Par</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>Date</p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list historique">
                        <tbody>
                        {this.state.history.map((action, i) => {
                            return (
                                <tr key={i} className="list-row">
                                    <td className="action">
                                        {action.action}
                                    </td>
                                    <td>
                                        {action.par.fullname}
                                    </td>
                                    <td>
                                        <Moment locale="fr" fromNow>{new Date(action.date)}</Moment>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                    {histoReady && maxPage > 1  && 
                    <Pagination
                        maxPage={this.state.maxPage}
                        page={this.state.page}
                        onChangePage={this.onChangePage}
                    ></Pagination>
                    }

                </section>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingHistory, history } = state.history;
    return { loadingHistory, history };
}
const actionCreators = {
    getHistory: historyActions.getHistory,
};

const Historique = connect(mapState, actionCreators)(HistoriquePage);

export default Historique;