import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { etabActions } from '../../actions/etab.actions';

class PopupAddEditProEtabPage extends React.Component {

    constructor(props) {
        super(props);

        this.initialState = {
            newEtab: null,
            nbPatientDay: "",
            internet: 0,
            chef: 0,
            secretaire: 0,
            rdv: 0,
            schedule: "00000000000000"
        }

        this.state = this.initialState;
        this.timer = setTimeout(() => {}, 1);
    }

    componentDidUpdate(prevProps) {

        if(this.props.etab !== prevProps.etab) {

            const edit = Object.keys(this.props.etab).length > 0;

            this.initialState = {
                ...this.initialState,
                nbPatientDay: edit ? this.props.etab.NbPatientsDay : "",
                internet: edit ? this.props.etab.WithInternetConn : 0,
                chef: edit ? this.props.etab.IsHeadDept : 0,
                secretaire: edit ? this.props.etab.HasSecretary : 0,
                rdv: edit ? this.props.etab.OnAppointment : 0,
                schedule: edit ? this.props.etab.Schedule : "00000000000000",
            }

            this.setState(this.initialState);
        }
    }

    handleSubmit = (e) => {
        if(Object.keys(this.props.etab).length > 0) {   //edit

            e.preventDefault();

            this.props.editProEtab(this.props.idPro, {
                id: this.props.etab.IdInstitution,
                nbPatientDay: this.state.nbPatientDay,
                internet: this.state.internet,
                chef: this.state.chef,
                secretaire: this.state.secretaire,
                rdv: this.state.rdv,
                schedule: this.state.schedule
            });
            
            this.btnElement.click();

        } else {    //post
            if(this.state.newEtab !== null) {
                e.preventDefault();

                this.props.postProEtab(this.props.idPro, {
                    id: this.state.newEtab.value,
                    nbPatientDay: this.state.nbPatientDay,
                    internet: this.state.internet,
                    chef: this.state.chef,
                    secretaire: this.state.secretaire,
                    rdv: this.state.rdv,
                    schedule: this.state.schedule
                });
                
                this.btnElement.click();
            } else {
                //msg error
            }

        }
    }

    handleSchedule = (i) => {
        let str = this.state.schedule;
        let rep = str[i] === "1" ? "0" : "1";
        str = str.substring(0, i) + rep + str.substring(i+1);
        this.setState({schedule: str})
    }

    handleFilter = (value) => {
        clearTimeout(this.timer);
    
        this.timer = setTimeout(() => {
            this.props.getEtablissements(this.props.idCountry, {
                page: 1,
                maxPerPage: 50,
                name: value
            });
        }, 1000);
    }

    render() {
        const edit = Object.keys(this.props.etab).length > 0;
        const formatGroupLabel = data => (<div><span>{data.label}</span></div>);

        return(
            <div className="modal fade in" id="modalAddEditProEtab" tabIndex="-1" role="dialog" aria-labelledby="modalAddEditProEtabLabel" animate="animate" onClick={() => this.btnElement.click()}>
                <div className="modal-dialog" role="document" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title" id="myModalLabel">{edit ? "Modifier" : "Ajouter"} un lieu de consultation</h1>
                        </div>
                        <div className="modal-body">
                            <form id="formAjout">

                                {!edit &&
                                <div>
                                    <label>Lieu de consultation</label>
                                    <Select
                                    options={this.props.etabList}
                                    formatGroupLabel={formatGroupLabel}
                                    value={this.state.newEtab}
                                    onChange={(newValue) => this.setState({ newEtab: newValue })}
                                    onInputChange={(newValue) => this.handleFilter(newValue)}
                                    placeholder="Entrez le nom d'un établissement"
                                    formatOptionLabel={(option, { context }) => {
                                        /* context can be either `menu` or `value`
                                            menu - dropdown
                                            value - value displayed
                                        */
                                        return context === 'menu' ? (
                                            <div className="select-react-option etab">
                                                <div>
                                                    <div className={`tag ${option.isPrivate === 1 ? "valid" : option.isPrivate === 2 ? "prive" : option.isPrivate === 3 ? "warning" : "inconnu"}`}>
                                                        <p>
                                                            {option.isPrivate === 1 ? "Public"
                                                            : option.isPrivate === 2 ? "Privé"
                                                            : option.isPrivate === 3 ? "Semi-privé"
                                                            : "Inconnu"}
                                                        </p>
                                                    </div>
                                                    <p>{option.ref} - {option.label}</p>
                                                </div>
                                                <small>{option.ad}</small>
                                            </div>
                                        ) : `${option.ref} - ${option.label}`;
                                    }}
                                    isClearable
                                    className="react-select-container"
                                    />
                                </div>
                                }

                                <h2>Sur ce lieu de consultation...</h2>

                                <div className="row">
                                    <div className="col-md-6">
                                        <label>Nombre de patients par semaine</label>
                                        <input
                                        type="number" 
                                        value={this.state.nbPatientDay !== null ? this.state.nbPatientDay : ""} 
                                        onChange={(e) => this.setState({nbPatientDay: e.target.value})} min="0"/>
                                    </div>
                                </div>

                                <div className="row radio-div">
                                    <div className="col-md-3">
                                        <label>Connexion Internet</label>
                                        <label>Chef de service</label>
                                        <label>Secrétaire</label>
                                        <label>Sur rendez-vous uniquement</label>
                                    </div>
                                    <div className="col-md-9">
                                        <div className="radio-form">
                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="internet"
                                                checked={this.state.internet === 0}
                                                value={0}
                                                onChange={(e) => this.setState({internet: parseInt(e.target.value)})}
                                                id="internetInconnu"
                                                />
                                                <label htmlFor="internetInconnu">Inconnu</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="internet"
                                                checked={this.state.internet === 1}
                                                value={1}
                                                onChange={(e) => this.setState({internet: parseInt(e.target.value)})}
                                                id="internetOui"
                                                />
                                                <label htmlFor="internetOui">Oui</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="internet"
                                                checked={this.state.internet === 2}
                                                value={2}
                                                onChange={(e) => this.setState({internet: parseInt(e.target.value)})}
                                                id="internetNon"
                                                />
                                                <label htmlFor="internetNon">Non</label>
                                            </div>
                                        </div>

                                        <div className="radio-form">
                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="chef"
                                                checked={this.state.chef === 0}
                                                value={0}
                                                onChange={(e) => this.setState({chef: parseInt(e.target.value)})}
                                                id="chefInconnu"
                                                />
                                                <label htmlFor="chefInconnu">Inconnu</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="chef"
                                                checked={this.state.chef === 1}
                                                value={1}
                                                onChange={(e) => this.setState({chef: parseInt(e.target.value)})}
                                                id="chefOui"
                                                />
                                                <label htmlFor="chefOui">Oui</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="chef"
                                                checked={this.state.chef === 2}
                                                value={2}
                                                onChange={(e) => this.setState({chef: parseInt(e.target.value)})}
                                                id="chefNon"
                                                />
                                                <label htmlFor="chefNon">Non</label>
                                            </div>
                                        </div>
                                    
                                        <div className="radio-form">
                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="secretaire"
                                                checked={this.state.secretaire === 0}
                                                value={0}
                                                onChange={(e) => this.setState({secretaire: parseInt(e.target.value)})}
                                                id="secretaireInconnu"
                                                />
                                                <label htmlFor="secretaireInconnu">Inconnu</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="secretaire"
                                                checked={this.state.secretaire === 1}
                                                value={1}
                                                onChange={(e) => this.setState({secretaire: parseInt(e.target.value)})}
                                                id="secretaireOui"
                                                />
                                                <label htmlFor="secretaireOui">Oui</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="secretaire"
                                                checked={this.state.secretaire === 2}
                                                value={2}
                                                onChange={(e) => this.setState({secretaire: parseInt(e.target.value)})}
                                                id="secretaireNon"
                                                />
                                                <label htmlFor="secretaireNon">Non</label>
                                            </div>
                                        </div>

                                        <div className="radio-form">
                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="rdv"
                                                checked={this.state.rdv === 0}
                                                value={0}
                                                onChange={(e) => this.setState({rdv: parseInt(e.target.value)})}
                                                id="rdvInconnu"
                                                />
                                                <label htmlFor="rdvInconnu">Inconnu</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="rdv"
                                                checked={this.state.rdv === 1}
                                                value={1}
                                                onChange={(e) => this.setState({rdv: parseInt(e.target.value)})}
                                                id="rdvOui"
                                                />
                                                <label htmlFor="rdvOui">Oui</label>
                                            </div>

                                            <div className="radio">
                                                <input
                                                type="radio"
                                                name="rdv"
                                                checked={this.state.rdv === 2}
                                                value={2}
                                                onChange={(e) => this.setState({rdv: parseInt(e.target.value)})}
                                                id="rdvNon"
                                                />
                                                <label htmlFor="rdvNon">Non</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <label>Demi-journées de présence du PDS sur ce lieu</label>

                                <table className="planning">
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td>Lundi</td>
                                            <td>Mardi</td>
                                            <td>Mercredi</td>
                                            <td>Jeudi</td>
                                            <td>Vendredi</td>
                                            <td>Samedi</td>
                                            <td>Dimanche</td>
                                        </tr>
                                        <tr>
                                            <td>Matin</td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[0] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(0)}>
                                                    <p>{this.state.schedule[0] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[2] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(2)}>
                                                    <p>{this.state.schedule[2] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[4] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(4)}>
                                                    <p>{this.state.schedule[4] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[6] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(6)}>
                                                    <p>{this.state.schedule[6] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[8] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(8)}>
                                                    <p>{this.state.schedule[8] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[10] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(10)}>
                                                    <p>{this.state.schedule[10] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[12] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(12)}>
                                                    <p>{this.state.schedule[12] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Après-midi</td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[1] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(1)}>
                                                    <p>{this.state.schedule[1] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[3] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(3)}>
                                                    <p>{this.state.schedule[3] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[5] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(5)}>
                                                    <p>{this.state.schedule[5] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[7] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(7)}>
                                                    <p>{this.state.schedule[7] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[9] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(9)}>
                                                    <p>{this.state.schedule[9] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[11] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(11)}>
                                                    <p>{this.state.schedule[11] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                            <td className="planning">
                                                <div
                                                className={`tag ${this.state.schedule[13] === "1" ? "valid" : "warning"}`}
                                                onClick={() => this.handleSchedule(13)}>
                                                    <p>{this.state.schedule[13] === "0" ? "Absent" : "Présent"}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="submit" form="formAjout" className="btn-save" onClick={this.handleSubmit}>
                                <img src="/dist/img/icons/icon-save.png" alt=""/>
                                Sauvegarder
                            </button>
                            <button
                            type="button"
                            className="btn-cancel"
                            data-dismiss="modal"
                            ref={button => this.btnElement = button}
                            onClick={() => this.setState(this.initialState)}
                            >Annuler</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function mapState(state) {
    const { loadingEtabs, etabs } = state.etab;
    return { loadingEtabs, etabs };
}
const actionCreators = {
    getEtablissements: etabActions.getEtablissements,
};

const PopupAddEditProEtab = connect(mapState, actionCreators)(PopupAddEditProEtabPage);

export default PopupAddEditProEtab;