import React from 'react';
import { connect } from 'react-redux';
import { protTypeActions } from '../../actions/protype.actions';
import ButtonAdd from '../../components/shared/ButtonAdd';
import PopupDelete from '../../components/shared/PopupDelete';
import PopupAddEditPDS from '../../components/shared/PopupAddEditPDS';
import { NotificationManager} from 'react-notifications';
import PopupConfirmation from '../../components/shared/PopupConfirmation';

class TypePDSPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            idDelete: -1,
            selectedPDS: {},
            listPDS: []
        }
    }

    componentDidMount() {
        this.props.getAllProType();
    }

    componentDidUpdate(prevProps){

        if((this.props.loadingProType !== prevProps.loadingProType) && !this.props.loadingProType){
            if((this.props.protype !== "null") && (this.props.protype !== undefined)){
                this.setState({ listPDS : JSON.parse(this.props.protype) });
            }            
        }

        if((this.props.pdsPosted !== prevProps.pdsPosted) && this.props.pdsPosted) {
            this.props.getAllProType();
            NotificationManager.success('',"Type PDS ajouté");
        }

        if((this.props.pdsEdited !== prevProps.pdsEdited) && this.props.pdsEdited) {
            this.props.getAllProType();
            NotificationManager.success('',"Type PDS édité");
        }

        if((this.props.pdsDeleted !== prevProps.pdsDeleted) && this.props.pdsDeleted) {
            this.props.getAllProType();
            NotificationManager.success('',"Type PDS supprimé");
        }
    }

    render() {
        const { listPDS } = this.state;

        return(
            <section className="content">
            
                <section className="content-header">
                    <h1>  
                        Types de PDS  
                        <small>Gestion des types de PDS</small>
                    </h1>
                </section>

                <section className="content typePDS">
                    
                    <table className="box tab-entete pds">
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>Label</p>
                                    </div>
                                </td>
                                <td className="last">
                                    <ButtonAdd modal="#modalAddEditPDS" handleAdd={() => this.setState({selectedPDS: {}})}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="list pds">
                        <tbody>
                        {listPDS.map((pds, i) => {
                            return (
                                <tr key={i} className="list-row">
                                    <td>
                                        {pds.name}
                                    </td>
                                    <td className="icons last">
                                        <div
                                        className="icon-btn"
                                        data-toggle="modal"
                                        data-target="#modalAddEditPDS"
                                        onClick={() => this.setState({selectedPDS: pds})}>
                                            <img src="/dist/img/icons/icon-edit.png" alt=""/>
                                        </div>
                                        <div
                                        className="icon-btn"
                                        data-toggle="modal"
                                        data-target="#modalDelete"
                                        onClick={() => this.setState({idDelete: pds.id})}>
                                            <img src="/dist/img/icons/icon-delete.png" alt=""/>
                                        </div>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>

                </section>

                <PopupAddEditPDS
                handleSubmit={(submit) => this.setState({ handleSubmit: submit })}
                addPDS={(pds) => this.props.postProType(pds)}
                pds={this.state.selectedPDS}
                editPDS={(pds) => this.props.editProType(pds)}
                />

                <PopupDelete
                type="type de PDS"
                deletePDS={(id) => this.props.deleteProType(id)}
                id={this.state.idDelete}
                />
                
                <PopupConfirmation message={'Êtes-vous sûr ?'} handleSubmit={this.state.handleSubmit}/>

            </section>
        )
    }
}

function mapState(state) {
    const { loadingProType, protype, pdsPosted, pdsEdited, pdsDeleted } = state.type;
    return { loadingProType, protype, pdsPosted, pdsEdited, pdsDeleted };
}
const actionCreators = {
    getAllProType: protTypeActions.getAllProType,
    postProType: protTypeActions.postProType,
    editProType: protTypeActions.editProType,
    deleteProType: protTypeActions.deleteProType,
};

const TypePDS = connect(mapState, actionCreators)(TypePDSPage);

export default TypePDS;